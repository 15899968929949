.row .react-bootstrap-table-pagination{
    flex-wrap: nowrap;
}
.user-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.workspace-selector .input-select{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}
.workspace-selector .input-select .select--container {
    width: 70%;
}
.actionIcons svg {
    width: 16px;
    height: 16px;
    vertical-align: unset;
    cursor: pointer;
}
.actionIcons i {
    font-size: 19px;
    color: #E04746;
    cursor: pointer;
    margin-left: 10px;
}
.actionIcons .only-disable {
    margin-left: 26px;
}
.userDisabledRow {
    background: #FFF4F4 0% 0% no-repeat padding-box;
    opacity: 1;
}
.userDisabledIcon {
    font-size: 14px;
    color: #E04746;
    line-height: unset;
    vertical-align: middle;
    margin-left: 0.5rem;
}
.nameHyperLink {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.usermgmt-user-pic {
    width: 32px;
    height: 32px;
    margin-top: -4px;
    opacity: 1;
}
.user-edit-controls {
    display: flex;
    justify-content: space-evenly;
}
.user-edit-controls .input-box {
    width: 100%;
    padding-left:10px;
    margin: 8px 0 10px 0;
}
.user-edit-controls .input-select {
    margin: 8px 0 10px 0 !important;
}
.user-edit-controls .multi-select {
    margin: 8px 0 10px 0;
}
.user-pic-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.edit-user-pic {
    width: 165px;
    height: 165px;
    border-radius: 50%;
    margin-top: 20px;
    padding: 20px;
    opacity: 1;
}
.user-edit-form-container {
    margin-top: 40px;
}
.user-edit-form-label{
    margin-top: 10px;
    color: #617992;
    font: normal 14px/20px "Roboto",Serif;
}  
.usermgmt-save-btn {
    width: 120px;
    margin-top: 45px;
    float: right;
}
.input-field-error {
    border: 1px solid #E67070;
}
.user-edit-form-container .input-box:disabled{
    cursor: not-allowed;
    background-color: #EFEFEF !important;
    color: #6E6E7C !important;
}
.usermgmt-save-btn:disabled {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border: 1px solid #D3D3D3;
    cursor: not-allowed;
    color: #D3D3D3;
}