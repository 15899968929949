@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.signup-error-message label{
  padding: 0px;
  margin-left: 0px;
}

.wrap-signup {
  width: 730px;
  min-height: 560px;
  overflow: hidden;
  display: inline-flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #00000033;
  border-radius: 10px;
  opacity: 1;
}

.tablet .wrap-signup {
  width: 650px;
}

.form-align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;
}

.signup-with-text {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-family: "Montserrat", "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #454f63;
  padding: 10px 0;
  opacity: 1;
}

#eyeIcon {
  cursor: pointer;
}
.CaretDown {
  color: #5a5a5a;
}

a {
  color: #4fa3ff;
}

.signup-icons {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.signup-icons .round-icon {
  width: 32px;
  height: 32px;
  border: 1px solid #bfbfbf;
  opacity: 1;
  border-radius: 50%;
  text-align: center;
  margin: 5px;
}
.signup-form {
  display: grid;
  grid-template-columns: 1fr;
}

.round-icon svg {
  vertical-align: bottom;
}

.signup-form .language-select {
  padding-top: 5px;
  padding-right: 10px;
}

.form-signup {
  max-width: 280px;
  width: 100%;
}

.safely-registration-left {
  background-color: #726bef;
  display: grid;
  grid-template-columns: 1fr;
}

.safely-registration-left .safely-logo-header {
  padding-top: 15px;
}

.safely-registration-left .signin-link {
  background-image: url("../icons/signupImage.svg");
  background-color: #726bef;
}

.text-style-div {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0px;
  font-family: "Montserrat", "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
}

.signin-link > p {
  text-align: center;
  font-size: medium 14px;
  font-family: "Montserrat", "Roboto", sans-serif;
  letter-spacing: 1px;
  padding: 10px;
  color: #ffffff;
  opacity: 1;
}

.signin-link .btn-style-div {
  text-align: center;
}

.signin-link .sign-in-btn {
  width: 120px;
  height: 40px;
  border: 1px solid #ffffff !important;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #726bef !important;
}

.signin-link .sign-in-btn:hover {
  background-color: #726bef !important;
  border: 1px solid #ffffff !important;
}

.signin-link .sign-in-btn:focus {
  background-color: #726bef !important;
  border: 1px solid #ffffff !important;
  outline: none !important;
  box-shadow: none !important;
}

.language-dropdown {
  width: 90px;
  height: 40px;
  float: right;
  font-family: "Roboto", Serif;
}

.safely-footer {
  text-align: center;

}

.safely-footer span {
  font-size: 10px;
  font-family: "Montserrat", "Roboto", sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.safely-footer svg {
  width: 63px;
  height: 24px;
  vertical-align: bottom;
}

.terms-text-style {
  text-align: left;
  font-size: 13px;
  font-family: "Montserrat", "Roboto", sans-serif !important;
  letter-spacing: 0px;
  color: #6e7e88;
}
.terms-text-style a {
  text-decoration: none;
  color: #4FA3FF;
  font-weight: 600;
}
/*
.checkbox-div input[type=checkbox] {
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  border: 1px solid #665dff;
  opacity: 1;
  vertical-align: middle;
  cursor: pointer;
}*/

.checkbox-div {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-tick {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #ffffff;
  border: 1px solid #9baac7;
  border-radius: 4px;
}

.checkmark-tick:after {
  content: "";
  position: absolute;
  display: none;
}

/* On mouse-over, add a grey background color */
.checkbox-div:hover input ~ .checkmark-tick {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-div input:checked ~ .checkmark-tick {
  background-color: #665dff;
  border: 1px solid #665dff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-div:after {
  content: "";
  position: absolute;
  display: none;
  border: 1px solid #665dff;
}

/* Show the checkmark when checked */
.checkbox-div input:checked ~ .checkmark-tick:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-div .checkmark-tick:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signUp-title {
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat", Serif !important;
  letter-spacing: 0px;
  color: #454f63;
  opacity: 1;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 10px;
}

.input-style {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  font-family: "Roboto", Serif;
  opacity: 1;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #4fa3ff;
}

.singupButton,
.signupBtn {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #665DFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #665DFF !important;
}

.singupButton:disabled {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  cursor: not-allowed;
  width: 100%;
}

.phoneSingin {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  color: #454f63;
  font-size: 11px;
  font-family: "Montserrat", Serif;
}

.phoneSingin .phoneSigninLink {
  padding-left: 5px;
}

.phone .signup-form {
  max-width: 100% !important;
  margin: 0 auto;
}


.phone .form-align-center {
  width: auto;
}
.phone .form-signup {
  max-height: 600px;
}
.css-18jox8o-singleValue {
  font-size: 12px;
  margin-left: 1px;
  margin-right: 1px;
}
.paragraph-text-style {
  text-align: center;
  font-weight: normal;
  font-family: "Roboto", Serif !important;
  font-size: 17px;
  letter-spacing: 0px;
  color: #6E7E88;
  opacity: 1;
  padding-top: 5px;
  padding-bottom: 15px;
}
.socialIcon-signUp {
  padding-top: 10px;
}

@media screen and (max-width: 320px) {
  .checkmark-tick{
    top: 4px !important;
  }
  .singupButton, .signupBtn{
    width: -webkit-fill-available !important;
  }
}