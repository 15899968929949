 .check-in-user-avtar .btn-light , .checkin-modal .custome-multiselect .dropdown-heading-dropdown-arrow svg{
    display: none !important;
    }
  .popup-datetime{
    color: #78849E;
    font-size: 15px;
    /* text-transform: capitalize; fixed 227 */
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
  }  
  .popup-checkin{
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    text-align: center;
    border-color: #665DFF;
    font-weight: 600;
  }

  .popup-checkin:hover,.popup-checkin:focus,.popup-checkin:active{
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    border-color: #665DFF;
    box-shadow:none !important;
  }
  .checkin-modal .custome-multiselect .dropdown-content .select-item{
   padding-top: 0px;
   padding-bottom: 0px;
   margin-bottom: 1px;
  }
  .checkin-modal .custome-multiselect .dropdown-content .item-renderer, .checkin-modal .custome-multiselect .dropdown-heading-value{
    color: #5A5A5A;
  }
  .checkin-modal .dropdownPointer{
    position: absolute;
    right: 10px;
    margin-top: -30px;
    pointer-events: none;
  }
  /* button.check-in-new-user {
    float: right;
  } */
  /* @media  screen and (min-width: 712px){
    .search-checkpoint{
      width: 350px;
  }
  } */
  .checkpoint-modal .checkin-input-container {
    width: 260px !important;
  }

  .popup-disable{
    background: #E67070 !important;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    text-align: center;
    border-color: #E67070 !important;
    font-weight: 600;
    cursor: pointer;
    margin: 1.4rem 0;
  }

  .popup-disable:hover,.ppopup-disable:focus,.popup-disable:active{
    background: #E67070 !important;
    border-radius: 4px;
    opacity: 1;
    border-color: #E67070 !important;
    box-shadow:none !important;
  }

  .popup-disable:disabled {
    cursor: not-allowed;
    background: #F2F2F2 0% 0% no-repeat padding-box !important;
    border: 1px solid #D3D3D3 !important;
    color: #D3D3D3 !important;
  }

  .checkpoint-modal input[type=radio] {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 5px 5px 0px 0px;
  }
  .checkpoint-modal input[type='radio']:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: 0px;
        left: 0px;
        position: relative;
        background-color: #ffffff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid #665DFF;
  }

  .checkpoint-modal input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    top: -1px;
    left: 2px;
    position: relative;
    background-color: #665DFF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #FFFFFF;
  }

  .checkpoint-modal .radio-button-background label {
    vertical-align: middle;
    color: #6E6E7C;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
  }
  .disable-pass-date-picker {
    margin: 0.8rem 0;
  }
  .disable-pass-date-picker label {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #617992;
    margin-bottom: 3px;
  }

  .multi-select-in-disable-pass {
    margin-top: 0.5rem;
    margin-left: 20px;
  }
  .multi-select-in-disable-pass label {
    font: 14px "Roboto";
    letter-spacing: 0px;
    color: #617992;
  }

  .checkin-modal .multiselect-arrow-custom .dropdown-heading-dropdown-arrow svg {
    display: inline !important;
  }

  .disable-date {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #6E6E7C;
    opacity: 0.4;
  }
  
  .search-checkpoint{
    width: 350px;
  }

  @media screen and (max-width: 712px){  
    .check-in-new-user {
      width: auto;
      margin: 0px;
    }
    
  }

  .disable-pass-date-picker i {
    z-index: 0 !important;
    cursor: pointer;
    margin-top: -25px;
  }

  @media only screen and (max-width: 640px){
    button.check-in-new-user {
      display: block;
      float: left;
      margin: 0px;
    }

    .search-checkpoint{
      width: 100%;
    }
  }

  /* .phone .checkpoint-continer{
    margin-top: 70px;
  } */
  /* .phone .checkpoint-label-name{
    padding-top: 0px !important;
  } */

  .go445362013 .panel-content {
    max-height: 185px;
  }

  .tab-checkpoint {
    font-family: "Montserrat", Serif;
    color:  #6e7e88;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 60px;
}

.tab-checkpoint.nav-item.nav-link.active {
    background-color: #F1F5FD;
    color: #665DFF;
}



.search-and-button-container{
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 415px) {
  .tab-checkpoint {
    padding: 10px 20px;
  }

  .check-in-new-user {
    width: 100%;
    margin: 10px 50px 10px 0px !important
  }

  .search-and-button-container{
    display: grid;
    justify-content: normal;
    padding-right: 0px;
  }
}


 .big-modal {
    max-width: 70%;
  }

 .popup-text{
    font-family: "Roboto", Serif;
    color: #6E6E7C;
    font-size: 17px;
  }

.popup-text-bold{
  font-family: "Roboto", Serif;
  color: #6E6E7C;
  font-size: 17px;
  font-weight: 600;
}

.popup-icon-container{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.popup-title-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.disabled-input:disabled{
  background-color: #fcfcff;
  opacity: 1;
}

.checkpoint-modal .modal-footer {
  flex-wrap: inherit;
}