.set-workspace.switch-control-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.set-workspace.options{
   display: flex;
   width: 100%;
   min-width: 200px;
}

.setworkspace.update-settings-text{
    color: #606076;
    text-align: center;
    font: bold 18px "Montserrat", Serif;
    padding-bottom: 28px;
    padding-top: 28px;
    display: block;
}

.border-top{
    border-top: #E1E7F2;
 }
 
 .border-bottom{
    border-top: #E1E7F2;
 }

 .padding-top{
     padding-top: 30px;
 }

 .switch-option-padding{
    padding: 7px 0px;
 }

 .almost-done-title{
   font-family: "Roboto", Serif;
   font-weight: bold;
    font-size: 18px;
    color: #707070;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: 25px;
    padding-bottom: 10px;
 }

 /* .switch-label{
    text-align: left !important;
 } */
 .set-workspace-text {
    text-align: left !important;
    width: 100%;
    max-width: 420px;
 }
 .switch-label-text {
   color: #5D768F;
 }