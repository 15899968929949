.limiter {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: #f1f5fd;
}

.container-login100 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  width: 360px;
  min-height: 540px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ccc;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 30px;
  padding-top: 50px;
}

.login100-form {
  width: 100%;
}

.login100-form-title {
  font-family: "Montserrat", Serif;
  font-weight: bold;
  font-size: 20px;
  color: #707070;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 20px;
}
.login100-form-sub-title {
  font-family: "Roboto", Serif;
  font-weight: bold;
  font-size: 16px;
  color: #707070;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 10px;
}

.validate-input {
  position: relative;
  margin-bottom: 16px;
}

.input100 {
  /* font-size: 18px;
  line-height: 1.2; */
  color: #686868;
  display: block;
  width: 100%;
  background: #e6e6e6;
  border: 1px solid #1849e8;
  height: 30px;
  border-radius: 8px;
  padding: 5px;
}

.threedots:after {
  content: "\2807";
  font-size: 22px;
  color: #617992;
  position: absolute;
  left: 7px;
  top: -3px;
}

.checkpoint-threedots {
  display: inline-block;
  margin-left: 26px;
}

.checkpoint-threedots .threedots:after {
  position: unset;
}

.space-item.active :hover .threedots:after {
  content: "\2807";
  font-size: 22px;
  color: #617992;
  position: absolute;
  /* top: -3px;
  left: 6px; */
}
.sub-spacetoggle {
  padding-top: 5px;
}
.space-item.active .dropright:hover {
  width: 24px;
  height: 24px;
  background-color: #f5f5f5;
  /* margin-top: 2px; */
  border-radius: 50px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
.space-sub-item.active .sub-spacetoggle .dropright:hover {
  background-color: #f5f5f5;
  /* margin-top: 2px; */
  border-radius: 50px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
/* .list-group-item.active {
  background-color: #c7d2de !important;
  border-color: #c7d2de !important;
} */
/* 
.SafelyPass .list-group-item {
  border: 0 !important;
  padding: 5px 0 !important;
} */

.downLoadAsPdf {
  display: none;
}
.qrCodePopUP .modal-body {
  padding: 0px;
}
.qrCodePopUP .modal-body .close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.qrCodePopUP .modal-body #result {
  width: auto !important;
}

.user-profile {
  max-height: 200px;
  min-height: 200px;
  background: url("../images/user-header-bg.png") center no-repeat;
}

.space-wrapper {
  flex-direction: row;
  padding: 10px 25px;
}
.space-item label {
  cursor: pointer;
  display: inline;
  word-break: break-word;
  font-size: 14px;
  font-weight: 600;
  color: #2b2b42;
  width: -webkit-max-content;
}
.space-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 70px;
  margin: 18px 0px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  border-top-width: 1px;
  box-shadow: 0px 1px 3px #00000029;
}
.space-item:hover {
  cursor: pointer;
}

.space-item .dropright {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2% !important;
  right: 1% !important;
}

/* .dropright .threedots::after {
  margin-top: 0px;
} */
.space-item.active {
  border-color: #1849e8;
}
.dropdown-menu.show {
  display: block;
}
.space-item.active::before {
  content: url("../icons/tick.svg");
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #665dff;
  /* left: 0;
  top: 0; */
  left: -1px;
  top: -1px;
  text-align: center;
  border-radius: 8px 0px;
}

.space-item p {
  font-weight: 600;
  color: #617992;
  font-size: 12px;
  margin: 0;
  text-transform: lowercase;
}

.space-item .roleName {
  text-transform: capitalize;
  word-break: break-word;
}

.space-header {
  align-items: center;
  padding: 15px 25px;
}

.header-title{
  padding-left: 10px !important;
}

.header-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #2b2b42;
  margin: 0;
  padding-bottom: 20px;
  padding-left: 5px;
}

.user-info h3 {
  font-size: 24px;
  font-weight: 600;
  color: #2b2b42;
  margin: 0;
}

.user-info p {
  font-size: 16px;
  color: #606076;
  font-weight: 500;
  margin: 0;
}
.contact-index {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin: 0 15px 0 0;
}
.contact-index p {
  color: #2b2b42;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  margin: 10px 0 0;
}

.stats ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.stats ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100px;
  height: 100px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin: 0 15px;
}
.stats ul li h2 {
  margin: 0;
  font-weight: 800;
}
.stats ul li p {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #606076;
  margin: 0;
}

.edit-user {
  display: flex;
}

.edit-user input {
  color: #3e3e3e;
  font-size: 24px;
  border: none;
  border-bottom: 1px solid #9baac7;
}
.edit-user input:focus {
  outline: none;
}
.edit-user-btn {
  position: absolute;
  background: #ebebeb;
  height: 32px;
  width: 32px;
  margin-left: 10px;
  border: none;
  padding: 0px;
  border-radius: 50%;
}

.close-btn-round {
  background: transparent;
  height: 32px;
  width: 48px;
  margin-left: 10px;
  border: 1px solid #1849e8;
  border-radius: 50%;
  padding: 0px;
}
.close-btn-round .a {
  /* fill: #1849e8; */
  stroke-width: 1.2px;
  stroke: #1849e8;
}
.save-btn-round,
.close-btn-round {
  z-index: 1;
}
.save-btn-round {
  background: #1849e8;
  height: 32px;
  width: 49px;
  margin-left: 10px;
  border: 1px solid #1849e8;
  border-radius: 50%;
  padding: 0px;
}

.capture-container label {
  margin-left: 15px;
}
.save-btn-round .a {
  fill: #ffffff;
  color: #ffffff;
}

.tui-image-editor-submenu use.normal.use-default {
  fill-rule: evenodd;
  fill: #ffffff !important;
  stroke: #ffffff !important;
}
.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-button
  > label,
.tui-image-editor-container
  .tui-image-editor-range-wrap.tui-image-editor-newline.short
  label,
.tui-image-editor-container
  .tui-image-editor-range-wrap.tui-image-editor-newline.short
  label
  > span {
  color: #fff !important;
}
.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-submenu-item
  .tui-image-editor-button.apply
  label,
.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-submenu-item
  .tui-image-editor-button.cancel
  label {
  vertical-align: 0px !important;
}
.header-cta-wrapper > .primary-cta {
  width: 154px;
}
/* responsive css */
@media screen and (min-width: 1024px) {
  .rename-space-modal-width {
    width: 400px !important;
  }

  .edit-max-occupancy{
    width: 435px !important;
  }
}

.noSpacesFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center;
  height: 165px;
  margin: 0 0 8vh 0;
  text-align: center;
}
.noSpacesFound > span {
  padding-top: 10vh;
}
.thereAreNoSpaces {
  background-image: url('../images/svg/bg_without_spaces.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.link-item {
  cursor: pointer;
  color: #469fff;
  font-weight: 600;
}

.withoutSpaces {
 flex-direction: row !important;
}

/* tablets */
@media screen and (max-width: 1024px) {
  .rename-space-modal-width {
    width: 400px !important;
  }

  .edit-max-occupancy{
    width: 435px !important;
  }
}

@media screen and (max-width: 680px) {
  .space-item {
    width: 100% !important;
    margin: 8px 0px;
  }
}

@media screen and (max-width: 768px) {
  .sub-container {
    box-shadow: none !important;
  }
  .header-cta-wrapper {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .header-cta-wrapper .primary-cta {
    width: 100%;
  }
  .contact-index p {
    font-size: 12px;
  }

  .rename-space-modal-width {
    width: 400px !important;
  }

  .edit-max-occupancy{
    width: 435px !important;
  }

  .space-item label {
    text-align: -webkit-center;
  }
  .user-info h3 {
    word-break: break-all;
    margin-right: 10px;
  }
}
/* tablets */

/* mobiles */
@media screen and (max-width: 480px), (orientation: portrait) {
  .workspacelist {
    min-height: 100vh !important;
  }
  /* .user-profile {
    max-height: none;
    background-size: cover;
  } */
  .user-details {
    flex-direction: column;
  }
  .user-info {
    text-align: center;
    padding: 15px 0;
  }
  .user-info h3 {
    font-size: 16px;
  }
  .user-info p {
    font-size: 14px;
  }
  .contact-index p {
    text-align: center;
  }
  .rename-space-modal-width {
    width: auto !important;
  }

  .edit-max-occupancy{
    width: auto !important;
  }

  .edit-user-btn {
    height: 24px;
    width: 24px;
    margin-left: 5px;
  }
  .edit-user-btn svg {
    width: 12px;
  }
}

@media screen and (max-width: 480px) {
  .avatar {
    width: 120px !important;
    height: 120px !important;
  }
  .container-avatar {
    top: calc(-60px) !important;
  }

  .subtitle-header.subspace-list{
    display: inherit !important;
    padding:30px 0px !important;
  }

  .subtitle-header.subspace-list> span{
    padding-left: 4rem !important;
  }
}
@media only screen and (orientation:landscape) and (max-width: 823px){
  .space-item {
    width: 100% !important;
    margin: 8px 0px;
}
}


@media screen and (max-width: 414px), (orientation: portrait) {
  .workspacelist {
    min-height: 100vh !important;
    height: 100%;
    overflow: auto;
  }
}

@media screen and (max-width: 375px) {
  .stats ul li {
    height: 80px;
    width: 80px;
  }

  .rename-space-modal-width {
    width: auto !important;
  }

  .edit-max-occupancy{
    width: auto !important;
  }

}

@media screen and (max-width: 380px) {
  .stats ul li {
    height: 60px;
    width: 60px;
  }
  .rename-space-modal-width {
    width: auto !important;
  }

  .edit-max-occupancy{
    width: auto !important;
  }

  .capture-container button {
    margin-left: 5px;
    font-size: 12px;
    padding: 0.25rem 5px !important;
  }
  .capture-container label {
    font-size: 12px !important;
  }
  .capture-container .change-camera-btn {
    padding: 0px !important;
  }

  .subtitle-header.subspace-list{
    display: inherit !important;
    padding:30px 0px !important;
  }

  .subtitle-header.subspace-list> span{
    padding-left: 4rem !important;
  }
}
/* mobiles */

/*Rename space styles*/

.safely-modal .modal-content {
  border-radius: 10px;
  padding: 15px;
}

.safely-modal .modal-content .modal-header,
.qrCodePopUP .modal-content .modal-header {
  border-bottom: none;
}

.safely-modal .modal-content .modal-header > button {
  position: absolute;
  top: 14.11px;
  right: 14.28px;
}

.safely-modal .modal-content .modal-header .modal-title {
  font-size: 22px;
  font-weight: 700;
}
.qrCodePopUP .modal-content .modal-header .modal-title {
  font-size: 22px;
  text-align: center;
}

.safely-modal .modal-content .modal-footer,
.qrCodePopUP .modal-content .modal-footer {
  border-top: none;
}

.header-subtitle {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 30px;
  margin-top: -10px;
  padding-bottom: 10px;
}
.subtitle-back-button .fa-arrow-left {
  color: #665dff;
  padding-right: 8px;
  font-size: 16px;
}
.subtitle-back-button {
  cursor: pointer;
  padding-bottom: 5px;
  width: fit-content;
}
.back-button-text,
.subtitle-header span {
  color: #606076;
}
.subtitle-header h4,
.subtitle-header span {
  display: inline-block;
  padding-right: 10px;
}
.subtitle-header h4 {
  text-transform: capitalize;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.subtitle-header {
  padding: 25px 0;
}

.space-header-addmember .subtitle-header{
  padding: 35px 0px;
}

.subtitle-header.subspace-list{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.renderModalCreatSubSpace .modal-footer button {
  min-width: 100px;
}

.header-cta-wrapper button:focus {
  outline: none !important;
}
.space-sub-item {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  margin: 10px 0px;
  padding: 0px !important;
  display: -webkit-box;
  cursor: pointer;
}
.user-icon-div {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-right: 1px solid #c6c6c6;
  width: 54px;
  height: 70px;
  text-align: center;
  vertical-align: bottom;
  border-radius: 5px 0px 0px 5px;
}
.user-icon-div svg {
  margin-top: 25px;
}
.user-subspace {
  height: 70px;
  padding: 10px;
  padding-right: 0px;
  width: calc(100% - 80px);
}
.sub-space-wrapper {
  padding: 0 25px;
}
.user-subspace label {
  cursor: pointer;
  text-align: left;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2b2b42;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding: 0px;
}
.user-subspace p {
  text-align: left;
  letter-spacing: 0px;
  color: #617992;
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.space-sub-item.active {
  border-color: #1849e8;
}
.subspace-member-image img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.subspace-member-block {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  display: -webkit-box;
  height: 70px;
  padding: 10px;
  margin: 10px 0px;
}
.subspace-member-name {
  margin-left: 15px;
  font-size: 16px;
  color: #585858;
  font-weight: 500;
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeMember {
  position: absolute;
  right: 24px;
  top: 10px;
  color: #5a5a5a;
  cursor: pointer;
}
.subspace-member-role {
  color: #808080 !important;
  font-size: 13px;
}
.removeText {
  font-size: 17px;
  color: #6e6e7c;
  font-family: "Roboto", sans-serif;
}
.removeText>span {
  font-weight: bold;
}

/* Ellipsis text */
.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone .header-cta-wrapper-subspace,
.tablet .header-cta-wrapper-subspace,
.phone .header-cta-wrapper-addmember,
.tablet .header-cta-wrapper-addmember {
  height: auto;
}
.space-header-addmember {
  padding-bottom: 10px;
}
.phone .subspace-header,
.phone .space-header-addmember,
.phone .space-header {
  padding-bottom: 9px;
}
.tablet .subspace-member-name,
.phone .subspace-member-name {
  padding-right: 13px;
}

.qrCodePopUP
  > div
  > .modal-content
  .modal-footer
  .border-radius-none.btn-light {
  background-color: #fff;
  border-color: #6c64fb;
  color: #6c64fb;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.qrCodePopUP
  > div
  > .modal-content
  .modal-footer
  .border-radius-none.btn-light:hover {
  background-color: #fff;
  color: #6c64fb;
}

.qrCodePopUP > div > .modal-content .modal-footer > button {
  background-color: #6c64fb;
  border-color: #6c64fb;
  color: #fff;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.qrCodePopUP > div > .modal-content .modal-footer > button:hover {
  background-color: #6c64fb;
  border-color: #6c64fb;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.qrCodePopUP .Box-containerQR {
  width: fit-content;
  margin: 0 auto;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
}
.qrCodePopUP .modal-content .modal-footer {
  padding-top: 30px;
}

.safty-pass-text-title {
  margin-top: 5px;
  color: #2b2b42;
  font-size: 18px;
  font-weight: 600;
  margin-left: 40px;
  padding: 10px 5px;
}
.safty-pass-block {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c6c6c6;
  border-radius: 14px;
  opacity: 1;
  padding: 10px 20px;
  margin: 25px 35px 5px 35px;
  display: flex;
  justify-content: space-between;
  max-width: 750px !important;
}
@media screen and (max-width: 768px) {
  .safty-pass-block {
    flex-direction: column;
  }
  .safty-pass-block > button {
    margin-top: 10px;
    align-self: flex-end;
  }
}
.safty-pass-text {
  color: #585858;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
}
.safty-pass-button {
  height: auto;
  border: 1px solid #665dff !important;
  background: #665dff 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  padding: 5px 0px;
  padding-right: 20px !important;
  font-weight: 500;
}
.safty-pass-button:hover,
.safty-pass-button:focus {
  border: 1px solid #665dff;
  background: #665dff 0% 0% no-repeat padding-box;
}
.safty-pass-button svg {
  width: 30px;
  height: 20px;
}
.space-info-continer {
  background: #f9fbfe 0% 0% no-repeat padding-box;
  border: 1px solid #eceded;
  border-radius: 14px;
  margin: 28px 10px 0;
}
.space-info-text {
  color: #2b2b42;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  background: #fff;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.arrowIcondown::after,
.arrowIconup::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  color: #a7a6a6;
  content: "";
  display: inline-block;
  height: 0.6em;
  width: 0.6em;
  left: 0;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.arrowIcondown::after {
  transform: rotate(135deg);
  top: 5px;
}
.arrowIconup::after {
  transform: rotate(315deg);
  top: 10px;
}
.arrowIcondown,
.arrowIconup {
  float: right;
  padding-right: 20px;
}
.space-image-block {
  width: 100%;
  height: 180px;
  background: #C3DF9A 0% 0% no-repeat padding-box;
}
.space-info-open {
  border-radius: 14px;
}
.space-qr-code-block {
  clear: both;
  width: 100%;
  height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eceded;
}
.space-name-edit-block {
  width: 100%;
  min-height: 60px;
}
.space-view-block {
  width: 100%;
  min-height: 70px;
  text-align: center;
  padding-top: 20px;
}
.space-preferences-block {
  min-height: 242px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eceded;
  margin-bottom: 10px;
}
.space-name-edit-name {
  color: #324856;
  padding: 10px;
  padding-bottom: 0px;
  font-weight: bold;
  font-size: 16px;
}
.space-name-edit-ownerdeatils {
  color: #324856;
  padding: 0 10px;
  font-weight: 500;
  font-size: 12px;
}
.dispalyLine {
  display: inline-block;
}
.space-name-edit {
  float: right;
  padding-right: 20px;
  margin-top: 15px;
  padding-bottom: 5px;
}
.space-name-edit-cricle {
  background: #665dff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  margin: 0 2px;
}
.space-name-edit-name-input {
  outline: none !important;
  margin-bottom: 5px;
}

.space-name-edit-name .add-new-post-col {
  padding: 0px !important;
}
.space-name-edit-name .spinner-loader {
  margin-top: 0px !important;
}
.view-space-button,
.view-space-button:hover {
  border: 1px solid #665dff !important;
  border-radius: 4px !important;
  background: #f9fbfe !important;
  color: #665dff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.space-image-edit {
  z-index: 1;
  position: absolute;
  margin-top: -50px;
  right: 43px;
}
.space-image-edit svg {
  width: 15px;
  height: 24px;
}
.space-image-block img {
  width: 100%;
  height: 100%;
}
.space-qr-code-container {
  text-align: center;
  width: 100%;
}
.space-qr-code-container .Box-containerQR {
  display: inline-block;
  padding: 15px;
}
.space-qr-code-name {
  color: #324856;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}
.space-qr-code-block-header {
  padding: 10px;
}
.space-qr-code-download {
  float: right;
  margin-right: 12px;
  cursor: pointer;
  font-size: 18px;
}
.workspace-preferencess-icon-block {
  text-align: right;
  padding-right: 40px !important;
}
.space-preferences-block .react-switch-label {
  display: inline-block;
}
.space-preferences-block .workspace-preferencess-text-block,
.space-preferences-block .workspace-preferencess-icon-block {
  padding-top: 5px;
  padding-bottom: 5px;
}
.space-colorPicker {
  position: absolute;
  right: 0;
  margin-right: 40px;
}
.workspace-preferencess-text-block {
  padding-left: 30px !important;
  color: #585858;
}
.workspace-preferencess-text-block svg {
  padding: 1px;
  margin: 0 5px;
}
.workspace-preferencess-title {
  color: #324856;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}
.workspace-preferencess-save-button {
  text-align: center;
  margin-bottom: 5px;
}
.workspace-preferencess-save-button .nextButton {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 12px;
  height: 36px;
}
.sentinvetationspace {
  width: 15px;
  height: 20px;
}
.workspace-form-eidt {
  color: #655dfd;
  padding-left: 8px;
  cursor: pointer;
}
.workspace-preferencess-save-button .nextButton:focus {
  background-color: #665dff !important;
  border-color: #665dff !important;
  outline: none !important;
  box-shadow: none !important;
}
.space-navegation-tap {
  text-align: right;
  padding-right: 35px;
  cursor: pointer;
  float: right;
}
.phone .safty-pass-text-title,
.tablet .safty-pass-text-title,
.tablet .safty-pass-block,
.phone .safty-pass-block {
  margin-left: 0px;
}
.spaceinfoback {
  padding-right: 5px;
}
.searchSpace .user-search {
  max-width: 300px;
}
.phone .searchSpace .user-search {
  max-width: 100%;
}
.noSpaceImage-spaceInfo {
  text-align: center;
  color: #ffffff;
  padding-top: 30px;
  font-size: 78px;
  height: 180px;
  text-transform: uppercase;
}

.tablet .safty-pass-block,
.phone .safty-pass-block {
  margin-right: 0px;
}
.noSpaceImage-worksapce{
  color: #fff;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  background: #C3DF9A 0% 0% no-repeat padding-box;
}
.noSpaceImage-worksapce div{
  text-align: center;
  margin-top: 10px;
  width: 40px;
  height: 40px;
}
.space-colorPicker .color-cricle div{
  border-radius: 50% !important;
}
.space-colorPicker .color-cricle{
  box-shadow: none !important;
}
.continer-user-subspace{
  padding-top: 23px;
}
.rounded-circle-small {
  border-radius: 50% !important;
  position: absolute;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  height: 40px !important;
  width: 40px !important;
  text-transform: uppercase;
}

.deleteSubSpaceText{
  color:#6E6E7C;
  font: Regular 17px/26px "Roboto", Serif;
}

.delete-image-icon {
  font-size: 23px !important;
  vertical-align: middle !important;
}
.space-name-delete-circle {
  background: #E67070 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 2px;
}
.space-delete-block{
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #ECEDED;
opacity: 1;
padding: 10px;
}
.end-block{
  min-height: 10px;
}
.space-delete{
  color: #E04746;
  font-size: 13px;
  cursor: pointer;
  width: fit-content;
}
.space-delete svg{
  padding: 2px;
  margin: 0 0 0 10px;
  vertical-align: bottom;
}
.deleteSpaceEmail{
  color: #4FA3FF;
}

.create-subspace-title{
  color: #617992 !important;
  font-family: "Roboto", Serif;
  font-size: 14px !important;
}

.btn-checkin-subspace.disabled-checkin:disabled{
    color: #BABABA;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border: none;
}

.span-check-into.max-occupancy{
  color: #B9B9B9;
}

.checkout-subspace.selected-subspace{
  color: #6E6E7C;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}

.checkout-subspace.question-text{
  color: #6E6E7C;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}
.tablet .space-item{
  width: 100%;
}
.phone .space-item{
  width: 100%;
}


.spaceleave-disable{
  color: #666666;
  cursor: not-allowed;
}
.spaceleavesvg-disable path{
  fill: #666666;
}


.safely-views {
  padding: 20px 40px !important;
}

.render-space {
  position: relative;
  display: flex;
  align-items: center;
  /* min-height: 70px; */
}

.render-space .space-name {
  font-size: 15px;
  font-weight: 600;
}