

.forgotPass-header .login-header {
    height: 105px;
}
.forgot-para-text {
    text-align: center;
    width: 100%;
    max-width: 360px;
    font-size: 17px;
    font-family: "Roboto", Serif !important;
    letter-spacing: 0px;
    color: #6E7E88;
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 5px;
}
.forgotPass-form {
    min-height: 100px;
}
.forgot-socialIcon {
    padding-top: 10px !important;
}
.bOdgSF {
    width: 100% !important;
}
.send-btn .btn-primary {
    background-color: #665DFF !important;
    border: #665DFF !important;
}
.send-btn .btn-primary:focus, .btn-primary:active {
    background-color: #665DFF !important;
    border: #665DFF !important;
}
.send-btn .btn-primary:disabled {
    background-color: #DDDDDD !important;
    border: #DDDDDD !important;
}
.send-btn {
    margin-bottom: 12px;
    padding-top: 12px;
}
.alert-success {
    background: #39B97E 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    text-align: center;
    font-family: "Montserrat", Serif !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.displayWrapper {
    flex-direction: column !important;
}