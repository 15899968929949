html,
body {
  height: 100%;
  background-color: #F1F5FD !important;
}

#root {
  height: 100%;
}

@font-face {
  font-family: "icomoon";
  src: url("../icons/fonts/icomoon.eot?35hk5e");
  src: url("../icons/fonts/icomoon.eot?35hk5e#iefix")
      format("embedded-opentype"),
    url("../icons/fonts/icomoon.ttf?35hk5e") format("truetype"),
    url("../icons/fonts/icomoon.woff?35hk5e") format("woff"),
    url("../icons/fonts/icomoon.svg?35hk5e#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", Serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clear:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e90e";
  color: #fff;
}
.icon-andon:before {
  content: "\e90c";
  color: #fff;
}
.icon-configuration:before {
  content: "\e90d";
  color: #fff;
}
.icon-workInstruction:before {
  content: "\e90b";
  color: #fff;
}
.icon-angleDown:before {
  content: "\e900";
  color: #fff;
}

.icon-angleDown-after::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "\e900";
  color: #13988a;
}
.icon-angleLeft:before {
  content: "\e901";
  color: #fff;
}
.icon-angleRight:before {
  content: "\e902";
  color: #fff;
}
.icon-checkLists:before {
  content: "\e903";
  color: #fff;
}
.icon-dashboard:before {
  content: "\e904";
  color: #fff;
}
.icon-deviceManagement:before {
  content: "\e905";
  color: #fff;
}
.icon-notices:before {
  content: "\e906";
  color: #fff;
}
.icon-organizationManager:before {
  content: "\e907";
  color: #fff;
}
.icon-productionLines:before {
  content: "\e908";
  color: #fff;
}
.icon-reports:before {
  content: "\e909";
  color: #fff;
}
.icon-userManagement:before {
  content: "\e90a";
  color: #fff;
}

/*===========Sidebar styles============*/
.app-sidebar__inner {
  padding: 2px 0rem 1rem;
  width: 100%;
}

.metismenu {
  font-size: 13.5px;
  position: relative;
  padding-bottom: 70px;
}

/*Checking------------*/
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link::before {
  content: " ";
  margin-right: 52px;
}

.metismenu-container,
.metismenu-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.metismenu-container {
  list-style: none;
}

.metismenu-container .metismenu-container {
  transition: padding 300ms;
  -webkit-transition: padding 300ms;
  background: var(--bg-submenu-sidebar);
}

.metismenu-container .metismenu-container .metismenu-item .metismenu-link {
  height: 0;
  overflow: hidden;
}

.metismenu-container
  .metismenu-container
  .metismenu-item
  .metismenu-link::before {
  content: " ";
  padding-left: 6px;
  margin-right: 43px;
}

.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 2em;
}

.metismenu-container.visible {
  padding: 0.5em 0;
}

.metismenu-container.visible > .metismenu-item > .metismenu-link {
  height: 40px;
}

/*For first menu options when :hover*/
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link:hover {
  background-color: var(--bg-onhover-sidebar);
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-link:hover,
.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link:hover
  i.metismenu-state-icon::before,
.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link:hover
  i.metismenu-icon::before {
  color: var(--font-color-onhover-sidebar);
}

/*List of each menu elements of each menu*/
.metismenu
  .metismenu-container
  .metismenu-container
  > .metismenu-item
  > .metismenu-link {
  color: var(--font-color-submenu-sidebar);
  text-decoration: none;
}

/*List of each menu elements when hover*/
.metismenu .metismenu-container .metismenu-container .metismenu-item:hover {
  background-color: var(--bg-onhover-sidebar);
}

.metismenu .metismenu-container .metismenu-container .metismenu-item:hover,
.metismenu
  .metismenu-container
  .metismenu-container
  .metismenu-item
  .metismenu-link:hover {
  color: var(--font-color-onhover-sidebar);
}

/*Animate when close a menu list of an element*/
.metismenu-container .metismenu-item .metismenu-link {
  transition: color 300ms, background-color 300ms;
  -webkit-transition: color 300ms, background-color 300ms;
  display: block;
  height: 40px;
  text-decoration: none;
  color: var(--font-color-menu-sidebar);
  line-height: 40px;
}

/*Menu title styles when an item is selected  <andon>->submitem-1*/
.metismenu > .metismenu-container .metismenu-link.has-active-child,
.metismenu > .metismenu-container .metismenu-link.active {
  background-color: var(--bg-active-menu-item-sidebar);
  color: var(--bg-line-selected-item);
}

.metismenu > .metismenu-container .metismenu-link.active,
.metismenu .metismenu-item > .metismenu-link.active > i.metismenu-icon::before {
  color: var(--bg-line-selected-item);
  font-weight: 600;
}

.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link.active::before {
  content: " ";
  padding-top: 14px;
  padding-bottom: 14px;
}

.metismenu > .metismenu-container .metismenu-link.has-active-child,
.metismenu
  .metismenu-container
  .metismenu-item
  .metismenu-container
  .metismenu-link.active,
.metismenu
  > .metismenu-container
  .metismenu-link.has-active-child
  i.metismenu-icon::before,
.metismenu
  > .metismenu-container
  .metismenu-link.has-active-child
  i.metismenu-state-icon::before {
  font-weight: 500;
  color: var(--bg-line-selected-item);
}

.metismenu
  .metismenu-container
  .metismenu-container
  .metismenu-item
  .metismenu-link.active::before {
  padding-top: 14px;
  content: " ";
  position: relative;
  left: 0;
  /* margin-right: 30px; */
  padding-left: 6px;
  padding-bottom: 14px;
  transition: margin-right 0.4s linear;
}

.metismenu .metismenu-item > .metismenu-link > i {
  line-height: 40px;
}

/*For icons left of Sidebar*/
.metismenu .metismenu-item > .metismenu-link > i.metismenu-icon {
  text-align: center;
  width: 1.5em;
  position: absolute;
  font-size: 14px;
  left: 18px;
}

.metismenu .metismenu-item > .metismenu-link i.metismenu-state-icon {
  transition: transform 300ms;
  -webkit-transition: transform 300ms;
  text-align: center;
  /* width: 3em; */
  position: absolute;
  float: right;
  right: 21.64px;
  font-size: 12px;
}

i.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.app-header {
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 10;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
}

.header-shadow {
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
}

.fixed-header {
  position: fixed;
  top: 0;
}

.app-container {
  margin: 0;
  background-color: var(--bg-main-menu);
}

i.metismenu-icon.icon-userManagement {
  font-size: 11px !important;
}

i.metismenu-icon.icon-configuration {
  font-weight: bold !important;
}

/*==Dropdown user==*/
.dropdown-menu {
  min-width: 6rem;
}

.dropdown-user > .dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-user > .dropdown-toggle {
  background-color: #fff !important;
  border-color: #fff !important;
}

.dropdown-user > .dropdown-toggle::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;

  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  color: #a7a6a6;
  content: "";
  display: inline-block;
  height: 0.6em;
  width: 0.6em;
  left: 0;
  position: relative;
  vertical-align: top;
  top: 10px;
  transform: rotate(135deg);
}

/*===========Collapsed sidebar=======*/
.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link {
  text-indent: -99rem;
  display: block;
  justify-content: center;
  align-items: center;
}

.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link
  > i.metismenu-icon {
  text-indent: 0;
  left: 25px;
}

.sidebar-collapsed
  .metismenu
  .metismenu-container
  .metismenu-container.visible {
  padding: 0;
  display: none;
}

.sidebar-collapsed
  .metismenu
  > .metismenu-container
  > .metismenu-item:hover
  > .metismenu-container {
  position: absolute;
  margin-top: -40px;
  z-index: 300;
  left: 82.58px;
  display: block !important;
  transition: display 300ms;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px !important;
}

.sidebar-collapsed .metismenu > .metismenu-container > .metismenu-item:hover {
  background: var(--bg-onhover-sidebar);
}

.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-item:hover
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link {
  height: 40px;
  width: 250.81px;
  position: relative;
}

.sidebar-collapsed i.metismenu-state-icon {
  height: 44px;
  width: 7px;
  right: 12px !important;
  font-size: 12px !important;
  text-indent: 0;
}

.sidebar-collapsed i.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link.active::before {
  content: " ";
  padding-left: 6px;
  padding-top: 15px;
  padding-bottom: 30px;
}

/*=====Media queries=====*/
/* @media only screen and (max-width: 480px) {
  .dropdown-user > .dropdown-menu.show {
    transform: translate(-25px, -5px);
  }
} */

@media (max-width: 700px) {
  .sidebar-collapsed
    > .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-container {
    width: 242.81px;
  }

  .sidebar-collapsed
    .metismenu
    .metismenu-container
    .metismenu-container
    .metismenu-item
    .metismenu-link::before {
    margin-right: 20px;
  }
}

.metismenu > .metismenu-container .metismenu-link.has-active-child:hover,
.metismenu > .metismenu-container .metismenu-link.active:hover {
  background-color: var(--bg-active-menu-item-sidebar);
  color: var(--bg-line-selected-item);
}

@media (hover: none) and (pointer: coarse) {
  .metismenu > .metismenu-container > .metismenu-item > .metismenu-link:hover {
    background-color: var(--bg-active-menu-item-sidebar);
    color: var(--bg-line-selected-item);
  }

  .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-link:hover
    i.metismenu-state-icon:before,
  .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-link:hover
    i.metismenu-icon:before {
    color: var(--bg-line-selected-item);
  }

  .metismenu > .metismenu-container .metismenu-link.has-active-child:hover,
  .metismenu > .metismenu-container .metismenu-link.active {
    background-color: var(--bg-active-menu-item-sidebar);
    color: var(--bg-line-selected-item);
  }

  .metismenu
    > .metismenu-container
    .metismenu-link.has-active-child:hover
    i.metismenu-icon::before,
  .metismenu
    > .metismenu-container
    .metismenu-link.has-active-child:hover
    i.metismenu-state-icon::before {
    font-weight: 600;
    color: var(--bg-line-selected-item);
  }

  .metismenu .metismenu-container .metismenu-container .metismenu-item:hover {
    background-color: #fff;
  }

  .metismenu
    .metismenu-container
    .metismenu-container
    .metismenu-item
    .metismenu-link:hover {
    color: var(--font-color-submenu-sidebar);
  }
}

.side-drawer {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  z-index: 1032;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-267px);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer-desktop {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  z-index: 1032;
  transition: width 0.3s;
}

.side-drawer-desktop.collapsed {
  width: 82.58px;
  transition: width 0.3s;
  overflow-y: unset;
}

.scrollbar {
  width: 260px;
  height: 90%;
  transition: width 0.3s;
  overflow-y: overlay;
  max-height: calc(100% - 225px);
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(84, 84, 84, 0.56);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 98, 98, 0.53);
}

.scrollbar.collpased {
  width: 82.58px;
  height: 90%;
  transition: width 0.3s;
  overflow-y: unset;
}

.sidebar-logo {
  overflow: hidden;
  width: 60px;
  height: 100%;
  margin: 0 auto;
  display: block;
}

/*  Import Contacts   */
.ImportContact > div > .modal-content {
  border-radius: .7rem;
}

.ImportContact > div > .modal-content
.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.ImportContact-title {
  font-weight: bold;
  font-size: 1.5rem !important;
  color: #000 !important;
}

.ImportContact-CSVTitle {
  font-size: 0.9rem !important;
  font-weight: bold;
  cursor: pointer;
}

.ImportContact > div > .modal-content
.modal-body {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.ContactsTable  > div > .modal-content
.modal-footer,
.ImportContact > div > .modal-content
.modal-footer {
  border-top: 0;
  padding: 0 2rem 2rem 2rem;
}

.ContactsTable > div > .modal-content
.modal-footer .btn-outline-primary,
.ImportContact > div > .modal-content
.modal-footer .btn-outline-primary {
  background-color: #fff;
  border-color: #6c64fb;
  color: #6c64fb;
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.ContactsTable > div > .modal-content
.modal-footer .btn-outline-primary:hover,
.ImportContact > div > .modal-content
.modal-footer .btn-outline-primary:hover {
  background-color: #6c64fb;
  color: #fff;
}

.ContactsTable > div > .modal-content
.modal-footer > button,
.ImportContact > div > .modal-content
.modal-footer > button {
  background-color: #6c64fb;
  border-color: #6c64fb;
  color: #fff;
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.ContactsTable > div > .modal-content
.modal-footer > button:hover,
.ImportContact > div > .modal-content
.modal-footer > button:hover {
  background-color: #5750ce;
  border-color: #5750ce;
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.ImportContacts-Buttons {
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.reports-buttons {
  padding: .5rem 1.5rem .5rem 1.5rem;
  border-color: #665DFF;
  color: #665DFF;
}


/* .reports-buttons:disabled:hover, */
.reports-buttons:focus,
.reports-buttons:hover,
.reports-buttons:active {
  background-color: #665DFF !important;
  border-color: #665DFF !important;
  color: #fff !important;
  box-shadow: 0 0 0 0.2rem #665DFF !important;
}

.reports-buttons:disabled{
  cursor: not-allowed;
  border-color: #D3D3D3!important;
  color: #d3d3d3!important;
  background-color: #F2F2F2!important;
}
.reports-buttons:disabled:hover,
.reports-buttons:disabled:focus {
  outline: none;
  border-color: #D3D3D3!important;
  color: #d3d3d3!important;
  box-shadow: none!important;
  background-color: #F2F2F2!important;
}

#image-name {
  padding-left: 1rem;
}

/*  CSV Component */
.CSVDelete {
  font-size: 1.5rem;
  position: absolute;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .CSVDelete {
    font-size: 1rem;
  }
}

.CSVComponent .CSVDelete {
  padding-left: 1rem;
  padding-top: .5rem;
}

.CSVComponent .CSVDelete:hover {
  font-size: 2rem;
  padding-top: .2rem;
}

/*  Component Table */
.contactsTable-title {
  font-size: 1.375rem;
  font-weight: bold;
}
.contactsTable-paginationFooter {
  align-items: baseline;
  text-align: center;
  margin-bottom: 5px;
}
.contactsTable-paginationFooter-Navigation > div > span {
  padding: 0 5px;
}
.contactsTable-paginationFooter-perPage {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 0;
}
.contactsTable-paginationFooter-perPage > div {
  padding: 0 5px;
}

@media screen and (min-width: 768px) and (max-width: 992px){
  .contactsTable-paginationFooter-Showing {
    padding-bottom: 7px;
  }
}
@media screen and (max-width: 768px){
  .contactsTable-paginationFooter {
    align-items: baseline;
  }
  .contactsTable-paginationFooter > div {
    padding: 5px 0;
    font-size: 10px;
  }
  .contactsTable-paginationFooter-perPage {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 0;
  }
  .contactsTable-paginationFooter > div .reports-buttons {
    padding: 2px;
    font-size: 10px;
  }
}
@media screen and (min-width: 576px){
  .contactsTable-paginationFooter > div .reports-buttons {
    padding: 5px;
  }
}
@media screen and (min-width: 768px){
  .contactsTable-paginationFooter > div .reports-buttons {
    padding: 7px;
  }
  .contactsTable-paginationFooter > div {
    font-size: 12px;
  }
}

@media screen and (max-width: 640px) {
  .contactsTable-title {
    font-size: 1.375rem;
    font-weight: bold;
    text-align: center;
    padding: 0 0 1rem 0;
  }
  .contactsTable-search {
    padding: 0 15px;
  }
  .contactsTable-search input {
    font-size: .75rem;
    margin-right: 200px;
  }
  .contactsTable .table th, .contactsTable .table td {
    padding: .2rem;
    font-size: .625rem;
  }
  .contactsTable-tableContainer {
    overflow: auto;
  }
  .reports-buttons {
    padding: .5rem 1rem .5rem 1rem;
    border-color: #665DFF;
    color: #665DFF;
  }
}

@media screen and (min-width: 576px) {
  .contactsTable .table th, .contactsTable .table td {
    font-size: .6875rem;
  }
}

@media screen and (min-width: 768px) {
  .contactsTable-tableContainer {
    padding: 0 15px 0 15px;
  }
  .contactsTable .table th, .contactsTable .table td {
    font-size: .75rem;
  }
  .checkin-table .contactsTable-paginationFooter-perPage .dropdown .dropdown-menu{
    top: -70px!important;
  }
  .users-table .contactsTable-paginationFooter-perPage .dropdown .dropdown-menu{
    top: -70px!important;
  }
}

@media screen and (min-width: 992px) {
  .contactsTable-title {
    padding-left: 15px;
  }
  .contactsTable-search {
    padding-right: 15px;
  }
  .contactsTable-tableContainer {
    padding: 0 15px 0 15px;
  }
  .contactsTable .table th, .contactsTable .table td {
    font-size: 1rem;
  }
}

.contactsTable-tableContainer div {
  width: 100%;
}

.contactsTable-search label {
  position: absolute;
  padding: 5px 0 0 8px;
  margin-left: 0;
}

.contactsTable-search input {
  padding-left: 30px;
  max-width: 280px;
  display: inline-block;
}

.contactsTable-search input::placeholder {
  color: #c8c8c8;
}

.contactsTable .table>thead:first-child>tr:first-child>th {
  border-top: 0;
}

.contactsTable .table>tbody:last-child>tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.button-Icon {
  display: flex;
  justify-content: center;
}

.ImportedContacts-circle {
  background-color: #01c875 !important;
  border-color: #01c875 !important;
}

.ImportedContacts-span {
  color: #01c875;
  font-size: .8rem;
  font-weight: 500;
  /* padding: .5rem 0 0 3.5rem; */
}

/* Start Sidebar Icons*/
.icon-home::before {
  content: url(../icons/sidebar/home.svg);
}
.icon-pass::before {
  content: url(../icons/sidebar/pass.svg);
}
.icon-location::before {
  content: url(../icons/sidebar/location.svg);
}
.icon-checkpoint::before {
  content: url(../icons/sidebar/checkpoint.svg);
}
.icon-market::before {
  content: url(../icons/sidebar/Icon_Marketplace.svg);
}
.icon-statistics::before {
  content: url(../icons/sidebar/statistics.svg);
}
.icon-users::before {
  content: url(../icons/sidebar/users.svg);
}
.icon-reports::before {
  content: url(../icons/sidebar/reports.svg);
}
.icon-cheeckout_new::before {
  content: url(../icons/sidebar/Icon_CheckPoint.svg);
}
.icon-edit:before {
  content: url(../icons/editIcon.svg);
}
.icon-post:before {
  content: url(../icons/sidebar/icon_post.svg);
}
.active .icon-home::before {
  content: url(../icons/sidebar/icon_home_active.svg);
}
.active .icon-post::before {
  content: url(../icons/sidebar/icon_post_active.svg);
}
.active .icon-pass::before {
  content: url(../icons/sidebar/icon_safelypass_active.svg);
}
.active .icon-location::before {
  content: url(../icons/sidebar/icon_location_active.svg);
}
.active .icon-checkpoint::before {
  content: url(../icons/sidebar/icon_checkpoint_active.svg);
}
.active .icon-cheeckout_new::before {
  content: url(../icons/sidebar/Icon_CheckPoint_Blue.svg);
}
.active .icon-statistics::before {
  content: url(../icons/sidebar/icon_analytics_active.svg);
}
.active .icon-users::before {
  content: url(../icons/sidebar/icon_user_active.svg);
}
.active .icon-reports::before {
  content: url(../icons/sidebar/icon_reports_active.svg);
}
.active .icon-edit:before {
  content: url(../icons/editIcon.svg);
}
.active .icon-market::before {
  content: url(../icons/sidebar/Icon_Marketplace_Blue.svg);
}
/* End Sidebar Icons*/

.spaces-dropdown-item {
  font-family: "Roboto", Serif;
  font-size: 14px;
  color: #5a5a5a;
}
.powered-by{
  position: absolute;
  bottom: 30px;
  color: #5A5A5A;
}

 .powered-by-text{
  padding-left: 20px;
  font-size: 10px;
  padding-right: 5px;
  vertical-align: sub;
  font-weight: 500;
}

 .powered-by-logo svg{
  height: 40px;
  width: 70px;
}

.powered-by-collapseSidebar .powered-by-text{
  padding-left: 10px !important;
}

.powered-by-collapseSidebar .powered-by-logo{
  padding-left: 5px !important;
}
@media screen and (max-width: 768px) {
  .powered-by{
    bottom: 10px;
  }
}

.has-active-child .icon-reports::before {
  content: url(../icons/sidebar/icon_reports_active.svg);
}