.create-workspace-form {
  margin: 0 auto;
  max-width: 440px;
}
.workspace-configuration-form {
  margin: 0 auto;
  max-width: 440px;
}
.create-workspace-form .login100-form-title,
.workspace-configuration-form .login100-form-title {
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0px;
  color: #454f63;
  opacity: 1;
}
.create-workspace-form p,
.workspace-configuration-form p {
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #6e7e88;
  opacity: 1;
}
.create-workspace-form .workspacesName,
.workspace-configuration-form .workspacesName {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  opacity: 1;
}
.create-workspace-form .form-control:focus,
.workspace-configuration-form .form-control:focus {
  border-color: #9baac7;
  box-shadow: none;
}
.create-workspace-form .backButton{
  margin-top: 20px;
  margin-right: 10px;
}
.workspace-configuration-form .nextButton , .workspace-configuration-form .backButton, .create-workspace-form .backButton{
  position: relative;
}
.create-workspace-form .nextButton,
.workspace-configuration-form .nextButton {
  position: relative;
  background: #665dff 0% 0% no-repeat padding-box;
  border: 1px solid #665dff;
  border-radius: 4px;
  margin-top: 20px;
}
.create-workspace-form .nextButton:active,
.create-workspace-form .nextButton:hover,
.workspace-configuration-form .nextButton:active,
.workspace-configuration-form .nextButton:hover {
  background: #665dff 0% 0% no-repeat padding-box !important;
  border: 1px solid #665dff !important;
}
.create-workspace {
  background: #f1f5fd 0% 0% no-repeat padding-box;
  text-align: center;
  overflow-x: hidden;
}
.create-workspace .cricle-progess {
  width: 40px;
  height: 40px;
  border: 1px solid #c5c3ea;
  opacity: 1;
  border-radius: 50%;
}
.create-workspace .cricle-progess-text {
  color: #a5a1ef;
  text-align: center;
  font-size: 15px;
  margin-top: 8px;
}
.create-workspace .cricle-progess-line {
  width: 38px;
  height: 0px;
  border: 1px solid #c5c3ea;
  opacity: 1;
  margin: 2px 5px;
}
.create-workspace-progess-bar .cricle-progess,
.create-workspace-progess-bar .cricle-progess-line,
.create-workspace-progess-bar .patch-div {
  display: inline-block;
}
.create-workspace-progess-bar {
  margin-top: 40px;
}
.create-workspace .login100-form {
  min-height: 300px;
}
.create-workspace .create-workspace-progess-bar .cricle-progess.selected,
.create-workspace .create-workspace-progess-bar .cricle-progess-line.selected {
  border: 1px solid #665dff;
}
.create-workspace .create-workspace-progess-bar .cricle-progess-text.selected {
  color: #665dff;
}
.create-workspace-progess-bar .patch-div {
  width: 15px;
  height: 20px;
  background: #fcfcff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -16px;
  vertical-align: bottom;
}
.createnewSafelyspace {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: #454F63;
  margin: 0 auto;
  width: 100%;
  margin-top: -45px;
  margin-bottom: 10px;
  font-weight: bold;
}
.create-workspace .login100-form-title-paddingTop,
.workspace-configuration-form .login100-form-title {
  padding-top: 25px;
}
.create-workspace .workspaceNameHelp,
.create-workspace .invitationLink {
  position: relative;
}
.create-workspace .input-div {
  width: 100%;
  padding-bottom: 10px;
}
.create-workspace .label-div {
  padding-bottom: 5px;
}
/* .create-workspace label {
  text-align: center;
  width: 100%;
  font-size: 14px;
} */
.create-workspace .invitationLink {
  padding-bottom: 20px;
}
.workspace-configuration-form .addMore {
  position: relative;
  padding-top: 20px;
  color: #4fa3ff;
}
.workspace-configuration-form .fa-plus {
  padding-right: 5px;
}

.workspace-configuration-form .cricle-icon {
  width: 50px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #665dff;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}

.workspace-configuration-form .cricle-icon svg {
  margin-top: 13px;
  width: 22px;
  height: 19px;
  cursor: pointer;
}
.workspace-configuration-form .bottom-button button {
  margin: 0px 10px;
  margin-bottom: 30px;
}
.workspace-configuration-form .backButton , .create-workspace-form .backButton{
  color: #665dff !important;
  border: 1px solid #665dff !important;
  background: #ffffff !important;
  border-radius: 4px;
}
.workspace-configuration-form .backButton:hover ,.create-workspace-form .backButton:hover{
  color: #665dff !important;
  border: 1px solid #665dff !important;
  background: #ffffff !important;
}
.workspace-configuration-form .omit {
  width: 100px;
  margin: 0 auto;
}
.create-workspace .workspace-created {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: #2B2B42;
  margin: 0 auto;
  width: 100%;
  margin-top: -45px;
  margin-bottom: 10px;
  font-weight: bold;
}
.create-workspace .workSpaceNameCreated {
  color: #665dff;
  padding: 0 5px;
}
.create-workspace .workspace-created span {
  display: inline-block;
}
.phone.create-workspace .workspace-created,
.tablet.create-workspace .workspace-created {
  width: auto;
}

.on-board-header {
  width: 100%;
}
.on-board-header .logo {
  text-align: left;
  margin-top: 20px;
  left: 20px;
}
.on-board-header .login-user {
  text-align: right;
  margin-top: 20px;
  right: 20px;
}
.create-workspace .container-login100 {
  display: unset;
}
.phone.create-workspace .wrap-login100,
.tablet.create-workspace .wrap-login100 {
  width: 90%;
}

.phone.create-workspace,
.tablet.create-workspace {
  padding-bottom: 20px;
}

.phone .on-board-header,
.tablet .on-board-header {
  padding-bottom: 30px;
}

.create-workspace .wrap-login100 {
  width: 730px;
  overflow: hidden;
  display: inline-flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  border: none;
  opacity: 1;
  min-height: 350px;
  padding: 10px;
}

.create-workspace .empty-div{
  min-height: 50px;
}

.login-user .dropdown-menu.show{
  top: -25px!important;
}

.login-user .dropdown-user > .dropdown-toggle::after{
display: none;
}
.login-user .dropdownicon{
  padding-left: 10px;
  color: #707070;
}
.login-user .dropdown,.login-user .dropdown-toggle{
  border-radius: 6px;
}
@media (max-width: 360px) {
  .workspace-configuration-form .ml-3, .mx-3{
    margin-left: 0px!important; 
  }
  }
.plus-add {
  font-size: 22px !important;
  vertical-align: bottom !important;
}
.label-error-message {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #E67070;
}
.label-error-message label {
  text-align: left !important;
}
