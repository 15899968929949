.add-new-post-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c6c6c6;
  border-radius: 14px;
  opacity: 1;
  padding: 10px 20px;
  margin-top: 25px;
}
.add-new-post-text {
  color: #585858;
  font-size: 14px;
  width: 60%;
  display: inline-block;
  font-weight: 600;
}
.add-new-post-button-div {
  display: inline-block;
  width: 40%;
  text-align: right;
}
.add-new-post-button {
  width: 150px !important;
  height: auto;
  border: 1px solid #665dff;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-weight: 500;
  border-radius: 4px !important;
  color: #665dff !important;
}
.add-new-post-button:hover,
.add-new-post-button:focus {
  border: 1px solid #665dff;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.phone .add-new-post-text {
  width: 100%;
}
.phone .add-new-post-button-div {
  width: 100%;
  margin-top: 10px;
}
.desktop .add-new-post-col {
  padding: 0px 50px;
}

.PhotoOrVideoContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 5px;
}

.PhotoOrVideo {
  width: 100%;
  max-width: 205px;
  height: 205px;
  margin: 12px;
  padding: 45px;
  background: #f1f5fd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.photo-icon-style {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  line-height: 2 !important;
  font-size: 30px !important;
  opacity: 1;
  border-radius: 64px;
  cursor: pointer;
}
.photo-icon-style svg {
  width: 30px;
  height: 30px;
}
.photo-icon-text {
  color: #585858;
  margin-top: 10px;
}

.camera-style {
  background-color: #665dff !important;
  border: none;
  text-align: center;
}

.camera-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  align-items: center;
}

.flip-icon-style {
  margin-right: 20px;
  margin-left: 60px;
}
.off.switch-toggle-style .react-switch-button {
  background: #999999;
}
.switch-toggle-style {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}
.switch-toggle-style label {
  padding: 0 5px;
  color: #999999;
  font-weight: 500;
}
.switch-toggle-style .react-switch-button {
  background: #ffffff;
}
@media screen and (max-width: 415px) {
  .PhotoOrVideoContainer {
    display: inherit;
  }
  .PhotoOrVideo {
    margin: 0 auto;
    margin-bottom: 12px;
  }
}

.takePhotoOrVideoPopup .modal-title {
  color: #2b2b42 !important;
  font-size: 22px;
  font-weight: 600;
}
.editimagepopup .row {
  margin: 0px;
}
.takePhotoOrVideoPopup video {
  height: auto !important;
}
.videoRecoderDiv {
  height: 80vh;
}
/* .addPopup .border-radius-none {
  color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #fff;
  width: 120px;
}

.addPopup .border-radius-none:hover {
  color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #fff;
  width: 120px;
} */

.addPopup .post-button,
.addPopup .post-button:hover {
  color: #fff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #6c64fb;
  width: 120px;
}

.addPopup .post-button-disable:hover,
.addPopup .post-button-disable {
  color: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  background: #cccccc;
  width: 120px;
  cursor: not-allowed;
}
.new-post-container {
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 2.5rem;
}

.new-post-image {
  display: inline-block;
}
.new-post-image img,
.new-post-image video {
  width: 375px;
  border-radius: 10px;
}
.new-post-image img {
  height: 341px;
}
.new-post-text-div {
  display: inline-block;
  vertical-align: top;
  padding-left: 30px;
}

.new-post-text-div textarea,
.new-post-text-div textarea:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  max-height: 120px !important;
  min-height: 120px !important;
  width: 320px;
}
.new-post-text-div .custome-multiselect {
  margin-top: 20px;
  width: 320px;
}
.dropdown-container {
  box-shadow: none !important;
  border-color: var(--rmsc-border) !important;
}

@media screen and (max-width: 860px) {
  .new-post-container {
    flex-direction: column;
    margin: 0;
  }
  .new-post-text-div textarea {
    margin-top: 20px;
    width: 100%;
  }
  .new-post-text-div {
    padding-left: 0px;
  }
  .new-post-image img,
  .new-post-image video {
    width: 100% !important;
  }
}
@media screen and (max-width: 320px) {
  .new-post-text-div textarea {
    width: 270px;
  }
  .new-post-text-div .custome-multiselect {
    width: 270px;
  }
  .display-subspace-div {
    width: 270px !important;
  }
}
.display-subspace-div {
  margin-top: 10px;
  width: 320px;
}
.display-subspace-style {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  font-family: "Roboto", Serif;
  letter-spacing: 0px;
  color: #5a5a5a;
  background: #f2f5ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  padding: 6px;
  margin-right: 5px;
  opacity: 1;
  word-break: break-word;
  margin-top: 5px;
  white-space: pre-line;
}

.view-post-div {
  padding: 0px !important;
}
.post-header {
  padding: 10px 20px;
  display: inline-flex;
  width: 100%;
}
.post-footer {
  padding: 10px 20px;
}
.post-user-name {
  text-align: left;
  font-weight: bold;
  font-family: "Montserrat", Serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
}
.post-space-name {
  text-align: left;
  font-family: "Montserrat", Serif;
  font-size: 11px;
  letter-spacing: 0px;
  opacity: 1;
}
.post-space-style {
  display: inline-block;
}
.post-time {
  padding-left: 5px;
}
.post-caption-style {
  text-align: left;
  font: Medium 14px/18px "Montserrat", Serif;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
  word-break: break-all;
  white-space: pre-line;
}
.view-comment-text {
  text-align: left;
  font: Medium 14px/18px "Montserrat", Serif;
  letter-spacing: 0px;
  color: #8f96aa;
  opacity: 1;
}
.user-comment-section {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
}
.post-text-field {
  border: none;
  width: 100%;
  border-radius: 0 !important;
  opacity: 0.5;
  border-bottom: 1px solid #8f96aa;
}
.post-button-style,
.post-button-style:hover {
  text-align: left !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Montserrat", Serif !important;
  letter-spacing: 0px !important;
  color: #665eff !important;
  border: none !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.post-button-style :disabled {
  color: #665eff;
  opacity: 0.3 !important;
}
.post-icons {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.icon-space {
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
}
.spinnerpopup {
  margin: 0 auto;
  max-width: 85px !important;
}
.spinnerpopup .spinner-loader {
  margin-top: 5px !important;
}
.post-space-style:first-child::before {
  content: none !important;
}

.post-space-style::before {
  content: ",";
  padding-right: 5px;
}
.post-space-name span {
  vertical-align: unset !important;
}

.view-comment-text::placeholder {
  color: #8f96aa;
}
.post-count-style {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
}
.outlineColor {
  color: #707070;
  font-size: 22px !important;
}
.fillColor {
  color: #665eff;
  font-size: 22px !important;
}
.editimagepopup .modal-header {
  padding: 10px 12px;
}
.MccLt {
  display: none !important;
}
.recoding-vedio {
  margin-top: 5px;
}
.recoding-buttons-next {
  text-align: right;
}
.recoding-buttons-next button,
.recoding-buttons-next button:hover {
  width: 120px;
  border: 1px solid #665dff;
  background: #665dff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  box-shadow: none;
  margin: 5px;
}
.recodingdiv {
  background: #ce3b3b !important;
}
.startrecord,
.stoprecord {
  margin-top: 18px;
  color: white !important;
}
#timer {
  position: absolute;
  z-index: 999;
  margin-top: -40px;
  color: #fff;
  right: 30px;
  font-size: 16px;
  font-weight: 500;
}
.loading-cal-spinner {
  margin-top: 5px;
  color: #6e6e7c;
  font-size: 12px;
  text-align: center;
}
.infiniteScrollpost,
.infinite-scroll-component__outerdiv {
  overflow-x: hidden !important;
  width: -webkit-fill-available;
}

.add-new-post-continer {
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.post-header-left {
  display: inline-flex;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.post-header-right {
  padding-right: 0px !important;
  padding-left: 0px !important;
  left: 4%;
}
.post-header-right .threedots:after {
  content: "\2807";
  font-size: 22px;
  color: #617992;
  left: 7px;
  top: -3px;
  font-family: none;
}
.post-header-right .dropright:hover {
  width: 24px;
  height: 24px;
  background-color: #f5f5f5;
  border-radius: 50px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
.deletePostText {
  text-align: left;
  font-size: 17px;
  font-weight: normal;
  font-family: "Roboto", Serif !important;
  letter-spacing: 0px;
  color: #6e6e7c;
}
.activeSafelypassdisplay{
  color: #6E6E7C;
}
.add-photo-or-video-link {
  margin-top: 20px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0px;
  color: #665DFF;
  cursor: pointer;
  opacity: 1;
}
.camera-post-icon {
  margin-right: 10px;
  vertical-align: text-bottom;
}
.add-post-text {
  padding: 0 40px;
}
.add-post-text textarea,
.add-post-text textarea:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  max-height: 120px !important;
  min-height: 120px !important;
  width: 100%;
}
.add-post-text .custome-multiselect {
  margin-top: 20px;
  width: 320px;
}
@media screen and (max-width: 860px) {
  .add-post-text {
    padding: 0px;
  }
}
@media screen and (max-width: 320px) {
  .add-post-text textarea {
    width: 270px;
  }
  .add-post-text .custome-multiselect {
    width: 270px;
  }
  .display-subspace-div {
    width: 270px !important;
  }
}
.desktop .postloadmore{
  padding-right: 50px;
}
.phone .postloadmore, .tablet .postloadmore{
  padding-right: 15px;
}
.postloadmore{
  width: 100%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
}
.loadmore{
  width: auto !important;
  text-transform: uppercase;
  font: normal 600 11px/14px "Montserrat", Serif;
}

.postContentModal {
  justify-content: center;
}
.postContentModal .modal-content {
  width: auto;
}

.postModal .modal-footer {
  margin-right: 2.5rem;
}

.postModal .modal-title, .editimagepopup .modal-title {
  font-size: 22px;
  font-weight: bold;
}

.titleAlign .modal-title { 
  padding-left: 40px;
}
@media screen and (max-width: 860px) { 
  .titleAlign .modal-title {
    padding-left: 0px;
  }
  .postModal .modal-footer {
    margin-right: 0;
  }
}
.lastpagethatsAll{
  color: #585858;
  font: normal 500 14px/18px "Montserrat", Serif;
  text-align: center;
}