@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.limiter {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: #f1f5fd;
}

.container-login100 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  width: 360px;
  min-height: 540px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ccc;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 30px;
  padding-top: 50px;
}

.login100-form {
  width: 100%;
}

.login100-form-title {
  font-family: "Montserrat", Serif;
  font-weight: bold;
  font-size: 20px;
  color: #707070;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 20px;
}
.login100-form-sub-title {
  font-family: "Roboto", Serif;
  font-weight: bold;
  font-size: 16px;
  color: #707070;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 10px;
}

.validate-input {
  position: relative;
  margin-bottom: 16px;
}

.input100 {
  /* font-size: 18px;
  line-height: 1.2; */
  color: #686868;
  display: block;
  width: 100%;
  background: #e6e6e6;
  border: 1px solid #1849e8;
  height: 30px;
  border-radius: 8px;
  padding: 5px;
}

.threedots:after {
  content: "\2807";
  font-size: 22px;
  color: #617992;
  position: absolute;
  left: 7px;
  top: -3px;
}

.checkpoint-threedots {
  display: inline-block;
  margin-left: 26px;
}

.checkpoint-threedots .threedots:after {
  position: unset;
}

.space-item.active :hover .threedots:after {
  content: "\2807";
  font-size: 22px;
  color: #617992;
  position: absolute;
  /* top: -3px;
  left: 6px; */
}
.sub-spacetoggle {
  padding-top: 5px;
}
.space-item.active .dropright:hover {
  width: 24px;
  height: 24px;
  background-color: #f5f5f5;
  /* margin-top: 2px; */
  border-radius: 50px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
.space-sub-item.active .sub-spacetoggle .dropright:hover {
  background-color: #f5f5f5;
  /* margin-top: 2px; */
  border-radius: 50px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
/* .list-group-item.active {
  background-color: #c7d2de !important;
  border-color: #c7d2de !important;
} */
/* 
.SafelyPass .list-group-item {
  border: 0 !important;
  padding: 5px 0 !important;
} */

.downLoadAsPdf {
  display: none;
}
.qrCodePopUP .modal-body {
  padding: 0px;
}
.qrCodePopUP .modal-body .close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.qrCodePopUP .modal-body #result {
  width: auto !important;
}

.user-profile {
  max-height: 200px;
  min-height: 200px;
  background: url(/static/media/user-header-bg.4ffb197c.png) center no-repeat;
}

.space-wrapper {
  flex-direction: row;
  padding: 10px 25px;
}
.space-item label {
  cursor: pointer;
  display: inline;
  word-break: break-word;
  font-size: 14px;
  font-weight: 600;
  color: #2b2b42;
  width: -webkit-max-content;
}
.space-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 70px;
  margin: 18px 0px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  border-top-width: 1px;
  box-shadow: 0px 1px 3px #00000029;
}
.space-item:hover {
  cursor: pointer;
}

.space-item .dropright {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2% !important;
  right: 1% !important;
}

/* .dropright .threedots::after {
  margin-top: 0px;
} */
.space-item.active {
  border-color: #1849e8;
}
.dropdown-menu.show {
  display: block;
}
.space-item.active::before {
  content: url(/static/media/tick.de3e32ce.svg);
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #665dff;
  /* left: 0;
  top: 0; */
  left: -1px;
  top: -1px;
  text-align: center;
  border-radius: 8px 0px;
}

.space-item p {
  font-weight: 600;
  color: #617992;
  font-size: 12px;
  margin: 0;
  text-transform: lowercase;
}

.space-item .roleName {
  text-transform: capitalize;
  word-break: break-word;
}

.space-header {
  align-items: center;
  padding: 15px 25px;
}

.header-title{
  padding-left: 10px !important;
}

.header-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #2b2b42;
  margin: 0;
  padding-bottom: 20px;
  padding-left: 5px;
}

.user-info h3 {
  font-size: 24px;
  font-weight: 600;
  color: #2b2b42;
  margin: 0;
}

.user-info p {
  font-size: 16px;
  color: #606076;
  font-weight: 500;
  margin: 0;
}
.contact-index {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin: 0 15px 0 0;
}
.contact-index p {
  color: #2b2b42;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  margin: 10px 0 0;
}

.stats ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.stats ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100px;
  height: 100px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin: 0 15px;
}
.stats ul li h2 {
  margin: 0;
  font-weight: 800;
}
.stats ul li p {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #606076;
  margin: 0;
}

.edit-user {
  display: flex;
}

.edit-user input {
  color: #3e3e3e;
  font-size: 24px;
  border: none;
  border-bottom: 1px solid #9baac7;
}
.edit-user input:focus {
  outline: none;
}
.edit-user-btn {
  position: absolute;
  background: #ebebeb;
  height: 32px;
  width: 32px;
  margin-left: 10px;
  border: none;
  padding: 0px;
  border-radius: 50%;
}

.close-btn-round {
  background: transparent;
  height: 32px;
  width: 48px;
  margin-left: 10px;
  border: 1px solid #1849e8;
  border-radius: 50%;
  padding: 0px;
}
.close-btn-round .a {
  /* fill: #1849e8; */
  stroke-width: 1.2px;
  stroke: #1849e8;
}
.save-btn-round,
.close-btn-round {
  z-index: 1;
}
.save-btn-round {
  background: #1849e8;
  height: 32px;
  width: 49px;
  margin-left: 10px;
  border: 1px solid #1849e8;
  border-radius: 50%;
  padding: 0px;
}

.capture-container label {
  margin-left: 15px;
}
.save-btn-round .a {
  fill: #ffffff;
  color: #ffffff;
}

.tui-image-editor-submenu use.normal.use-default {
  fill-rule: evenodd;
  fill: #ffffff !important;
  stroke: #ffffff !important;
}
.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-button
  > label,
.tui-image-editor-container
  .tui-image-editor-range-wrap.tui-image-editor-newline.short
  label,
.tui-image-editor-container
  .tui-image-editor-range-wrap.tui-image-editor-newline.short
  label
  > span {
  color: #fff !important;
}
.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-submenu-item
  .tui-image-editor-button.apply
  label,
.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-submenu-item
  .tui-image-editor-button.cancel
  label {
  vertical-align: 0px !important;
}
.header-cta-wrapper > .primary-cta {
  width: 154px;
}
/* responsive css */
@media screen and (min-width: 1024px) {
  .rename-space-modal-width {
    width: 400px !important;
  }

  .edit-max-occupancy{
    width: 435px !important;
  }
}

.noSpacesFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center;
  height: 165px;
  margin: 0 0 8vh 0;
  text-align: center;
}
.noSpacesFound > span {
  padding-top: 10vh;
}
.thereAreNoSpaces {
  background-image: url(/static/media/bg_without_spaces.98b2ae37.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.link-item {
  cursor: pointer;
  color: #469fff;
  font-weight: 600;
}

.withoutSpaces {
 flex-direction: row !important;
}

/* tablets */
@media screen and (max-width: 1024px) {
  .rename-space-modal-width {
    width: 400px !important;
  }

  .edit-max-occupancy{
    width: 435px !important;
  }
}

@media screen and (max-width: 680px) {
  .space-item {
    width: 100% !important;
    margin: 8px 0px;
  }
}

@media screen and (max-width: 768px) {
  .sub-container {
    box-shadow: none !important;
  }
  .header-cta-wrapper {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .header-cta-wrapper .primary-cta {
    width: 100%;
  }
  .contact-index p {
    font-size: 12px;
  }

  .rename-space-modal-width {
    width: 400px !important;
  }

  .edit-max-occupancy{
    width: 435px !important;
  }

  .space-item label {
    text-align: -webkit-center;
  }
  .user-info h3 {
    word-break: break-all;
    margin-right: 10px;
  }
}
/* tablets */

/* mobiles */
@media screen and (max-width: 480px), (orientation: portrait) {
  .workspacelist {
    min-height: 100vh !important;
  }
  /* .user-profile {
    max-height: none;
    background-size: cover;
  } */
  .user-details {
    flex-direction: column;
  }
  .user-info {
    text-align: center;
    padding: 15px 0;
  }
  .user-info h3 {
    font-size: 16px;
  }
  .user-info p {
    font-size: 14px;
  }
  .contact-index p {
    text-align: center;
  }
  .rename-space-modal-width {
    width: auto !important;
  }

  .edit-max-occupancy{
    width: auto !important;
  }

  .edit-user-btn {
    height: 24px;
    width: 24px;
    margin-left: 5px;
  }
  .edit-user-btn svg {
    width: 12px;
  }
}

@media screen and (max-width: 480px) {
  .avatar {
    width: 120px !important;
    height: 120px !important;
  }
  .container-avatar {
    top: calc(-60px) !important;
  }

  .subtitle-header.subspace-list{
    display: inherit !important;
    padding:30px 0px !important;
  }

  .subtitle-header.subspace-list> span{
    padding-left: 4rem !important;
  }
}
@media only screen and (orientation:landscape) and (max-width: 823px){
  .space-item {
    width: 100% !important;
    margin: 8px 0px;
}
}


@media screen and (max-width: 414px), (orientation: portrait) {
  .workspacelist {
    min-height: 100vh !important;
    height: 100%;
    overflow: auto;
  }
}

@media screen and (max-width: 375px) {
  .stats ul li {
    height: 80px;
    width: 80px;
  }

  .rename-space-modal-width {
    width: auto !important;
  }

  .edit-max-occupancy{
    width: auto !important;
  }

}

@media screen and (max-width: 380px) {
  .stats ul li {
    height: 60px;
    width: 60px;
  }
  .rename-space-modal-width {
    width: auto !important;
  }

  .edit-max-occupancy{
    width: auto !important;
  }

  .capture-container button {
    margin-left: 5px;
    font-size: 12px;
    padding: 0.25rem 5px !important;
  }
  .capture-container label {
    font-size: 12px !important;
  }
  .capture-container .change-camera-btn {
    padding: 0px !important;
  }

  .subtitle-header.subspace-list{
    display: inherit !important;
    padding:30px 0px !important;
  }

  .subtitle-header.subspace-list> span{
    padding-left: 4rem !important;
  }
}
/* mobiles */

/*Rename space styles*/

.safely-modal .modal-content {
  border-radius: 10px;
  padding: 15px;
}

.safely-modal .modal-content .modal-header,
.qrCodePopUP .modal-content .modal-header {
  border-bottom: none;
}

.safely-modal .modal-content .modal-header > button {
  position: absolute;
  top: 14.11px;
  right: 14.28px;
}

.safely-modal .modal-content .modal-header .modal-title {
  font-size: 22px;
  font-weight: 700;
}
.qrCodePopUP .modal-content .modal-header .modal-title {
  font-size: 22px;
  text-align: center;
}

.safely-modal .modal-content .modal-footer,
.qrCodePopUP .modal-content .modal-footer {
  border-top: none;
}

.header-subtitle {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 30px;
  margin-top: -10px;
  padding-bottom: 10px;
}
.subtitle-back-button .fa-arrow-left {
  color: #665dff;
  padding-right: 8px;
  font-size: 16px;
}
.subtitle-back-button {
  cursor: pointer;
  padding-bottom: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.back-button-text,
.subtitle-header span {
  color: #606076;
}
.subtitle-header h4,
.subtitle-header span {
  display: inline-block;
  padding-right: 10px;
}
.subtitle-header h4 {
  text-transform: capitalize;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.subtitle-header {
  padding: 25px 0;
}

.space-header-addmember .subtitle-header{
  padding: 35px 0px;
}

.subtitle-header.subspace-list{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.renderModalCreatSubSpace .modal-footer button {
  min-width: 100px;
}

.header-cta-wrapper button:focus {
  outline: none !important;
}
.space-sub-item {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  margin: 10px 0px;
  padding: 0px !important;
  display: -webkit-box;
  cursor: pointer;
}
.user-icon-div {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-right: 1px solid #c6c6c6;
  width: 54px;
  height: 70px;
  text-align: center;
  vertical-align: bottom;
  border-radius: 5px 0px 0px 5px;
}
.user-icon-div svg {
  margin-top: 25px;
}
.user-subspace {
  height: 70px;
  padding: 10px;
  padding-right: 0px;
  width: calc(100% - 80px);
}
.sub-space-wrapper {
  padding: 0 25px;
}
.user-subspace label {
  cursor: pointer;
  text-align: left;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2b2b42;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding: 0px;
}
.user-subspace p {
  text-align: left;
  letter-spacing: 0px;
  color: #617992;
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.space-sub-item.active {
  border-color: #1849e8;
}
.subspace-member-image img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.subspace-member-block {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  display: -webkit-box;
  height: 70px;
  padding: 10px;
  margin: 10px 0px;
}
.subspace-member-name {
  margin-left: 15px;
  font-size: 16px;
  color: #585858;
  font-weight: 500;
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeMember {
  position: absolute;
  right: 24px;
  top: 10px;
  color: #5a5a5a;
  cursor: pointer;
}
.subspace-member-role {
  color: #808080 !important;
  font-size: 13px;
}
.removeText {
  font-size: 17px;
  color: #6e6e7c;
  font-family: "Roboto", sans-serif;
}
.removeText>span {
  font-weight: bold;
}

/* Ellipsis text */
.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone .header-cta-wrapper-subspace,
.tablet .header-cta-wrapper-subspace,
.phone .header-cta-wrapper-addmember,
.tablet .header-cta-wrapper-addmember {
  height: auto;
}
.space-header-addmember {
  padding-bottom: 10px;
}
.phone .subspace-header,
.phone .space-header-addmember,
.phone .space-header {
  padding-bottom: 9px;
}
.tablet .subspace-member-name,
.phone .subspace-member-name {
  padding-right: 13px;
}

.qrCodePopUP
  > div
  > .modal-content
  .modal-footer
  .border-radius-none.btn-light {
  background-color: #fff;
  border-color: #6c64fb;
  color: #6c64fb;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.qrCodePopUP
  > div
  > .modal-content
  .modal-footer
  .border-radius-none.btn-light:hover {
  background-color: #fff;
  color: #6c64fb;
}

.qrCodePopUP > div > .modal-content .modal-footer > button {
  background-color: #6c64fb;
  border-color: #6c64fb;
  color: #fff;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.qrCodePopUP > div > .modal-content .modal-footer > button:hover {
  background-color: #6c64fb;
  border-color: #6c64fb;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.qrCodePopUP .Box-containerQR {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
}
.qrCodePopUP .modal-content .modal-footer {
  padding-top: 30px;
}

.safty-pass-text-title {
  margin-top: 5px;
  color: #2b2b42;
  font-size: 18px;
  font-weight: 600;
  margin-left: 40px;
  padding: 10px 5px;
}
.safty-pass-block {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c6c6c6;
  border-radius: 14px;
  opacity: 1;
  padding: 10px 20px;
  margin: 25px 35px 5px 35px;
  display: flex;
  justify-content: space-between;
  max-width: 750px !important;
}
@media screen and (max-width: 768px) {
  .safty-pass-block {
    flex-direction: column;
  }
  .safty-pass-block > button {
    margin-top: 10px;
    align-self: flex-end;
  }
}
.safty-pass-text {
  color: #585858;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
}
.safty-pass-button {
  height: auto;
  border: 1px solid #665dff !important;
  background: #665dff 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  padding: 5px 0px;
  padding-right: 20px !important;
  font-weight: 500;
}
.safty-pass-button:hover,
.safty-pass-button:focus {
  border: 1px solid #665dff;
  background: #665dff 0% 0% no-repeat padding-box;
}
.safty-pass-button svg {
  width: 30px;
  height: 20px;
}
.space-info-continer {
  background: #f9fbfe 0% 0% no-repeat padding-box;
  border: 1px solid #eceded;
  border-radius: 14px;
  margin: 28px 10px 0;
}
.space-info-text {
  color: #2b2b42;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  background: #fff;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.arrowIcondown::after,
.arrowIconup::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  color: #a7a6a6;
  content: "";
  display: inline-block;
  height: 0.6em;
  width: 0.6em;
  left: 0;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.arrowIcondown::after {
  transform: rotate(135deg);
  top: 5px;
}
.arrowIconup::after {
  transform: rotate(315deg);
  top: 10px;
}
.arrowIcondown,
.arrowIconup {
  float: right;
  padding-right: 20px;
}
.space-image-block {
  width: 100%;
  height: 180px;
  background: #C3DF9A 0% 0% no-repeat padding-box;
}
.space-info-open {
  border-radius: 14px;
}
.space-qr-code-block {
  clear: both;
  width: 100%;
  height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eceded;
}
.space-name-edit-block {
  width: 100%;
  min-height: 60px;
}
.space-view-block {
  width: 100%;
  min-height: 70px;
  text-align: center;
  padding-top: 20px;
}
.space-preferences-block {
  min-height: 242px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eceded;
  margin-bottom: 10px;
}
.space-name-edit-name {
  color: #324856;
  padding: 10px;
  padding-bottom: 0px;
  font-weight: bold;
  font-size: 16px;
}
.space-name-edit-ownerdeatils {
  color: #324856;
  padding: 0 10px;
  font-weight: 500;
  font-size: 12px;
}
.dispalyLine {
  display: inline-block;
}
.space-name-edit {
  float: right;
  padding-right: 20px;
  margin-top: 15px;
  padding-bottom: 5px;
}
.space-name-edit-cricle {
  background: #665dff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  margin: 0 2px;
}
.space-name-edit-name-input {
  outline: none !important;
  margin-bottom: 5px;
}

.space-name-edit-name .add-new-post-col {
  padding: 0px !important;
}
.space-name-edit-name .spinner-loader {
  margin-top: 0px !important;
}
.view-space-button,
.view-space-button:hover {
  border: 1px solid #665dff !important;
  border-radius: 4px !important;
  background: #f9fbfe !important;
  color: #665dff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.space-image-edit {
  z-index: 1;
  position: absolute;
  margin-top: -50px;
  right: 43px;
}
.space-image-edit svg {
  width: 15px;
  height: 24px;
}
.space-image-block img {
  width: 100%;
  height: 100%;
}
.space-qr-code-container {
  text-align: center;
  width: 100%;
}
.space-qr-code-container .Box-containerQR {
  display: inline-block;
  padding: 15px;
}
.space-qr-code-name {
  color: #324856;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}
.space-qr-code-block-header {
  padding: 10px;
}
.space-qr-code-download {
  float: right;
  margin-right: 12px;
  cursor: pointer;
  font-size: 18px;
}
.workspace-preferencess-icon-block {
  text-align: right;
  padding-right: 40px !important;
}
.space-preferences-block .react-switch-label {
  display: inline-block;
}
.space-preferences-block .workspace-preferencess-text-block,
.space-preferences-block .workspace-preferencess-icon-block {
  padding-top: 5px;
  padding-bottom: 5px;
}
.space-colorPicker {
  position: absolute;
  right: 0;
  margin-right: 40px;
}
.workspace-preferencess-text-block {
  padding-left: 30px !important;
  color: #585858;
}
.workspace-preferencess-text-block svg {
  padding: 1px;
  margin: 0 5px;
}
.workspace-preferencess-title {
  color: #324856;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}
.workspace-preferencess-save-button {
  text-align: center;
  margin-bottom: 5px;
}
.workspace-preferencess-save-button .nextButton {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 12px;
  height: 36px;
}
.sentinvetationspace {
  width: 15px;
  height: 20px;
}
.workspace-form-eidt {
  color: #655dfd;
  padding-left: 8px;
  cursor: pointer;
}
.workspace-preferencess-save-button .nextButton:focus {
  background-color: #665dff !important;
  border-color: #665dff !important;
  outline: none !important;
  box-shadow: none !important;
}
.space-navegation-tap {
  text-align: right;
  padding-right: 35px;
  cursor: pointer;
  float: right;
}
.phone .safty-pass-text-title,
.tablet .safty-pass-text-title,
.tablet .safty-pass-block,
.phone .safty-pass-block {
  margin-left: 0px;
}
.spaceinfoback {
  padding-right: 5px;
}
.searchSpace .user-search {
  max-width: 300px;
}
.phone .searchSpace .user-search {
  max-width: 100%;
}
.noSpaceImage-spaceInfo {
  text-align: center;
  color: #ffffff;
  padding-top: 30px;
  font-size: 78px;
  height: 180px;
  text-transform: uppercase;
}

.tablet .safty-pass-block,
.phone .safty-pass-block {
  margin-right: 0px;
}
.noSpaceImage-worksapce{
  color: #fff;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  background: #C3DF9A 0% 0% no-repeat padding-box;
}
.noSpaceImage-worksapce div{
  text-align: center;
  margin-top: 10px;
  width: 40px;
  height: 40px;
}
.space-colorPicker .color-cricle div{
  border-radius: 50% !important;
}
.space-colorPicker .color-cricle{
  box-shadow: none !important;
}
.continer-user-subspace{
  padding-top: 23px;
}
.rounded-circle-small {
  border-radius: 50% !important;
  position: absolute;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  height: 40px !important;
  width: 40px !important;
  text-transform: uppercase;
}

.deleteSubSpaceText{
  color:#6E6E7C;
  font: Regular 17px/26px "Roboto", Serif;
}

.delete-image-icon {
  font-size: 23px !important;
  vertical-align: middle !important;
}
.space-name-delete-circle {
  background: #E67070 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 2px;
}
.space-delete-block{
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #ECEDED;
opacity: 1;
padding: 10px;
}
.end-block{
  min-height: 10px;
}
.space-delete{
  color: #E04746;
  font-size: 13px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.space-delete svg{
  padding: 2px;
  margin: 0 0 0 10px;
  vertical-align: bottom;
}
.deleteSpaceEmail{
  color: #4FA3FF;
}

.create-subspace-title{
  color: #617992 !important;
  font-family: "Roboto", Serif;
  font-size: 14px !important;
}

.btn-checkin-subspace.disabled-checkin:disabled{
    color: #BABABA;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border: none;
}

.span-check-into.max-occupancy{
  color: #B9B9B9;
}

.checkout-subspace.selected-subspace{
  color: #6E6E7C;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}

.checkout-subspace.question-text{
  color: #6E6E7C;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}
.tablet .space-item{
  width: 100%;
}
.phone .space-item{
  width: 100%;
}


.spaceleave-disable{
  color: #666666;
  cursor: not-allowed;
}
.spaceleavesvg-disable path{
  fill: #666666;
}


.safely-views {
  padding: 20px 40px !important;
}

.render-space {
  position: relative;
  display: flex;
  align-items: center;
  /* min-height: 70px; */
}

.render-space .space-name {
  font-size: 15px;
  font-weight: 600;
}
.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
    margin-bottom: 0px;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
.input-box {
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 8px 0 0 0;
  border: 1px solid #9baac7;
  border-radius: 4px;
  height: 44px;
  padding-left: 35px;
}
.input-box:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.input-box:focus + .search-result {
  display: block;
}
.search-result:hover {
  display: block;
}
.search-result {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 0;
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  display: none;
  max-height: 9rem;
  overflow: scroll;
}
/* checkbox css starts here */
/* The container */
.container-dropdown {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #6e6e7c;
  padding: 5px 8px;
  margin-bottom: 0px;
}
.container-dropdown:hover {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
}
/* Hide the browser's default checkbox */
.container-dropdown input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: none;
}
.chip button:focus {
  outline: none !important;
}
/* On mouse-over, add a grey background color */
.container-dropdown:hover input ~ .checkmark {
  background-color: #ccc;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

/* When the checkbox is checked, add a blue background */
.container-dropdown input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-dropdown input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-dropdown .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.multiSelect {
  text-align: left;
  display: flex;
  flex-direction: column;
}
/* chip css */
.chip {
  flex-grow: 1;
}
.chip-body {
  min-width: 101px;
  width: auto;
  background: #f2f5ff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 15px !important;
  color: #5a5a5a;
  padding: 1px 10px;
  display: inline-block;
  margin-left: 3px;
  margin-bottom: 3px;
  margin-top: 10px;
  font: Regular 13px/18px "Roboto" , Serif;
}
.chip-text {
  margin: 0;
  display: inline;
}
.chip-close {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  float: right;
  margin-left: 3px;
}
.multiSelect .fa-search {
  position: absolute;
  margin-top: 23px;
  margin-left: 15px;
  color: #3c3c4399;
}
.search-fa-icon {
  color: #3C3C4399 !important;
}

/*  Show Mode - Button  */

.inputButton-container-show {
    display: flex;
    flex-direction: column;
}

.inputButton-container-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
}

.inputButton-button {
    border: 1px solid #333;
    border-radius: 4px;
    width: 167px;
    padding-left: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 20px 1em 0 0;
}

.inputButton-button.active {
    border: 1px solid #665DFF;
}

.inputButton-container-buttons .active {
    color: #665DFF;
}

.inputButton-button label {
    font-weight: 600;
    cursor: pointer;
}

/* @supports (display: grid) {
    .inputButton-container-buttons {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(167px, 1fr));
        grid-gap: 1em;
    }
    div>.form-check {
        padding: 0;
    }
} */

/*  Edit Mode - Button  */

.inputButton-container {
    padding-top: 11px;
}

.inputButton-options {
    display: flex;
    align-items: baseline;
}

.text-container {
    width: 50%;
}

.text-container .input-container {
    margin-top: 0;
}

.text-container .input-container .edition-input {
    margin-bottom: 0;
}

.text-container .required {
    margin-top: 0;
}

.text-container>span {
    color: red;
    font-size: 9px;
}

.option-container {
    display: flex;
    width: 50%;
}

.cancelIcon {
    width: 17px;
    margin: 5px 0 0 10px;
}

.inputButton-addButton {
    text-align: right;
    padding-top: 10px;
}

.inputButton-addButton>span {
    color: #4FA3FF;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.cancelIcon>svg {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .inputButton-options {
        display: flex;
        align-items: baseline;
        flex-direction: column;
    }
    .text-container {
        width: 100%;
    }
    .text-container>.selected-input {
        width: 100%;
    }
    .option-container {
        width: 100%;
    }
    .option-container>select {
        margin: 20px 0;
    }
}

/*  Dropdown */

.dropdown-list {
    max-width: 500px;
}

.dropdown-list>label:first-child {
    color: #454F63;
    font: normal normal normal 18px/40px Roboto;
}

.dropdown-list .input-select__control {
    border-radius: 4px;
}
html,
body {
  height: 100%;
  background-color: #F1F5FD !important;
}

#root {
  height: 100%;
}

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.97b4f523.eot);
  src: url(/static/media/icomoon.97b4f523.eot#iefix)
      format("embedded-opentype"),
    url(/static/media/icomoon.d0ecb9a4.ttf) format("truetype"),
    url(/static/media/icomoon.310594fb.woff) format("woff"),
    url(/static/media/icomoon.a7def6d0.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", Serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clear:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e90e";
  color: #fff;
}
.icon-andon:before {
  content: "\e90c";
  color: #fff;
}
.icon-configuration:before {
  content: "\e90d";
  color: #fff;
}
.icon-workInstruction:before {
  content: "\e90b";
  color: #fff;
}
.icon-angleDown:before {
  content: "\e900";
  color: #fff;
}

.icon-angleDown-after::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "\e900";
  color: #13988a;
}
.icon-angleLeft:before {
  content: "\e901";
  color: #fff;
}
.icon-angleRight:before {
  content: "\e902";
  color: #fff;
}
.icon-checkLists:before {
  content: "\e903";
  color: #fff;
}
.icon-dashboard:before {
  content: "\e904";
  color: #fff;
}
.icon-deviceManagement:before {
  content: "\e905";
  color: #fff;
}
.icon-notices:before {
  content: "\e906";
  color: #fff;
}
.icon-organizationManager:before {
  content: "\e907";
  color: #fff;
}
.icon-productionLines:before {
  content: "\e908";
  color: #fff;
}
.icon-reports:before {
  content: "\e909";
  color: #fff;
}
.icon-userManagement:before {
  content: "\e90a";
  color: #fff;
}

/*===========Sidebar styles============*/
.app-sidebar__inner {
  padding: 2px 0rem 1rem;
  width: 100%;
}

.metismenu {
  font-size: 13.5px;
  position: relative;
  padding-bottom: 70px;
}

/*Checking------------*/
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link::before {
  content: " ";
  margin-right: 52px;
}

.metismenu-container,
.metismenu-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.metismenu-container {
  list-style: none;
}

.metismenu-container .metismenu-container {
  transition: padding 300ms;
  -webkit-transition: padding 300ms;
  background: var(--bg-submenu-sidebar);
}

.metismenu-container .metismenu-container .metismenu-item .metismenu-link {
  height: 0;
  overflow: hidden;
}

.metismenu-container
  .metismenu-container
  .metismenu-item
  .metismenu-link::before {
  content: " ";
  padding-left: 6px;
  margin-right: 43px;
}

.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 2em;
}

.metismenu-container.visible {
  padding: 0.5em 0;
}

.metismenu-container.visible > .metismenu-item > .metismenu-link {
  height: 40px;
}

/*For first menu options when :hover*/
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link:hover {
  background-color: var(--bg-onhover-sidebar);
}

.metismenu > .metismenu-container > .metismenu-item > .metismenu-link:hover,
.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link:hover
  i.metismenu-state-icon::before,
.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link:hover
  i.metismenu-icon::before {
  color: var(--font-color-onhover-sidebar);
}

/*List of each menu elements of each menu*/
.metismenu
  .metismenu-container
  .metismenu-container
  > .metismenu-item
  > .metismenu-link {
  color: var(--font-color-submenu-sidebar);
  text-decoration: none;
}

/*List of each menu elements when hover*/
.metismenu .metismenu-container .metismenu-container .metismenu-item:hover {
  background-color: var(--bg-onhover-sidebar);
}

.metismenu .metismenu-container .metismenu-container .metismenu-item:hover,
.metismenu
  .metismenu-container
  .metismenu-container
  .metismenu-item
  .metismenu-link:hover {
  color: var(--font-color-onhover-sidebar);
}

/*Animate when close a menu list of an element*/
.metismenu-container .metismenu-item .metismenu-link {
  transition: color 300ms, background-color 300ms;
  -webkit-transition: color 300ms, background-color 300ms;
  display: block;
  height: 40px;
  text-decoration: none;
  color: var(--font-color-menu-sidebar);
  line-height: 40px;
}

/*Menu title styles when an item is selected  <andon>->submitem-1*/
.metismenu > .metismenu-container .metismenu-link.has-active-child,
.metismenu > .metismenu-container .metismenu-link.active {
  background-color: var(--bg-active-menu-item-sidebar);
  color: var(--bg-line-selected-item);
}

.metismenu > .metismenu-container .metismenu-link.active,
.metismenu .metismenu-item > .metismenu-link.active > i.metismenu-icon::before {
  color: var(--bg-line-selected-item);
  font-weight: 600;
}

.metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link.active::before {
  content: " ";
  padding-top: 14px;
  padding-bottom: 14px;
}

.metismenu > .metismenu-container .metismenu-link.has-active-child,
.metismenu
  .metismenu-container
  .metismenu-item
  .metismenu-container
  .metismenu-link.active,
.metismenu
  > .metismenu-container
  .metismenu-link.has-active-child
  i.metismenu-icon::before,
.metismenu
  > .metismenu-container
  .metismenu-link.has-active-child
  i.metismenu-state-icon::before {
  font-weight: 500;
  color: var(--bg-line-selected-item);
}

.metismenu
  .metismenu-container
  .metismenu-container
  .metismenu-item
  .metismenu-link.active::before {
  padding-top: 14px;
  content: " ";
  position: relative;
  left: 0;
  /* margin-right: 30px; */
  padding-left: 6px;
  padding-bottom: 14px;
  transition: margin-right 0.4s linear;
}

.metismenu .metismenu-item > .metismenu-link > i {
  line-height: 40px;
}

/*For icons left of Sidebar*/
.metismenu .metismenu-item > .metismenu-link > i.metismenu-icon {
  text-align: center;
  width: 1.5em;
  position: absolute;
  font-size: 14px;
  left: 18px;
}

.metismenu .metismenu-item > .metismenu-link i.metismenu-state-icon {
  transition: transform 300ms;
  -webkit-transition: transform 300ms;
  text-align: center;
  /* width: 3em; */
  position: absolute;
  float: right;
  right: 21.64px;
  font-size: 12px;
}

i.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.app-header {
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 10;
  transition: all 0.2s;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
}

.header-shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
}

.fixed-header {
  position: fixed;
  top: 0;
}

.app-container {
  margin: 0;
  background-color: var(--bg-main-menu);
}

i.metismenu-icon.icon-userManagement {
  font-size: 11px !important;
}

i.metismenu-icon.icon-configuration {
  font-weight: bold !important;
}

/*==Dropdown user==*/
.dropdown-menu {
  min-width: 6rem;
}

.dropdown-user > .dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-user > .dropdown-toggle {
  background-color: #fff !important;
  border-color: #fff !important;
}

.dropdown-user > .dropdown-toggle::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;

  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  color: #a7a6a6;
  content: "";
  display: inline-block;
  height: 0.6em;
  width: 0.6em;
  left: 0;
  position: relative;
  vertical-align: top;
  top: 10px;
  transform: rotate(135deg);
}

/*===========Collapsed sidebar=======*/
.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link {
  text-indent: -99rem;
  display: block;
  justify-content: center;
  align-items: center;
}

.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link
  > i.metismenu-icon {
  text-indent: 0;
  left: 25px;
}

.sidebar-collapsed
  .metismenu
  .metismenu-container
  .metismenu-container.visible {
  padding: 0;
  display: none;
}

.sidebar-collapsed
  .metismenu
  > .metismenu-container
  > .metismenu-item:hover
  > .metismenu-container {
  position: absolute;
  margin-top: -40px;
  z-index: 300;
  left: 82.58px;
  display: block !important;
  transition: display 300ms;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px !important;
}

.sidebar-collapsed .metismenu > .metismenu-container > .metismenu-item:hover {
  background: var(--bg-onhover-sidebar);
}

.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-item:hover
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link {
  height: 40px;
  width: 250.81px;
  position: relative;
}

.sidebar-collapsed i.metismenu-state-icon {
  height: 44px;
  width: 7px;
  right: 12px !important;
  font-size: 12px !important;
  text-indent: 0;
}

.sidebar-collapsed i.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.sidebar-collapsed
  > .metismenu
  > .metismenu-container
  > .metismenu-container
  > .metismenu-item
  > .metismenu-link.active::before {
  content: " ";
  padding-left: 6px;
  padding-top: 15px;
  padding-bottom: 30px;
}

/*=====Media queries=====*/
/* @media only screen and (max-width: 480px) {
  .dropdown-user > .dropdown-menu.show {
    transform: translate(-25px, -5px);
  }
} */

@media (max-width: 700px) {
  .sidebar-collapsed
    > .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-container {
    width: 242.81px;
  }

  .sidebar-collapsed
    .metismenu
    .metismenu-container
    .metismenu-container
    .metismenu-item
    .metismenu-link::before {
    margin-right: 20px;
  }
}

.metismenu > .metismenu-container .metismenu-link.has-active-child:hover,
.metismenu > .metismenu-container .metismenu-link.active:hover {
  background-color: var(--bg-active-menu-item-sidebar);
  color: var(--bg-line-selected-item);
}

@media (hover: none) and (pointer: coarse) {
  .metismenu > .metismenu-container > .metismenu-item > .metismenu-link:hover {
    background-color: var(--bg-active-menu-item-sidebar);
    color: var(--bg-line-selected-item);
  }

  .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-link:hover
    i.metismenu-state-icon:before,
  .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-link:hover
    i.metismenu-icon:before {
    color: var(--bg-line-selected-item);
  }

  .metismenu > .metismenu-container .metismenu-link.has-active-child:hover,
  .metismenu > .metismenu-container .metismenu-link.active {
    background-color: var(--bg-active-menu-item-sidebar);
    color: var(--bg-line-selected-item);
  }

  .metismenu
    > .metismenu-container
    .metismenu-link.has-active-child:hover
    i.metismenu-icon::before,
  .metismenu
    > .metismenu-container
    .metismenu-link.has-active-child:hover
    i.metismenu-state-icon::before {
    font-weight: 600;
    color: var(--bg-line-selected-item);
  }

  .metismenu .metismenu-container .metismenu-container .metismenu-item:hover {
    background-color: #fff;
  }

  .metismenu
    .metismenu-container
    .metismenu-container
    .metismenu-item
    .metismenu-link:hover {
    color: var(--font-color-submenu-sidebar);
  }
}

.side-drawer {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  z-index: 1032;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-267px);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer-desktop {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  z-index: 1032;
  transition: width 0.3s;
}

.side-drawer-desktop.collapsed {
  width: 82.58px;
  transition: width 0.3s;
  overflow-y: unset;
}

.scrollbar {
  width: 260px;
  height: 90%;
  transition: width 0.3s;
  overflow-y: overlay;
  max-height: calc(100% - 225px);
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(84, 84, 84, 0.56);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 98, 98, 0.53);
}

.scrollbar.collpased {
  width: 82.58px;
  height: 90%;
  transition: width 0.3s;
  overflow-y: unset;
}

.sidebar-logo {
  overflow: hidden;
  width: 60px;
  height: 100%;
  margin: 0 auto;
  display: block;
}

/*  Import Contacts   */
.ImportContact > div > .modal-content {
  border-radius: .7rem;
}

.ImportContact > div > .modal-content
.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.ImportContact-title {
  font-weight: bold;
  font-size: 1.5rem !important;
  color: #000 !important;
}

.ImportContact-CSVTitle {
  font-size: 0.9rem !important;
  font-weight: bold;
  cursor: pointer;
}

.ImportContact > div > .modal-content
.modal-body {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.ContactsTable  > div > .modal-content
.modal-footer,
.ImportContact > div > .modal-content
.modal-footer {
  border-top: 0;
  padding: 0 2rem 2rem 2rem;
}

.ContactsTable > div > .modal-content
.modal-footer .btn-outline-primary,
.ImportContact > div > .modal-content
.modal-footer .btn-outline-primary {
  background-color: #fff;
  border-color: #6c64fb;
  color: #6c64fb;
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.ContactsTable > div > .modal-content
.modal-footer .btn-outline-primary:hover,
.ImportContact > div > .modal-content
.modal-footer .btn-outline-primary:hover {
  background-color: #6c64fb;
  color: #fff;
}

.ContactsTable > div > .modal-content
.modal-footer > button,
.ImportContact > div > .modal-content
.modal-footer > button {
  background-color: #6c64fb;
  border-color: #6c64fb;
  color: #fff;
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.ContactsTable > div > .modal-content
.modal-footer > button:hover,
.ImportContact > div > .modal-content
.modal-footer > button:hover {
  background-color: #5750ce;
  border-color: #5750ce;
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.ImportContacts-Buttons {
  padding: .5rem 1.5rem .5rem 1.5rem;
}

.reports-buttons {
  padding: .5rem 1.5rem .5rem 1.5rem;
  border-color: #665DFF;
  color: #665DFF;
}


/* .reports-buttons:disabled:hover, */
.reports-buttons:focus,
.reports-buttons:hover,
.reports-buttons:active {
  background-color: #665DFF !important;
  border-color: #665DFF !important;
  color: #fff !important;
  box-shadow: 0 0 0 0.2rem #665DFF !important;
}

.reports-buttons:disabled{
  cursor: not-allowed;
  border-color: #D3D3D3!important;
  color: #d3d3d3!important;
  background-color: #F2F2F2!important;
}
.reports-buttons:disabled:hover,
.reports-buttons:disabled:focus {
  outline: none;
  border-color: #D3D3D3!important;
  color: #d3d3d3!important;
  box-shadow: none!important;
  background-color: #F2F2F2!important;
}

#image-name {
  padding-left: 1rem;
}

/*  CSV Component */
.CSVDelete {
  font-size: 1.5rem;
  position: absolute;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .CSVDelete {
    font-size: 1rem;
  }
}

.CSVComponent .CSVDelete {
  padding-left: 1rem;
  padding-top: .5rem;
}

.CSVComponent .CSVDelete:hover {
  font-size: 2rem;
  padding-top: .2rem;
}

/*  Component Table */
.contactsTable-title {
  font-size: 1.375rem;
  font-weight: bold;
}
.contactsTable-paginationFooter {
  align-items: baseline;
  text-align: center;
  margin-bottom: 5px;
}
.contactsTable-paginationFooter-Navigation > div > span {
  padding: 0 5px;
}
.contactsTable-paginationFooter-perPage {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 0;
}
.contactsTable-paginationFooter-perPage > div {
  padding: 0 5px;
}

@media screen and (min-width: 768px) and (max-width: 992px){
  .contactsTable-paginationFooter-Showing {
    padding-bottom: 7px;
  }
}
@media screen and (max-width: 768px){
  .contactsTable-paginationFooter {
    align-items: baseline;
  }
  .contactsTable-paginationFooter > div {
    padding: 5px 0;
    font-size: 10px;
  }
  .contactsTable-paginationFooter-perPage {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 0;
  }
  .contactsTable-paginationFooter > div .reports-buttons {
    padding: 2px;
    font-size: 10px;
  }
}
@media screen and (min-width: 576px){
  .contactsTable-paginationFooter > div .reports-buttons {
    padding: 5px;
  }
}
@media screen and (min-width: 768px){
  .contactsTable-paginationFooter > div .reports-buttons {
    padding: 7px;
  }
  .contactsTable-paginationFooter > div {
    font-size: 12px;
  }
}

@media screen and (max-width: 640px) {
  .contactsTable-title {
    font-size: 1.375rem;
    font-weight: bold;
    text-align: center;
    padding: 0 0 1rem 0;
  }
  .contactsTable-search {
    padding: 0 15px;
  }
  .contactsTable-search input {
    font-size: .75rem;
    margin-right: 200px;
  }
  .contactsTable .table th, .contactsTable .table td {
    padding: .2rem;
    font-size: .625rem;
  }
  .contactsTable-tableContainer {
    overflow: auto;
  }
  .reports-buttons {
    padding: .5rem 1rem .5rem 1rem;
    border-color: #665DFF;
    color: #665DFF;
  }
}

@media screen and (min-width: 576px) {
  .contactsTable .table th, .contactsTable .table td {
    font-size: .6875rem;
  }
}

@media screen and (min-width: 768px) {
  .contactsTable-tableContainer {
    padding: 0 15px 0 15px;
  }
  .contactsTable .table th, .contactsTable .table td {
    font-size: .75rem;
  }
  .checkin-table .contactsTable-paginationFooter-perPage .dropdown .dropdown-menu{
    top: -70px!important;
  }
  .users-table .contactsTable-paginationFooter-perPage .dropdown .dropdown-menu{
    top: -70px!important;
  }
}

@media screen and (min-width: 992px) {
  .contactsTable-title {
    padding-left: 15px;
  }
  .contactsTable-search {
    padding-right: 15px;
  }
  .contactsTable-tableContainer {
    padding: 0 15px 0 15px;
  }
  .contactsTable .table th, .contactsTable .table td {
    font-size: 1rem;
  }
}

.contactsTable-tableContainer div {
  width: 100%;
}

.contactsTable-search label {
  position: absolute;
  padding: 5px 0 0 8px;
  margin-left: 0;
}

.contactsTable-search input {
  padding-left: 30px;
  max-width: 280px;
  display: inline-block;
}

.contactsTable-search input::-webkit-input-placeholder {
  color: #c8c8c8;
}

.contactsTable-search input:-ms-input-placeholder {
  color: #c8c8c8;
}

.contactsTable-search input::placeholder {
  color: #c8c8c8;
}

.contactsTable .table>thead:first-child>tr:first-child>th {
  border-top: 0;
}

.contactsTable .table>tbody:last-child>tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.button-Icon {
  display: flex;
  justify-content: center;
}

.ImportedContacts-circle {
  background-color: #01c875 !important;
  border-color: #01c875 !important;
}

.ImportedContacts-span {
  color: #01c875;
  font-size: .8rem;
  font-weight: 500;
  /* padding: .5rem 0 0 3.5rem; */
}

/* Start Sidebar Icons*/
.icon-home::before {
  content: url(/static/media/home.dc36113e.svg);
}
.icon-pass::before {
  content: url(/static/media/pass.0e7b6a14.svg);
}
.icon-location::before {
  content: url(/static/media/location.b27a85c6.svg);
}
.icon-checkpoint::before {
  content: url(/static/media/checkpoint.0f53554f.svg);
}
.icon-market::before {
  content: url(/static/media/Icon_Marketplace.25a4b7ab.svg);
}
.icon-statistics::before {
  content: url(/static/media/statistics.a3f1ee2f.svg);
}
.icon-users::before {
  content: url(/static/media/users.351ab6ba.svg);
}
.icon-reports::before {
  content: url(/static/media/reports.0e5f979f.svg);
}
.icon-cheeckout_new::before {
  content: url(/static/media/Icon_CheckPoint.49698100.svg);
}
.icon-edit:before {
  content: url(/static/media/editIcon.e1d9a9f8.svg);
}
.icon-post:before {
  content: url(/static/media/icon_post.c7884dd9.svg);
}
.active .icon-home::before {
  content: url(/static/media/icon_home_active.ff174eb5.svg);
}
.active .icon-post::before {
  content: url(/static/media/icon_post_active.9adf656a.svg);
}
.active .icon-pass::before {
  content: url(/static/media/icon_safelypass_active.8f7a300d.svg);
}
.active .icon-location::before {
  content: url(/static/media/icon_location_active.1fd49b08.svg);
}
.active .icon-checkpoint::before {
  content: url(/static/media/icon_checkpoint_active.c03db572.svg);
}
.active .icon-cheeckout_new::before {
  content: url(/static/media/Icon_CheckPoint_Blue.4ec97846.svg);
}
.active .icon-statistics::before {
  content: url(/static/media/icon_analytics_active.77204330.svg);
}
.active .icon-users::before {
  content: url(/static/media/icon_user_active.84a18222.svg);
}
.active .icon-reports::before {
  content: url(/static/media/icon_reports_active.4e42e79a.svg);
}
.active .icon-edit:before {
  content: url(/static/media/editIcon.e1d9a9f8.svg);
}
.active .icon-market::before {
  content: url(/static/media/Icon_Marketplace_Blue.3ef28e66.svg);
}
/* End Sidebar Icons*/

.spaces-dropdown-item {
  font-family: "Roboto", Serif;
  font-size: 14px;
  color: #5a5a5a;
}
.powered-by{
  position: absolute;
  bottom: 30px;
  color: #5A5A5A;
}

 .powered-by-text{
  padding-left: 20px;
  font-size: 10px;
  padding-right: 5px;
  vertical-align: sub;
  font-weight: 500;
}

 .powered-by-logo svg{
  height: 40px;
  width: 70px;
}

.powered-by-collapseSidebar .powered-by-text{
  padding-left: 10px !important;
}

.powered-by-collapseSidebar .powered-by-logo{
  padding-left: 5px !important;
}
@media screen and (max-width: 768px) {
  .powered-by{
    bottom: 10px;
  }
}

.has-active-child .icon-reports::before {
  content: url(/static/media/icon_reports_active.4e42e79a.svg);
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 100%;
}

.sws-container {
  height: 100%;
}

/* Split the screen in half */
.split {
  width: 100%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the top side */
.white-top-split {
  height: 100%;
  top: 0;
  /* background-color: #fff; */
}

/* Control the bottom side */
.green-bottom-split {
  height: 40%;
  bottom: 0;
  background-color: #13988a;
}

.login {
  width: 730px;
  min-height: 500px;
  overflow: hidden;
  display: inline-flex;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #00000033;
  border-radius: 10px;
  opacity: 1;
}

.login-header {
  height: 130px;
}

.wrapper {
  min-height: calc(100% - 27.8px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  background-color: #F1F5FD;
}

.login-white-container {
  background-color: #fff;
  height: 60%;
}

.login-green-container {
  background-color: #13988a;
  height: calc(40% - 32.6px);
  position: relative;
}

.footer {
  position: relative;
  width: 100%;
  background-color: #fafafa !important;
}

.footer-copyright-text {
  font-size: 14px;
}

.login-carousel-image {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 541px;
}

/*=====Styles for imputs===*/
.border-bottom-input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #9BAAC7;
  border-radius: 4px !important;
  opacity: 1;
  font-family: "Roboto", Serif;
  overflow: hidden;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.border-bottom-input-focus:focus {
  border: 1px solid #4FA3FF !important;
}

/*============MEDIA QUERIES FOR LOGIN==============*/
/* Android devices */
@media only screen and (max-width: 480px) {
  html,
  body {
    background: none;
  }
  .login {
    box-shadow: none;
    border-radius: 10px;
    height: 100%;
    position: relative;
    padding: 15px 15px 15px 15px;
  }

  .login-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: 100%;
  }

  .green-bottom-split {
    display: none;
  }

  .white-top-split {
    display: none;
  }

  .wrapper {
    position: relative;
    min-height: calc(100% - 27.8px);
    background: none;
  }

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 300;
    background: none;
  }

  .footer-copyright-text {
    font-size: 12px;
  }

  .logo-andonix {
    padding-top: 10px;
  }

  .login-header {
    padding-bottom: 1rem;
  }

  .carusel-column {
    display: none;
  }

  .login-main-container {
    min-height: auto;
    height: auto !important;
  }

  .button-circle {
    width: 40px;
    height: 40px;
  }
}

.login-container {
  width: 390px;
}

@media only screen and (min-width: 480px) {
  .login-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .green-bottom-split {
    display: none;
  }

  .white-top-split {
    display: none;
  }

  .footer-copyright-text {
    font-size: 12px;
  }

  .logo-andonix {
    padding-top: 10px;
  }

  .login-header {
    padding-bottom: 2rem;
  }

  .carusel-column {
    display: none;
  }

  .login-main-container {
    min-height: auto;
    height: auto !important;
  }
}

@media only screen and (min-width: 480px) and (orientation: landscape) {
  .login-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .footer {
    position: relative;
  }
}

@media only screen and (min-width: 768px) {
  .login {
    box-sizing: border-box;
    box-shadow: 0px 4px 33px rgba(79, 41, 132, 0.13),
      0px 4px 20px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    /* height: 550px; */
  }

  .green-bottom-split {
    display: block;
  }

  /* .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  } */

  /* .footer-copyright-text {
    font-size: 14px;
  } */

  .carusel-column {
    display: block;
  }
}

/*Login social media*/
.seperator {
  float: left;
  width: 100%;
  border-top: 1px solid #ccc;
  text-align: center;
  margin: 20px 0 0;
}
.seperator b {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  position: relative;
  top: -22px;
  z-index: 1;
}

.social-icon-div {
  padding-top: 25px;
}
/* .social-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-icon .fa {
  font-size: 28px;
  width: 40px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.social-icon .fa:hover {
  opacity: 0.7;
}

.social-icon .fa-facebook {
  background: #3b5998;
  color: white;
}

.social-icon .fa-twitter {
  background: #55acee;
  color: white;
}

.social-icon .fa-google {
  background: #dd4b39;
  color: white;
}

.social-icon .fa-windows {
  background: #81c2e0;
  color: white;
} */

.safely-sigin-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-login {
  width: 280px;
  min-height: 220px;
  max-width: 100%;
  height: 100%;
}

.klnsGD {
  max-width: 100%;
}

.signin-select {
  padding-top: 10px;
  padding-right: 25px;
}

.btn-submit {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", Serif !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.btn-submit:focus {
  outline: none !important;
  box-shadow: none !important;
}

.safely-logo {
  padding-top: 20px;
  padding-left: 20px;
}

.forgot-password {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #4FA3FF;
  opacity: 1;
}

.login-right-style {
  background-color: #726BEF;
}

.login-right-style .signin-link {
  background-image: url(/static/media/signupImage.d5012c9b.svg);
  background-position: right;
  height: 100%;
  padding-top: 48%;
  max-height: 393px;
  margin-top: 40px;
}
.workspace-name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 54px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.workspace-name-container p {
  color: #3E3E3E;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  margin-bottom: unset;
  margin-top: unset;
}

.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.bs-callout div {
  text-align: justify;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.table thead th:focus {
  outline: none;
}
.table thead th {
  border-top: none;
}
a:focus {
  outline: none;
}
.no-wrap {
  white-space: nowrap;
}

.space-checkbox {
  margin-right: 10px;
  margin-top: 5px;
  color: white;
  float: right;
}

.space-checkbox input:checked ~ .cr{
  background-color: #665DFF;
}

.transaction-checkbox {
  display: inline-block;
}

.transaction-checkbox input:checked ~ .cr {
  color: white;
  background-color: #665DFF;
}

.checkbox label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}

.page-item.active .page-link {
  z-index: 0;
}

/* Global Styles */
body {
  font-family: "Montserrat", sans-serif;
}
.main-container {
  background: #f1f5fd;
}
.css-2613qy-menu {
  /* display: inline!important; */
  visibility: visible!important;
  visibility: initial!important;
}
.sub-container {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  min-height: 85vh;
  padding: 10px 0;
  margin: 0 20px;
}

.workspacelist.sub-container {
  padding: 0px !important;
}

.phone.sub-container,
.tablet.sub-container {
  box-shadow: none !important;
}
.primary-cta {
  font-size: 14px;
  font-weight: 600;
  background-color: #665dff;
  color: #ffffff;
  outline: none;
  border: 1px solid #665dff;
  border-radius: 4px;
  text-transform: capitalize;
  height: 40px;
}
.header-nav {
  background: #f1f5fd;
}
/* Global Styles */

.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap,
.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor,
.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor
  .tui-image-editor-canvas-container,
.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor
  .tui-image-editor-canvas-container
  canvas {
  height: auto !important;
  margin: 0 auto;
}

.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor {
  width: 100% !important;
  top: 0px !important;
}

.andonix-tui-image-editor-container
  .tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-menu-rotate
  .tui-image-editor-submenu
  li.tui-image-editor-newline.tui-image-editor-range-wrap {
  display: none;
}
.tu.tui-image-editor-main-container {
  top: -79px;
}
.tui-image-editor-container .tui-image-editor-main-container {
  top: -78px !important;
}
/* image editor style */

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
[type="file"] + label {
  background: #665dff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  font-size: inherit;
  margin-bottom: 0;
  outline: none;
  padding: 0.5rem 10px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
[type="file"] + label:hover {
  background-color: #665dff;
}
[type="file"] + label.btn-1 {
  background-color: #665dff;
  box-shadow: 0 6px #665dff;
  transition: none;
}
[type="file"] + label.btn-1:hover {
  box-shadow: 0 4px #665dff;
  top: 2px;
}
.btn-info {
  background: #665dff;
}
.btn-info:hover {
  background-color: #665dff !important;
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}
.loader3 span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: rgba(135, 211, 124, 1);
  animation: loader3 1.5s linear infinite;
}
.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}
@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
html,
body {
  overflow-y: scroll;
}

.qrcodeScanner .modal-title {
  color: #2b2b42;
  font-weight: bold;
}
.qrScannerErrorPopup .modal-body{
  padding-left: 20px;
}
.subspace-name-error{
  color: #6E6E7C;
  font-weight: bold;
}
.photoOrVedioPopup .modal-header{
  padding: 0px;
  padding-right: 20px;
  padding-top: 5px;
}
.photoOrVedioPopup .modal-body{
  padding-top: 0px
}
.qrcodeScanner .modal-header,.qrScannerErrorPopup .modal-header,.takePhotoOrVideoPopup  .modal-header,.editimagepopup .modal-header,.addPopup .modal-header,.photoOrVedioPopup .modal-header{
  border-bottom: none;
}
.qrcodeScanner .modal-footer,.qrScannerErrorPopup .modal-footer,.addPopup .modal-footer{
  border-top: none;
}
.qrcodeScanner .modal-content {
  padding: 15px;
  padding-top: 5px;
}
.qrcodeScanner .cancel-button-qrcode, .qrScannerErrorPopup .cancel-button-qrcode{
  color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #fff;
  width: 120px;
}
.containerScan {
  position: absolute;
  top: 15%;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
}

.containerScan::before {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;
  left: 0px;
  top: 0px;
  border-left-width: 5px;
  border-top-width: 5px;
}

.containerScan::after {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;
  right: 0px;
  top: 0px;
  border-right-width: 5px;
  border-top-width: 5px;
}

.containerScan em::before {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;

  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
}

.containerScan em::after {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;

  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
}

.scan {
  width: 95%;
  position: absolute;
  animation: scan 2s infinite;
  -webkit-animation-direction: alternate-reverse;
  background: linear-gradient(0deg, #1754ff, transparent);
  height: 40px;
}

@keyframes scan {
  0%,
  100% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(155px);
  }
}

.DescriptionScan {
  position: absolute;
  text-align: center;
  color: #fff;
  bottom: 5%;
  font-weight: 600;
  padding: 0 4px 0 4px;
}

.flipCamera {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 5%;
  padding-bottom: 5%; */
  padding: 5% 15px 5% 15px;
}
.flipCamera span {
  font-size: 10px;
  text-align: center;
}

.modalScannerFooter {
  border-top: 0;
  padding: 1.5rem 2rem 2rem 2rem;
}
@media only screen and (max-width: 399px) {
  .modalScannerFooter {
    display: flex;
    justify-content: center;
    padding: 0.7rem 2rem 2rem 2rem;
  }
}

@media only screen and (min-width: 400px) {
  .flipCamera {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 135px;
  }
}

@media only screen and (max-width: 310px) {
  .containerScan {
    width: 100px;
    height: 100px;
  }
  .scan {
    height: 20px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(75px);
    }
  }
  .DescriptionScan {
    font-size: 10px;
    bottom: 2%;
  }
}

@media only screen and (min-width: 310px) {
  .containerScan {
    width: 110px;
    height: 110px;
  }
  .scan {
    height: 20px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(65px);
    }
  }
  .DescriptionScan {
    font-size: 10px;
    bottom: 2%;
  }
}

@media only screen and (min-width: 320px) {
  .containerScan {
    width: 100px;
    height: 100px;
  }
  .scan {
    height: 20px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(75px);
    }
  }
  .DescriptionScan {
    font-size: 12px;
    bottom: 1.5%;
  }
}

@media only screen and (min-width: 350px) {
  .containerScan {
    width: 130px;
    height: 130px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(105px);
    }
  }
}

@media only screen and (min-width: 380px) {
  .containerScan {
    width: 150px;
    height: 150px;
  }
  .scan {
    height: 30px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(115px);
    }
  }
}

@media only screen and (min-width: 400px) {
  .DescriptionScan {
    font-size: 14px;
  }
}

@media only screen and (min-width: 420px) {
  .containerScan {
    width: 170px;
    height: 170px;
  }
  .scan {
    height: 40px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(125px);
    }
  }
}

@media only screen and (min-width: 440px) {
  .DescriptionScan {
    bottom: 8%;
  }
}

@media only screen and (min-width: 480px) {
  .containerScan {
    width: 200px;
    height: 200px;
  }
  .scan {
    height: 30px;
  }
  @keyframes scan {
    0%,
    100% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(165px);
    }
  }
}

.tool-Tip {
  border-radius: 20px !important;
  padding: 1px 10px !important;
  font-size: 9px !important;
  font-weight: 600 !important;
}

.css-50etie {
  width: 50px!important;
}
/* Multi Select CSS */

.user-stats {
  display: none !important;
}

/*Styles of Verification Point  */
/* .TimeLineVerificationPoint .list-group-item {
  border: 0;
  text-align: center;
}
.TimeLineVerificationPoint>.list-group>.list-group-item>div>.inputButton-container-buttons {
  justify-content: center;
} */

/* Verification point */
.logo-container {
  background-color: #e9e8ff;
  height: 240px;
  width: 240px;
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.outer-circle {
  background-color: #c3c0ff;
  height: 190px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.inner-circle {
  background-color: #665eff;
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.cta-container button {
  margin: 20px 0;
}
.btn-primary-filled {
  background-color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 12px 20px;
  min-width: 335px;
  max-width: 335px;
}
.btn-primary-filled:hover {
  background-color: #665dff;
  border: 1px solid #665dff;
}

.btn-primary-outline {
  background-color: transparent;
  border: 1px solid #665dff;
  color: #665dff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 12px 20px;
  min-width: 335px;
  max-width: 335px;
}
.btn-primary-outline:hover {
  background-color: transparent;
  border: 1px solid #665dff;
  color: #665dff;
}

.btn-checkin-outline {
  background: transparent;
  color: #665dff;
  border: 1px solid #665dff;
  min-width: 120px;
  padding: 5px 15px;
}
.btn-checkin-outline:hover {
  background-color: transparent;
  color: #665dff;
}
.btn-checkin-outline:disabled {
  background-color: #DDDDDD;
  border: 1px solid #DDDDDD;
  color: #FFFFFF;
  cursor: not-allowed;
}

.btn-checkout-outline {
  background: transparent;
  color: #e67070;
  border: 1px solid #e67070 !important;
  min-width: 120px;
  padding: 5px 15px;
}
.btn-checkout-outline:hover,
.btn-checkout-outline:focus {
  background-color: transparent !important;
  color: #e67070;
  border: 1px solid #e67070 !important;
}
.checkin-select label {
  display: none;
}
.checkin-table td,
th {
  vertical-align: middle;
}
.checkin-table th {
  border-top: none;
}
.text-danger {
  color: #e67070 !important;
}
.text-success {
  color: #39b97e !important;
}
.table-row-count {
  font-size: 13px;
  color: #999999;
}
.next-btn {
  border: 1px solid #665dff;
  color: #665dff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 7px 30px;
}
.next-btn:hover {
  background-color: #665dff;
  border: 1px solid #665dff;
}
.previous-btn {
  padding: 7px 30px;
  border: 1px solid #665dff;
  color: #665dff;
  text-transform: uppercase;
  font-weight: 600;
}
.previous-btn:hover {
  background-color: #665dff;
  border: 1px solid #665dff;
}
.previous-btn:disabled,
.next-btn:disabled {
  background: #f2f2f2;
  color: #d3d3d3;
  font-weight: 600;
  border: 1px solid #d3d3d3;
  text-transform: uppercase;
}
.show-control {
  color: #5a5a5a;
  font-size: 13px;
}
.checkin-modal .modal-content {
  background-color: #fcfcff;
}
.checkin-modal .modal-body {
  padding: 40px;
}

.checkin-modal h3 {
  color: #2b2b42;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
}
.checkin-modal .close {
  margin-top: -30px;
  margin-right: -25px;
}
.checkin-modal .avtar {
  margin-bottom: 20px;
}
.checkin-modal input {
  height: 36px;
  color: #6e6e7c;
}
.checkin-select .select--container > .input-select__control {
  border-radius: 4px;
  color: #5a5a5a;
}

.checkin-select .input-select__placeholder {
  color: #6e6e7c;
}
.multi-select .dropdown-heading-value {
  color: #6e6e7c;
}
.multi-select .dropdown-heading-value span {
  max-width: 200px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1;
}

.checkout-modal .modal-body {
  padding: 40px;
}
.checkout-modal h3 {
  color: #2b2b42;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
}
.checkout-modal .close {
  margin-top: -30px;
  margin-right: -25px;
}
.checkout-modal p {
  font-size: 17px;
  color: #6e6e7c;
}
.checkout-modal .btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.checkout-modal .btn-container .btn-outline-secondary {
  background-color: transparent !important;
  border-color: #665dff;
  color: #665dff;
  padding: 0px;
}

.checkout-modal .btn-container .btn-danger {
  background-color: #e67070 !important;
  border-color: #e67070 !important;
  font-weight: 500;
  margin-left: 10px;
  padding: 0px;
  /* color: #665DFF; */
}
/* Verification point */

/* user manamgement css */
.icon-btn-secondary {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 0 4px;
  border: 1px solid #665dff;
}
.icon-btn-secondary span {
  font-size: 16px;
  color: #665dff;
}
.icon-btn-secondary svg {
  margin-top: -5px;
  margin-left: -5px;
}
.icon-btn {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: #665dff;
  border: 1px solid #665dff;
  margin-left: 5px;
}

.icon-btn svg {
  margin-top: -5px;
  margin-left: -5px;
  fill: #665dff;
  stroke: #665dff;
  border: 1px solid #665dff;
}
.user-search {
  height: 36px !important;
  border: 1px solid #9baac7 !important;
  border-radius: 4px !important;
  color: #6e6e7c !important;
}
.user-workspaces {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.user-workspaces label {
  color: #454f63;
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 0;
  white-space: nowrap;
}
.user-workspaces .select--container {
  width: 100%;
}
.user-workspaces .input-select__control {
  height: 36px;
  border-radius: 4px;
}
.user-workspaces .input-select__indicator,
.user-workspaces .input-select__value-container {
  padding: 0 8px;
}
.user-workspaces.caret-down-gray .input-select__indicator i {
  color: #B4B4B4;
}

.user-workspaces.caret-down-gray .input-select__indicator,
 .user-workspaces.caret-down-gray .input-select__value-container> div{
   color: #B4B4B4;
 }

.user-controls {
  margin-top: 30px;
}
/* .user-workspaces  */
/* Multi Select CSS */

.select-panel .item-renderer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
/* Multi Select CSS */
/* start - Styles of QR  */
.Box-containerQR {
  position: relative;
  padding: 20px 20px 20px 20px;
  border: 1px solid #ebebeb;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
}

.containerQR::before {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;
  left: 25px;
  top: 25px;
  border-left-width: 10px;
  border-top-width: 10px;
}

.containerQR::after {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;
  right: 25px;
  top: 25px;
  border-right-width: 10px;
  border-top-width: 10px;
}

.containerQR em::before {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;

  left: 25px;
  bottom: 25px;
  border-left-width: 10px;
  border-bottom-width: 10px;
}

.containerQR em::after {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;

  right: 25px;
  bottom: 25px;
  border-right-width: 10px;
  border-bottom-width: 10px;
}
/* end - Styles of QR  */

.sentinvetationpopup .modal-title {
  width: 100%;
  text-align: justify;
  word-break: break-all;
}
.qrCodePopUP .modal-title {
  width: 95%;
  text-align: justify;
  word-break: break-all;
}

.renderInvitationModal .workSpaceName-label-renderInvitationModal {
  width: 100%;
  text-align: justify;
  word-break: break-all;
}
.custome-multiselect .dropdown-content .select-panel:first-child {
  position: relative;
}
.custome-multiselect .dropdown-content .select-panel:first-child:before {
  font-family: "FontAwesome", Serif;
  color: #3c3c4399;
  content: "\f002";
  position: absolute;
  margin-left: 10px;
  margin-top: 8px;
}
.custome-multiselect
  .dropdown-content
  .select-panel:first-child
  div:first-child
  input {
  padding-left: 30px !important;
}
.users-search-input {
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
}
/* image cropper css*/

.checkin-input-container {
  width: 300px;
  position: relative;
}

@media screen and (min-width: 600px) {
  .ImageCropper-cropContainer-1 {
    height: 320px !important;
    width: 99% !important;
  }
  .ImageCropper-controls-3 {
    padding: 0 0 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .ImageCropper-cropContainer-1 {
    width: 100%;
    height: 235px !important;
    position: relative;
    background: #333;
  }
}

.image-crop-modal {
  padding: 20px;
}
.image-crop-modal h3 {
  margin-bottom: 20px;
  color: #2b2b42;
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.cropped-image-btn-container button {
  padding: 10px 35px;
  max-width: 100px;
}
.cropped-image-btn-container button:focus {
  outline: none;
}

.cropped-image-btn-container .MuiButton-containedPrimary {
  color: #fff;
  background-color: #665dff !important;
}
/*Loader CSS*/
.loader-container {
  background-color: #ebebeb;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-container .spinner-loader {
  margin-top: 5px;
}
.createQrCode-div,
.inviteMyContacts-div {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .users-table td,
  .checkin-table td,
  th {
    white-space: nowrap;
  }
  .users-table,
  .checkin-table {
    overflow-x: auto;
  }
  .users-search-input {
    margin-left: 10px;
  }
  .react-bootstrap-table {
    overflow: scroll;
  }
}
@media screen and (max-width: 767px) {
  /* .userManagement-table{
    height: 100vh;
  } */
  .checkin-input-container {
    width: 100%;
  }
  .users-table td,
  .checkin-table td,
  th {
    white-space: nowrap;
  }
  .users-search-input {
    margin-left: 10px;
  }
  .user-workspaces {
    align-items: flex-start;
    flex-direction: column;
  }
  .user-workspaces label {
    margin-bottom: 10px;
  }
  .table thead th {
    font-size: 13px;
  }
  .users-table .contactsTable-paginationFooter div,
  .checkin-table .contactsTable-paginationFooter div {
    text-align: center;
    justify-content: center;
    margin: 10px 0;
  }
  .users-table .contactsTable-paginationFooter-Showing,
  .checkin-table .contactsTable-paginationFooter-Showing {
    padding: 0;
  }
  .users-table .contactsTable-paginationFooter-perPage,
  .checkin-table .contactsTable-paginationFooter-perPage {
    justify-content: center;
  }
}
@media screen and (max-width: 360px) {
  .btn-primary-filled,
  .btn-primary-outline {
    font-size: 13px;
    padding: 12px 10px;
    min-width: 250px;
    max-width: 250px;
  }
  .users-search-input {
    margin-left: 7px;
    margin-top: 6px;
  }
}

@media screen and (max-width: 320px) {
  .users-search-input {
    margin-left: 7px;
    margin-top: 6px;
  }
}
.userManagement-table .contactsTable-tableContainer {
  overflow: visible !important;
}
.userManagement-table .contactsTable-tableContainer .dropdown-menu,
.safelyTableContiner .dropdown-menu {
  min-width: 0rem !important;
}
.userManagement-table .contactsTable-tableContainer .dropdown-item,
.safelyTableContiner .dropdown-item {
  padding: 0px !important;
}
.userManagement-table .contactsTable-tableContainer .dropdown-item a,
.safelyTableContiner .dropdown-item {
  padding: 0.25rem 1.5rem !important;
}
.scanQRCodeButton {
  display: flex;
  align-items: center;
  border: 1px solid #665dff;
  border-radius: 4px;
  margin-right: 10px;
  color: #665dff;
  background: #f1f5fd;
  padding: 8px;
  font-size: 12px;
}
.scanQRCodeButton:hover {
  outline: none;
}
.scanQRCodeButton:focus {
  outline: none;
}
.scanQRCodeButton svg {
  margin-right: 5px;
}
.scanQRCodeButton.phone svg {
  margin-right: 0px;
}
.error-cricle{
  background: #E67070 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  padding-top: 4px;
  display: inline-block;
}
.error-message-title-text{
  color: #2B2B42;
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}
.error-message-des-text{
  color: #6E6E7C;
  padding-left: 40px;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.sucess-cricle{
  background: #39B97E 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  padding-top: 4px;
  display: inline-block;
}
.godal-space-dropdown{
  width: 146px;
  margin-right: 10px !important;
  box-shadow:none;
}
.godal-space-dropdown label{
  display: none;
}
.godal-space-dropdown .input-select__control{
  border-color: hsl(0,0%,80%) !important;
  box-shadow:none !important;
}
.godal-space-dropdown .input-select__control:focus,.godal-space-dropdown .input-select__control:hover,.godal-space-dropdown .select--container:focus,.godal-space-dropdown .select--container:hover{
  outline:none !important;
  box-shadow:none !important;
  border-color: hsl(0,0%,80%) !important;
}
.scanQRCodeButton.phone{
  margin-right: 5px;
  padding: 5px;
}
.godal-space-dropdown.phone{
  width: 100px;
  margin: 0px !important;
  margin-right: 5px !important;
}
.main-container-header-tittle.phone{
  max-width: 79px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.phonenave{
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #2B2B42;
  font-weight: bold;
  font-size: 12px;
}
@media screen and (max-width: 860px) {
  nav{
    padding: 5px !important;
  }
  .sidebar-button{
    margin-top: 70px !important;
  }
  .tui-image-editor-container{
    min-height: 417px !important;
    overflow-x:scroll;
  }
}
@media screen and (max-width: 411px) {
  .tui-image-editor-container{
    min-height: 340px !important;
  }
}
@media screen and (max-width: 375px) {
  .tui-image-editor-container{
    min-height: 313px !important;
  }
}
@media screen and (max-width: 320px) {
  .tui-image-editor-container{
    min-height: 271px !important;
  }
  .phonenave{
    width: 80px;
  }
}
.outline-secondary-save{
  background: #665DFF 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 4px;
  border-color: #665DFF;
}
.outline-secondary-save:hover{
  background: #665DFF 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 4px;
  border-color: #665DFF;
}
.outline-secondary-cancel{
  background: #fff 0% 0% no-repeat padding-box;
  color: #665DFF;
  border-radius: 4px;
  border: 1px solid #665DFF;
}
.outline-secondary-cancel:hover{
  background: #fff 0% 0% no-repeat padding-box;
  color: #665DFF;
  border-radius: 4px;
  border: 1px solid #665DFF;
}
.tui-image-editor-submenu-style{
background: #151515 0% 0% no-repeat padding-box !important;
opacity: 0.75 !important;
}
.tui-image-editor-container{
  min-height: 590px;
}
.tui-image-editor-container .color-picker-control{
  width: 250px !important;
}
.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button{
  min-width: 7px;
  min-height: 7px;
}
.header-back-button{
  color: #655DFD;
  cursor: pointer;
}

.subspace-close-icon {
  vertical-align: middle;
  font-size: 20px;
}
.error-message-deletespace-text {
  font-size: 16px;
  padding-left: 40px;
  font-family: "Roboto", Serif !important;
  letter-spacing: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #6E6E7C;
  opacity: 1;
}

.pb-15{
  padding-bottom: 15px;
}
.subspace-style-text {
  width: 90%;
  font-size: 16px;
  font-family: "Roboto", Serif;
}
.subspace-name-bold {
  font-weight: bold;
}
.copperpoupHide{
  display: none !important;
}

.subspace-oval {
  display: inline-flex;
  background-color: #F2F5FF;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #E6E6E6;
  border-radius: 15px;
  opacity: 1;
  margin: 10px 5px 5px 0px;
  padding: 5px;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #5A5A5A;
  opacity: 1;
}

.createTransaction.sub-container,
.editTransaction.sub-container,
.executeTransaction.sub-container {
  overflow-x: auto;
  padding: 0 10px;
}

.transaction-container {
  min-width: 760px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin: 0 auto;
}

.transaction-container.execute {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.transaction-header {
  display: flex;
  font: normal normal 600 15px Montserrat;
  line-height: 15px;
  padding: 10px 4px;
  background-color: #F2F2F2;
}

.transaction-filename {
  width: 215px;
  padding-left: 5px;
}

.transaction-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 auto;
}

.transaction-pagination.execute {
  justify-content: flex-end;
  align-items: center;
}

.transaction-row {
  display: flex;
  border-left: 5px solid #F2F2F2;
  border-right: 5px solid #F2F2F2;
  border-bottom: 5px solid #F2F2F2;
}

.pdf-viewer {
  position: relative;
  float: left;
  margin: 0 5px;
  border: 2px solid #BABABA;
}

.pdf-viewer.execute {
  margin: 0;
}

.input-select,
.create-transaction-menu {
  font: normal normal bold 14px Montserrat;
  padding: 5px;
  min-width: 210px;
  background-color: #FBFBFB;
  border: 2px solid #BABABA;
  float: left;
}

.transaction-button-container {
  margin: 15px 0;
  float: right;
}

.executeTransaction .transaction-button-container .save-btn{
  min-width: 110px;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.input-button {
  margin-top: 20px;
  cursor: pointer;
}

.input-icon {
  margin-right: 10px;
}

.pdf-text,
.pdf-signature {
  position: relative;
  text-align: center;
  line-height: 1;
  background-color: #FFFFC4;
  border: 1px solid black;
  letter-spacing: 0px;
  padding: 2px 0;
}

.pdf-text.input-required,
.pdf-signature.input-required {
  color: red
}

.pdf-text.resizable,
.pdf-signature.resizable {
  border: 2px solid #362fb3;
}

.resizable .resizers .resizer{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #362fb3;
  border: 2px solid #362fb3;
  position: absolute;
}
.resizable .resizers .resizer.top-left {
  left: -4px;
  top: -4px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizable .resizers .resizer.top-right {
  right: -4px;
  top: -4px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-left {
  left: -4px;
  bottom: -4px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-right {
  right: -4px;
  bottom: -4px;
  cursor: nwse-resize;
}

.signature-pad {
  width: 70vw;
  max-width: 470px;
  height: 30vh;
  border: 1px solid black;
  display: block;
  margin: 0 auto;
}

.add-signature .btn.btn-info:disabled {
  background-color: #E0E0E0 !important;
  color: white;
  border: 1px solid #E0E0E0;
}

.add-signature .btn.btn-info {
  background-color: #665DFF;
  color: white;
}

.pdf-input-text {
  padding: 0;
  margin: 0;
}

.transaction-button-container .save-btn.btn-primary:focus {
  background-color: #665DFF;
  color: white;
}

.transaction-button-container .save-btn.btn-primary:disabled {
 background-color: gray; 
 border: none;
}

.transaction-button-container .cancel-btn.btn-primary:focus {
  background-color: white;
  color: #665DFF;
}
.row .react-bootstrap-table-pagination{
    flex-wrap: nowrap;
}
.user-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.workspace-selector .input-select{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}
.workspace-selector .input-select .select--container {
    width: 70%;
}
.actionIcons svg {
    width: 16px;
    height: 16px;
    vertical-align: unset;
    cursor: pointer;
}
.actionIcons i {
    font-size: 19px;
    color: #E04746;
    cursor: pointer;
    margin-left: 10px;
}
.actionIcons .only-disable {
    margin-left: 26px;
}
.userDisabledRow {
    background: #FFF4F4 0% 0% no-repeat padding-box;
    opacity: 1;
}
.userDisabledIcon {
    font-size: 14px;
    color: #E04746;
    line-height: unset;
    vertical-align: middle;
    margin-left: 0.5rem;
}
.nameHyperLink {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.usermgmt-user-pic {
    width: 32px;
    height: 32px;
    margin-top: -4px;
    opacity: 1;
}
.user-edit-controls {
    display: flex;
    justify-content: space-evenly;
}
.user-edit-controls .input-box {
    width: 100%;
    padding-left:10px;
    margin: 8px 0 10px 0;
}
.user-edit-controls .input-select {
    margin: 8px 0 10px 0 !important;
}
.user-edit-controls .multi-select {
    margin: 8px 0 10px 0;
}
.user-pic-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.edit-user-pic {
    width: 165px;
    height: 165px;
    border-radius: 50%;
    margin-top: 20px;
    padding: 20px;
    opacity: 1;
}
.user-edit-form-container {
    margin-top: 40px;
}
.user-edit-form-label{
    margin-top: 10px;
    color: #617992;
    font: normal 14px/20px "Roboto",Serif;
}  
.usermgmt-save-btn {
    width: 120px;
    margin-top: 45px;
    float: right;
}
.input-field-error {
    border: 1px solid #E67070;
}
.user-edit-form-container .input-box:disabled{
    cursor: not-allowed;
    background-color: #EFEFEF !important;
    color: #6E6E7C !important;
}
.usermgmt-save-btn:disabled {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border: 1px solid #D3D3D3;
    cursor: not-allowed;
    color: #D3D3D3;
}

.error-message{
    text-align: center;
    margin-top: 15px;
}
.safelyTableContiner .pagination{
    float: right;
}
.safelyTableContiner .react-bootstrap-table-pagination-total{
    padding-left: 10px;
}
.spinner-loader{
    text-align: center;
    margin-top: 20px;
}
@media (max-width: 756px) {
.safelyTableContiner{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.reportlist.sub-container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
}
@media (max-width: 360px) {
.main-container-header-tittle{
    font-size: 11px !important;
}
}

.reports-header {
    margin: 30px 0px 10px 30px;
    font: 18px/22px "Montserrat", Serif;
    font-weight: 600;
    display: block;
}

.tabs-container {
    padding: 20px 25px !important;
    flex-wrap: nowrap;
}

.tab-reports {
    font-family: "Montserrat", Serif;
    color:  #6e7e88;
    font-size: 13px;
    font-weight: 600;
}

.tab-reports.nav-item.nav-link.active {
    background-color: #F1F5FD;
    color: #665DFF;
}

/* verificatiion report css */
.report-container {
    padding: 40px;
    min-height: 500px;
    max-height: calc(95vh - 71px);
    overflow-y: auto;
}
.report-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report-title h3 {
    color: #2B2B42;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.export-button {
    padding: 7px 20px;
    color: #665DFF;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid #665DFF;
}
.export-button:hover, .export-button:focus, .export-button:active {
    background-color: #665DFF !important;
    color: #fff !important;
    border: 1px solid #665DFF!important;
    box-shadow: none!important;
}
.export-button:disabled:hover, .export-button:disabled:focus, .export-button:disabled:active {
    cursor: not-allowed;
    color: #212529!important;
    background-color: #f8f9fa!important;
    border-color: #f8f9fa!important;
}
.period-selector {
    margin: 20px 0!important;
}
.period-selector .input-select__indicator{
    padding: 0!important;
}
.period-selector .input-select__control {
    min-height: 36px;
    border: 1px solid #9BAAC7!important;
    border-radius: 4px!important;
}
.period-selector .css-1wa3eu0-placeholder {
    color: #6E6E7C;
}
.date-picker-container .react-datepicker-wrapper{
    width: 100%;
}
.date-picker-container i {
    color: #707070;
    position: absolute;
    z-index: 1;
    right: 0;
    margin-right: 22px;
    margin-top: 10px;
}
.date-picker {
    width: 100%;
    min-height: 36px;
    color: #6E6E7C;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.input-search-container input {
    width: 100%;
    min-height: 36px;
    padding-left: 30px;
    color: #6E6E7C;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.input-search-container i{
    color: #3C3C4399;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
}
.report-table .react-bootstrap-table {
    overflow: auto;
}
.report-table .react-bootstrap-table th {
    white-space: nowrap;
}
.report-table::before, .report-table::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
}
.report-table::before {
    left: 0;
}
.cell-fixed-left {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    z-index: 5;
    background-color: #fff;
}

.cell-fixed-left::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.th-title[data-title] {
    position: relative;
    z-index: 1;
}
.th-title[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -25px;
    display: inline-block;
    padding: 1px 6px;
    background: #fff;
    color: #7b7d7f;
    font-size: 10px;
    font-family: sans-serif;
    border: 1px solid #7b7d7f;
    border-radius: 16px!important;
    white-space: nowrap;
}
.th-title[data-title]:hover::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 8px;
    display: inline-block;
    color: #7b7d7f;
    border: 8px solid transparent;	
    border-bottom: 8px solid #7b7d7f;
  }

  .report-container .css-2b097c-container{
      z-index: 10;
  }
  .report-container .react-datepicker-popper {
      z-index: 6;
  }
  
  .toggle-more {
      cursor: pointer;
      color: #4FA3FF;
      font-size: 12px;
  }
.mobile-filters i {
    margin-left: 5px;
    color: #665dff;
}
.mobile-filters i.disabled {
    color: #6e7e88;
    cursor: not-allowed;
}
.filter-container {
    margin-left: 15px;
}
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .period-selector .css-1wa3eu0-placeholder{
        white-space: nowrap;
    }
    .input-search-container i {
        color: #3C3C4399;
        position: absolute;
        z-index: 1;
        left: 0;
        margin-left: 10px;
        margin-top: 11px;
    }
    .date-picker-container i {
        margin-right: 25px;
    }
}

  @media screen and (max-width: 767px) {
    .report-title {
        flex-direction: row;
    }
    .report-title h3 {
        font-size: 18px;
    }
    .input-search-container i {
        margin-left: 10px;
    }
    .date-picker-container i {
        margin-right: 25px;
    }
    .users-table, .checkin-table {
        overflow-x: inherit;
    }
    .cell-fixed-left {
        position: static!important;
    }
  }
@media screen and (max-width: 375px) and (min-width: 360px) {
    .report-title h3 {
        font-size: 14px;
    }
}
  @media screen and (max-width: 320px) {
    .report-container .pl-0 {
        padding-left: 0px!important;
    }
    .input-search-container i {
        margin-left: 10px;
    }
    .date-picker-container i {
        margin-right: 10px;
    }
    .filter-container > div[class*="col-"] {
          padding: 0;
    }
    .report-title {
        flex-direction: row;
        padding: 0;
    }
    .report-title h3 {
        font-size: 14px;
    }
    .date-picker {
        font-size: 12px;
    }
    .period-selector .css-1wa3eu0-placeholder{
        font-size: 12px;
    }
    .input-search-container input::-webkit-input-placeholder {
        font-size: 12px;
    }
    .input-search-container input:-ms-input-placeholder {
        font-size: 12px;
    }
    .input-search-container input::placeholder {
        font-size: 12px;
    }
    .export-button {
        font-size: 12px;
    }
    .contactsTable-paginationFooter {
        font-size: 12px;
    }
  }

  /* MeasuringCircle    */
.measuringCircle {
    display: flex;
    min-width: 200px;
}
.measuringCircle .circleContainer {
    position: relative;
}
.measuringCircle .availability {
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.measuringCircle .circle {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    border: solid 3px;
}
.measuringCircle .text {
    align-self: center;
    margin-left: 9px;
    font-weight: 500;
}

/*  measuringBar    */
.measuringBar {
    min-width: 100px !important;
    width: 100%;
}
.measuringBar > div > div {
    opacity: .5;
}

.activityTimes {
    height: 40px;
    background-color: #F5F7FC;
    border-bottom: 2px solid #f0f0f0;
    border-top: 2px solid #f0f0f0;
    padding: 10px 20px;
    text-align: left;
    font: 14px "Montserrat", Serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    width: 100%;
}

.activityRow {
    white-space: nowrap;
    border-bottom: 2px solid #f0f0f0;
    width: 100%;
}

.activityHeaderText {
    display: inline-block;
    width: 250px;
    color: #5A5A5A;
    font: 13px "Montserrat";
    font-weight: bolder;
    padding-left: 20px;
    margin-bottom: 10px;
}

.activityDate { 
    display: inline-block;
    width: 250px;
    color: #9C9C9C;
    font-family: "Montserrat", Serif;
    font-size: 16px;
    padding-left: 20px;
}

.activityText {
    display: inline-block;
    color: #5A5A5A;
    font: 17px "Montserrat", Serif;
    padding: 23px 21.5px;
    vertical-align: middle;
}

.activityIcon {
    width: 21px;
    height: 18px;
    margin-right: 15px;
    vertical-align: top;
}
.user-pic-reports{
    width: 44px;
    height: 44px;
    border-radius: 4px;
    cursor: pointer;
}
.create-workspace-form {
  margin: 0 auto;
  max-width: 440px;
}
.workspace-configuration-form {
  margin: 0 auto;
  max-width: 440px;
}
.create-workspace-form .login100-form-title,
.workspace-configuration-form .login100-form-title {
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0px;
  color: #454f63;
  opacity: 1;
}
.create-workspace-form p,
.workspace-configuration-form p {
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #6e7e88;
  opacity: 1;
}
.create-workspace-form .workspacesName,
.workspace-configuration-form .workspacesName {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  opacity: 1;
}
.create-workspace-form .form-control:focus,
.workspace-configuration-form .form-control:focus {
  border-color: #9baac7;
  box-shadow: none;
}
.create-workspace-form .backButton{
  margin-top: 20px;
  margin-right: 10px;
}
.workspace-configuration-form .nextButton , .workspace-configuration-form .backButton, .create-workspace-form .backButton{
  position: relative;
}
.create-workspace-form .nextButton,
.workspace-configuration-form .nextButton {
  position: relative;
  background: #665dff 0% 0% no-repeat padding-box;
  border: 1px solid #665dff;
  border-radius: 4px;
  margin-top: 20px;
}
.create-workspace-form .nextButton:active,
.create-workspace-form .nextButton:hover,
.workspace-configuration-form .nextButton:active,
.workspace-configuration-form .nextButton:hover {
  background: #665dff 0% 0% no-repeat padding-box !important;
  border: 1px solid #665dff !important;
}
.create-workspace {
  background: #f1f5fd 0% 0% no-repeat padding-box;
  text-align: center;
  overflow-x: hidden;
}
.create-workspace .cricle-progess {
  width: 40px;
  height: 40px;
  border: 1px solid #c5c3ea;
  opacity: 1;
  border-radius: 50%;
}
.create-workspace .cricle-progess-text {
  color: #a5a1ef;
  text-align: center;
  font-size: 15px;
  margin-top: 8px;
}
.create-workspace .cricle-progess-line {
  width: 38px;
  height: 0px;
  border: 1px solid #c5c3ea;
  opacity: 1;
  margin: 2px 5px;
}
.create-workspace-progess-bar .cricle-progess,
.create-workspace-progess-bar .cricle-progess-line,
.create-workspace-progess-bar .patch-div {
  display: inline-block;
}
.create-workspace-progess-bar {
  margin-top: 40px;
}
.create-workspace .login100-form {
  min-height: 300px;
}
.create-workspace .create-workspace-progess-bar .cricle-progess.selected,
.create-workspace .create-workspace-progess-bar .cricle-progess-line.selected {
  border: 1px solid #665dff;
}
.create-workspace .create-workspace-progess-bar .cricle-progess-text.selected {
  color: #665dff;
}
.create-workspace-progess-bar .patch-div {
  width: 15px;
  height: 20px;
  background: #fcfcff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -16px;
  vertical-align: bottom;
}
.createnewSafelyspace {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: #454F63;
  margin: 0 auto;
  width: 100%;
  margin-top: -45px;
  margin-bottom: 10px;
  font-weight: bold;
}
.create-workspace .login100-form-title-paddingTop,
.workspace-configuration-form .login100-form-title {
  padding-top: 25px;
}
.create-workspace .workspaceNameHelp,
.create-workspace .invitationLink {
  position: relative;
}
.create-workspace .input-div {
  width: 100%;
  padding-bottom: 10px;
}
.create-workspace .label-div {
  padding-bottom: 5px;
}
/* .create-workspace label {
  text-align: center;
  width: 100%;
  font-size: 14px;
} */
.create-workspace .invitationLink {
  padding-bottom: 20px;
}
.workspace-configuration-form .addMore {
  position: relative;
  padding-top: 20px;
  color: #4fa3ff;
}
.workspace-configuration-form .fa-plus {
  padding-right: 5px;
}

.workspace-configuration-form .cricle-icon {
  width: 50px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #665dff;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}

.workspace-configuration-form .cricle-icon svg {
  margin-top: 13px;
  width: 22px;
  height: 19px;
  cursor: pointer;
}
.workspace-configuration-form .bottom-button button {
  margin: 0px 10px;
  margin-bottom: 30px;
}
.workspace-configuration-form .backButton , .create-workspace-form .backButton{
  color: #665dff !important;
  border: 1px solid #665dff !important;
  background: #ffffff !important;
  border-radius: 4px;
}
.workspace-configuration-form .backButton:hover ,.create-workspace-form .backButton:hover{
  color: #665dff !important;
  border: 1px solid #665dff !important;
  background: #ffffff !important;
}
.workspace-configuration-form .omit {
  width: 100px;
  margin: 0 auto;
}
.create-workspace .workspace-created {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: #2B2B42;
  margin: 0 auto;
  width: 100%;
  margin-top: -45px;
  margin-bottom: 10px;
  font-weight: bold;
}
.create-workspace .workSpaceNameCreated {
  color: #665dff;
  padding: 0 5px;
}
.create-workspace .workspace-created span {
  display: inline-block;
}
.phone.create-workspace .workspace-created,
.tablet.create-workspace .workspace-created {
  width: auto;
}

.on-board-header {
  width: 100%;
}
.on-board-header .logo {
  text-align: left;
  margin-top: 20px;
  left: 20px;
}
.on-board-header .login-user {
  text-align: right;
  margin-top: 20px;
  right: 20px;
}
.create-workspace .container-login100 {
  display: unset;
}
.phone.create-workspace .wrap-login100,
.tablet.create-workspace .wrap-login100 {
  width: 90%;
}

.phone.create-workspace,
.tablet.create-workspace {
  padding-bottom: 20px;
}

.phone .on-board-header,
.tablet .on-board-header {
  padding-bottom: 30px;
}

.create-workspace .wrap-login100 {
  width: 730px;
  overflow: hidden;
  display: inline-flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  border: none;
  opacity: 1;
  min-height: 350px;
  padding: 10px;
}

.create-workspace .empty-div{
  min-height: 50px;
}

.login-user .dropdown-menu.show{
  top: -25px!important;
}

.login-user .dropdown-user > .dropdown-toggle::after{
display: none;
}
.login-user .dropdownicon{
  padding-left: 10px;
  color: #707070;
}
.login-user .dropdown,.login-user .dropdown-toggle{
  border-radius: 6px;
}
@media (max-width: 360px) {
  .workspace-configuration-form .ml-3, .mx-3{
    margin-left: 0px!important; 
  }
  }
.plus-add {
  font-size: 22px !important;
  vertical-align: bottom !important;
}
.label-error-message {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #E67070;
}
.label-error-message label {
  text-align: left !important;
}

.signup-error-message label{
  padding: 0px;
  margin-left: 0px;
}

.wrap-signup {
  width: 730px;
  min-height: 560px;
  overflow: hidden;
  display: inline-flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #00000033;
  border-radius: 10px;
  opacity: 1;
}

.tablet .wrap-signup {
  width: 650px;
}

.form-align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;
}

.signup-with-text {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-family: "Montserrat", "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #454f63;
  padding: 10px 0;
  opacity: 1;
}

#eyeIcon {
  cursor: pointer;
}
.CaretDown {
  color: #5a5a5a;
}

a {
  color: #4fa3ff;
}

.signup-icons {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.signup-icons .round-icon {
  width: 32px;
  height: 32px;
  border: 1px solid #bfbfbf;
  opacity: 1;
  border-radius: 50%;
  text-align: center;
  margin: 5px;
}
.signup-form {
  display: grid;
  grid-template-columns: 1fr;
}

.round-icon svg {
  vertical-align: bottom;
}

.signup-form .language-select {
  padding-top: 5px;
  padding-right: 10px;
}

.form-signup {
  max-width: 280px;
  width: 100%;
}

.safely-registration-left {
  background-color: #726bef;
  display: grid;
  grid-template-columns: 1fr;
}

.safely-registration-left .safely-logo-header {
  padding-top: 15px;
}

.safely-registration-left .signin-link {
  background-image: url(/static/media/signupImage.d5012c9b.svg);
  background-color: #726bef;
}

.text-style-div {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0px;
  font-family: "Montserrat", "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
}

.signin-link > p {
  text-align: center;
  font-size: medium 14px;
  font-family: "Montserrat", "Roboto", sans-serif;
  letter-spacing: 1px;
  padding: 10px;
  color: #ffffff;
  opacity: 1;
}

.signin-link .btn-style-div {
  text-align: center;
}

.signin-link .sign-in-btn {
  width: 120px;
  height: 40px;
  border: 1px solid #ffffff !important;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #726bef !important;
}

.signin-link .sign-in-btn:hover {
  background-color: #726bef !important;
  border: 1px solid #ffffff !important;
}

.signin-link .sign-in-btn:focus {
  background-color: #726bef !important;
  border: 1px solid #ffffff !important;
  outline: none !important;
  box-shadow: none !important;
}

.language-dropdown {
  width: 90px;
  height: 40px;
  float: right;
  font-family: "Roboto", Serif;
}

.safely-footer {
  text-align: center;

}

.safely-footer span {
  font-size: 10px;
  font-family: "Montserrat", "Roboto", sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.safely-footer svg {
  width: 63px;
  height: 24px;
  vertical-align: bottom;
}

.terms-text-style {
  text-align: left;
  font-size: 13px;
  font-family: "Montserrat", "Roboto", sans-serif !important;
  letter-spacing: 0px;
  color: #6e7e88;
}
.terms-text-style a {
  text-decoration: none;
  color: #4FA3FF;
  font-weight: 600;
}
/*
.checkbox-div input[type=checkbox] {
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  border: 1px solid #665dff;
  opacity: 1;
  vertical-align: middle;
  cursor: pointer;
}*/

.checkbox-div {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-tick {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #ffffff;
  border: 1px solid #9baac7;
  border-radius: 4px;
}

.checkmark-tick:after {
  content: "";
  position: absolute;
  display: none;
}

/* On mouse-over, add a grey background color */
.checkbox-div:hover input ~ .checkmark-tick {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-div input:checked ~ .checkmark-tick {
  background-color: #665dff;
  border: 1px solid #665dff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-div:after {
  content: "";
  position: absolute;
  display: none;
  border: 1px solid #665dff;
}

/* Show the checkmark when checked */
.checkbox-div input:checked ~ .checkmark-tick:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-div .checkmark-tick:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.signUp-title {
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat", Serif !important;
  letter-spacing: 0px;
  color: #454f63;
  opacity: 1;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 10px;
}

.input-style {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  font-family: "Roboto", Serif;
  opacity: 1;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #4fa3ff;
}

.singupButton,
.signupBtn {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #665DFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #665DFF !important;
}

.singupButton:disabled {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  cursor: not-allowed;
  width: 100%;
}

.phoneSingin {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  color: #454f63;
  font-size: 11px;
  font-family: "Montserrat", Serif;
}

.phoneSingin .phoneSigninLink {
  padding-left: 5px;
}

.phone .signup-form {
  max-width: 100% !important;
  margin: 0 auto;
}


.phone .form-align-center {
  width: auto;
}
.phone .form-signup {
  max-height: 600px;
}
.css-18jox8o-singleValue {
  font-size: 12px;
  margin-left: 1px;
  margin-right: 1px;
}
.paragraph-text-style {
  text-align: center;
  font-weight: normal;
  font-family: "Roboto", Serif !important;
  font-size: 17px;
  letter-spacing: 0px;
  color: #6E7E88;
  opacity: 1;
  padding-top: 5px;
  padding-bottom: 15px;
}
.socialIcon-signUp {
  padding-top: 10px;
}

@media screen and (max-width: 320px) {
  .checkmark-tick{
    top: 4px !important;
  }
  .singupButton, .signupBtn{
    width: -webkit-fill-available !important;
  }
}
.form-style {
    height: 100% !important;
}

.createSafelySpace-div {
    height: 450px;
    min-height: 60%;
    background-color: #F1F5FD;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
}
.welcome-header {
    background-color: #F1F5FD;
}
.welcomePage-body1 {
    height: 400px !important;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
}
.existingSpace-div {
    height: 240px;
    min-height: 40%;
    width: 100%;
    border-top: 1px solid rgb(207, 219, 241);
    background: #EDF3FF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

/* @media screen and (max-height: 650px) { 
    .createSafelySpace-div {
        height: 400px !important;
        min-height: 60% !important;
    }
   .existingSpace-div {
        height: 250px !important;
        min-height: 40% !important;
    }
    .welcomePage-body1 {
        height: 200px !important;
        padding-bottom: 100px !important;
    }
} */

.welcome-container {
    padding: 0px;
    background-color: #EDF3FF;
}
.btn-display {
    display: flex;
    justify-content: center;
}
.scan-btn-div {
    width: 100%;
}
.scan-btn-div .scan-btn-button {
    width: 100%;
    max-width: 336px;
    border: 1px solid #665DFF !important;
    background-color: #EDF3FF !important;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #665DFF !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
.scan-btn-div .scan-btn-button:focus, .scan-btn-div .scan-btn-button:active, .scan-btn-div .scan-btn-button:hover{
    border: 1px solid #665DFF !important;
    color: #665DFF;
    background: #EDF3FF !important;
    background-color:#EDF3FF !important;
    box-shadow: none !important;
}
.welcome-text-div {
    text-align: center;
}
.welcome-text-div .text-style {
    text-align: center;
    font-size: 22px;
    font-family: "Montserrat", Serif;
    font-weight: bold;
    letter-spacing: 0px;
    color: #454F63;
    padding-bottom: 30px;
}
.user-name-style {
    font-size: 28px;
}
.para-text-style {
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat", Serif;
    font-weight: normal;
    letter-spacing: 0px;
    color: #6E7E88;
    opacity: 1;
}
.new-workspace-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
}
.existing-text-style {
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #454F63;
    opacity: 1;
}
.tryAnotherAccount-Btn {
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #4FA3FF;
    opacity: 1;
    border: none;
    background-color: #EDF3FF;
}
.phone.welcomePage{
    overflow-x: hidden;
}
.header-row-div{
    width: 100%;
}
.create-space-btn {
    width: 100%;
    max-width: 336px;
}
.set-workspace.switch-control-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.set-workspace.options{
   display: flex;
   width: 100%;
   min-width: 200px;
}

.setworkspace.update-settings-text{
    color: #606076;
    text-align: center;
    font: bold 18px "Montserrat", Serif;
    padding-bottom: 28px;
    padding-top: 28px;
    display: block;
}

.border-top{
    border-top: #E1E7F2;
 }
 
 .border-bottom{
    border-top: #E1E7F2;
 }

 .padding-top{
     padding-top: 30px;
 }

 .switch-option-padding{
    padding: 7px 0px;
 }

 .almost-done-title{
   font-family: "Roboto", Serif;
   font-weight: bold;
    font-size: 18px;
    color: #707070;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: 25px;
    padding-bottom: 10px;
 }

 /* .switch-label{
    text-align: left !important;
 } */
 .set-workspace-text {
    text-align: left !important;
    width: 100%;
    max-width: 420px;
 }
 .switch-label-text {
   color: #5D768F;
 }


.forgotPass-header .login-header {
    height: 105px;
}
.forgot-para-text {
    text-align: center;
    width: 100%;
    max-width: 360px;
    font-size: 17px;
    font-family: "Roboto", Serif !important;
    letter-spacing: 0px;
    color: #6E7E88;
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 5px;
}
.forgotPass-form {
    min-height: 100px;
}
.forgot-socialIcon {
    padding-top: 10px !important;
}
.bOdgSF {
    width: 100% !important;
}
.send-btn .btn-primary {
    background-color: #665DFF !important;
    border: #665DFF !important;
}
.send-btn .btn-primary:focus, .btn-primary:active {
    background-color: #665DFF !important;
    border: #665DFF !important;
}
.send-btn .btn-primary:disabled {
    background-color: #DDDDDD !important;
    border: #DDDDDD !important;
}
.send-btn {
    margin-bottom: 12px;
    padding-top: 12px;
}
.alert-success {
    background: #39B97E 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    text-align: center;
    font-family: "Montserrat", Serif !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.displayWrapper {
    flex-direction: column !important;
}
.backToSingup-link-div {
    font-size: 14px;
    letter-spacing: 0px;
    color: #4FA3FF;
    height: 80px;
    padding-top: 35px;
    font-weight: 500;
}
.verify-wrapper {
    min-height: 500px;
}
.pincode-input-text {
    width: 44px !important;
    height: 44px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #9BAAC7 !important;
    border-radius: 4px;
    margin: 0px 4px !important;
}
.verify-pin-input-div {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}
.verify-btn-div {
    margin: auto;
    width: 206px;
    padding-top: 30px;
}
.verify-form {
    width: 320px;
}
.verify-header {
    height: 110px !important;
}
.resend-pin-div {
    padding-top: 20px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0px;
    color: #6E7E88;
    opacity: 1;
}
.pin-verified-fail, .pin-verified-success {
    text-align: center;
    font-family: "Roboto", Serif !important;
    letter-spacing: 0px;
    color: #39B97E;
    font-size: 12px;
    opacity: 1;
    padding-top: 5px;
}
.pin-verified-fail {
    color: red;
}
.pin-success-btn {
    background-color: #39B97E !important;
    border: 1px solid #39B97E !important;
    width: 100% !important;
}
.pin-verified-icon {
    font-size: 18px;
    margin-top: 2px;
    color: #FFFFFF;
    font-weight: bold;
}
.resend-pin-btn {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4FA3FF;
    opacity: 1;
    background-color: #FFFFFF !important;
}
.btn-submit-pin {
    background: #665DFF 0% 0% no-repeat padding-box;
    border: 1px solid #665DFF;
    width: 100% !important;
}
.btn-submit-pin:disabled {
    background: #DDDDDD 0% 0% no-repeat padding-box !important;
    border: 1px solid #DDDDDD !important;
    width: 100% !important;
}

.not-receive-verification-pin-text{
    text-align: center;
    color: #6e7e88;
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
}
.safelypass-text-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.safelypass-activated-text {
  color: #39b97e;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", Serif;
  padding-left: 5px;
}
.safelypass{
  padding: 10px 20px;
}

.timer-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timer-check-out-contier{
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
}
.workspaceSafelyPasscontier{
  display: flex;
  align-items: center;
}
.timer-check-out-contier .noSpaceImage-worksapce{
padding-top: 1px;
}
.safely-workspace-name{
  color: #2B2B42;
  font: normal normal 600 15px/19px "Montserrat", Serif;
  padding-left: 5px;
  word-break: break-word;
}
.timer-container {
  width: 268px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  align-items: center;
}
.safelypass-activated-date{
font: normal 16px/19px "Montserrat", Serif;
color: #606076;
}

.user-info-text-name{
  color: #2B2B42;
  font: normal 600 1.5rem/1.8125rem "Montserrat", Serif;
  word-break: break-word;
}

.timer-text {
  font-size: 17px;
  color: #2b2b42;
  font-family: "Roboto", Serif;
  font-weight: bold;
  margin: 0 10px 0 10px;
}

.timer-container > .btn {
  padding: 0 !important;
}

.container > .user-profile {
  padding: 0 !important;
  margin: 35px 10px;
}

.btn-checkout {
  background: #e67070;
  width: 120px;
  height: 40px;
  border-radius: 4px;
}

.icon-pass-text-activated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotate(-20deg);
}

.icon-pass-text-activated::before {
  color: #39b97e;
}

.text-content > div {
  width: auto !important;
}

.safelyPass-popup .modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #00000033;
  border-radius: 10px;
  opacity: 1;
}
.safelyPass-popup .modal-header {
  border-bottom: none;
}

.safelyPass-popup .modal-content .modal-header .modal-title {
  font-size: 22px;
  font-weight: 700;
  color: #2b2b42;
}

.safelyPass-popup .modal-content .modal-footer {
  border-top: none;
}

.safelyPass-popup .safelyPass-popup-button {
  border: 1px solid #665dff;
  border-radius: 4px;
  background: #ffff;
  color: #665dff;
  width: 100px;
  height: 40px;
}
.safelyPass-popup .safelyPass-popup-button-no {
  border: 1px solid #E67070;
  border-radius: 4px;
  background: #E67070;
  color: #fff;
  width: 100px;
  height: 40px;
}
.safelyPass-popup .safelyPass-popup-button-yes {
  border: 1px solid #665DFF;
  border-radius: 4px;
  background: #665DFF;
  color: #fff;
  width: 100px;
  height: 40px;
}

.safelyPass-popup .safelyPass-popup-text1 {
  color: #e67070;
}

.safelyPass-popup .safelyPass-popup-text2 {
  margin-top: 10px;
  color: #6e6e7c;
}

.safypasstext {
  word-break: break-all;
}

/* .text-center{
    text-align: center;
} */
.safelypass-coniter .user-details img{
  width: 120px;
  height: 120px;
}
.phone.safelypass .user-profile{
  display: inherit !important;
  margin-left: 0px;
}

.btn-check-into-subspace{
  height: 40px;
  background: #39B97E 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border-color: currentColor;
}

.btn-check-out-subspace{
  height: 36px;
  background: #E67070 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border-color: currentColor;
}

.qr-code-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 480px){
  .timer-container{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    margin: 0px;
  }

  .timer-text{
    font-size: 16px;
  }

  .btn-check-into-subspace{
    width: auto;
    height: auto;
  }

  .qr-code-section{
    margin: 5px 0 10px 0px;
  }
}

@media only screen and (max-width: 712px){
  .timer-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px 10px 0px;
  }

  .user-profile{
    max-height: none;
  }
}


.phone .timer-container{
  text-align: center;
  display: block;
  margin: 0px;
}
.phone .user-details{
  display: block !important;
  text-align: center;
}
.phone .timer-container .btn{
  width: 100%;
}

.tablet .safelypass-coniter .user-details{
  display: block !important;
  text-align: center;
  justify-content: flex-start;
}
.tablet .safelypass-coniter .safelypass-text-container {
  justify-content: space-evenly;
  max-height: 130px;
}
.tablet .safelypass-coniter .timer-section{
  justify-content: space-evenly;
  max-height: 130px;
}
@media only screen and (orientation:landscape) and (max-width: 823px){
.tablet .safelypass-coniter .user-details, .tablet .safelypass-coniter .timer-section,.tablet .safelypass-coniter .safelypass-text-container{
  flex: 100% 1 !important;
  max-width: 100% !important;
}  
.tablet .safelypass-coniter .timer-container{
  text-align: center;
  display: block;
}
.tablet .safelypass-coniter .user-details{
  display: block !important;
  text-align: center;
}
.tablet .safelypass-coniter .timer-container .btn{
  width: 100%;
}
.tablet .safelypass-coniter .user-profile{
  max-height: 100%;
}
.tablet .timer-check-out-contier,.tablet .safelypass-activated-div{
   margin-right: 0px;
}
}
.phone .safelypass-coniter .user-profile{
  max-height: 100%;
}

.tablet .safelypass-coniter .user-profile{
  margin-top: 30px;
}

 .check-in-user-avtar .btn-light , .checkin-modal .custome-multiselect .dropdown-heading-dropdown-arrow svg{
    display: none !important;
    }
  .popup-datetime{
    color: #78849E;
    font-size: 15px;
    /* text-transform: capitalize; fixed 227 */
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
  }  
  .popup-checkin{
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    text-align: center;
    border-color: #665DFF;
    font-weight: 600;
  }

  .popup-checkin:hover,.popup-checkin:focus,.popup-checkin:active{
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    border-color: #665DFF;
    box-shadow:none !important;
  }
  .checkin-modal .custome-multiselect .dropdown-content .select-item{
   padding-top: 0px;
   padding-bottom: 0px;
   margin-bottom: 1px;
  }
  .checkin-modal .custome-multiselect .dropdown-content .item-renderer, .checkin-modal .custome-multiselect .dropdown-heading-value{
    color: #5A5A5A;
  }
  .checkin-modal .dropdownPointer{
    position: absolute;
    right: 10px;
    margin-top: -30px;
    pointer-events: none;
  }
  /* button.check-in-new-user {
    float: right;
  } */
  /* @media  screen and (min-width: 712px){
    .search-checkpoint{
      width: 350px;
  }
  } */
  .checkpoint-modal .checkin-input-container {
    width: 260px !important;
  }

  .popup-disable{
    background: #E67070 !important;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    text-align: center;
    border-color: #E67070 !important;
    font-weight: 600;
    cursor: pointer;
    margin: 1.4rem 0;
  }

  .popup-disable:hover,.ppopup-disable:focus,.popup-disable:active{
    background: #E67070 !important;
    border-radius: 4px;
    opacity: 1;
    border-color: #E67070 !important;
    box-shadow:none !important;
  }

  .popup-disable:disabled {
    cursor: not-allowed;
    background: #F2F2F2 0% 0% no-repeat padding-box !important;
    border: 1px solid #D3D3D3 !important;
    color: #D3D3D3 !important;
  }

  .checkpoint-modal input[type=radio] {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 5px 5px 0px 0px;
  }
  .checkpoint-modal input[type='radio']:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: 0px;
        left: 0px;
        position: relative;
        background-color: #ffffff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid #665DFF;
  }

  .checkpoint-modal input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    top: -1px;
    left: 2px;
    position: relative;
    background-color: #665DFF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #FFFFFF;
  }

  .checkpoint-modal .radio-button-background label {
    vertical-align: middle;
    color: #6E6E7C;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
  }
  .disable-pass-date-picker {
    margin: 0.8rem 0;
  }
  .disable-pass-date-picker label {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #617992;
    margin-bottom: 3px;
  }

  .multi-select-in-disable-pass {
    margin-top: 0.5rem;
    margin-left: 20px;
  }
  .multi-select-in-disable-pass label {
    font: 14px "Roboto";
    letter-spacing: 0px;
    color: #617992;
  }

  .checkin-modal .multiselect-arrow-custom .dropdown-heading-dropdown-arrow svg {
    display: inline !important;
  }

  .disable-date {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #6E6E7C;
    opacity: 0.4;
  }
  
  .search-checkpoint{
    width: 350px;
  }

  @media screen and (max-width: 712px){  
    .check-in-new-user {
      width: auto;
      margin: 0px;
    }
    
  }

  .disable-pass-date-picker i {
    z-index: 0 !important;
    cursor: pointer;
    margin-top: -25px;
  }

  @media only screen and (max-width: 640px){
    button.check-in-new-user {
      display: block;
      float: left;
      margin: 0px;
    }

    .search-checkpoint{
      width: 100%;
    }
  }

  /* .phone .checkpoint-continer{
    margin-top: 70px;
  } */
  /* .phone .checkpoint-label-name{
    padding-top: 0px !important;
  } */

  .go445362013 .panel-content {
    max-height: 185px;
  }

  .tab-checkpoint {
    font-family: "Montserrat", Serif;
    color:  #6e7e88;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 60px;
}

.tab-checkpoint.nav-item.nav-link.active {
    background-color: #F1F5FD;
    color: #665DFF;
}



.search-and-button-container{
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 415px) {
  .tab-checkpoint {
    padding: 10px 20px;
  }

  .check-in-new-user {
    width: 100%;
    margin: 10px 50px 10px 0px !important
  }

  .search-and-button-container{
    display: grid;
    justify-content: normal;
    padding-right: 0px;
  }
}


 .big-modal {
    max-width: 70%;
  }

 .popup-text{
    font-family: "Roboto", Serif;
    color: #6E6E7C;
    font-size: 17px;
  }

.popup-text-bold{
  font-family: "Roboto", Serif;
  color: #6E6E7C;
  font-size: 17px;
  font-weight: 600;
}

.popup-icon-container{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.popup-title-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.disabled-input:disabled{
  background-color: #fcfcff;
  opacity: 1;
}

.checkpoint-modal .modal-footer {
  flex-wrap: inherit;
}
/* .edit-form-header {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #2B2B42;
    opacity: 1;
    padding-top: 20px;
    padding-left: 10px;
} */
/* .quill-editor-div {
    padding-left: 10px;
    padding-top: 20px;
} */
.quill-editor-div .quill {
    width: 100%;
    max-width: 860px !important;
    height: 300px;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.ql-toolbar.ql-snow {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
    opacity: 1;
    margin: 4px;
}
.ql-container.ql-snow {
    max-height: 220px;
    border: none;
}
.quill-editor-label {
    text-align: left;
    font-weight: 500;
    font-family: "Montserrat", Serif;
    font-size: 14px;
    letter-spacing: 0px;
    color: #454F63;
    opacity: 1;
}
.edit-form-btn {
    margin-top: 10px;
    text-align: right;
    margin-right: 20px;
}
.save-btn , .cancel-btn{
    width: 110px !important;
    height: 40px;
    border:1px solid #665DFF;
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    outline: none !important;
    box-shadow: none !important;
    margin: 5px;
}
.save-btn:hover {
    background: #665DFF 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.cancel-btn {
    background-color: #FFFFFF;
    color: #665DFF;  
}
.cancel-btn:hover {
    background-color: #FFFFFF;
    color: #665DFF;  
}
/* .add-another-input {
    color: #4FA3FF;
    font-size: 14px;
    font-weight: 400;
    margin-right: 17%;
    cursor: pointer;
} */
.plus-symbol {
    vertical-align: bottom !important;
    font-size: 20px !important;
}
.text-input {
    border: none;
    background-color: #F1F5FD !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    outline: none;
    border-bottom: 1px solid #9a9b9e;
    width: 90% !important;
    color: #334856;                             
}
/* .text-input-wrapper {
    flex: 1px;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 10px;                           
} */
/* .delete-icon , 
.tabletdelete-icon ,
.phonedelete-icon
{
    font-size: 20px;
    margin-right: 12%;
    cursor: pointer;
    float: right;
    margin-top: 0;
    text-align: center;
    color: #e67070;                           
} */
.select-input-para {
    font-weight: 700; 
    color: #334856;
}
/* .select-input-div {
    width: 80%;
    height: 100%;
    background-color: #f2f5fe;
    margin: 15px;
    padding: 10px;
    border-width: 1px;
    border-radius: 4px;
    border-style: dashed;
    border-color: #b7c6e8;                          
} */
/* ------------- */
/* .tabletdelete-icon {
    margin-right: 10% !important;
}
.phonedelete-icon {
    margin-right: 6% !important;
} */
.drop-down-style {
    width: 90%;
}
.form-control-textAera{
    min-height: 300px;
    max-width: 860px;
    max-height: 300px;
    border: 1px solid #dddddd;
}
.verifyEditdiv {
    cursor: pointer;
}
.verifyEditdiv > svg .a {
    fill: #665DFF;
  }
/* 
.inputs-wrapper {
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 10;
} */
.trash-icon {
    font-size: 20;
    margin-right: 12%;
    cursor: pointer;
    float: right;
    margin-top: 0;
    text-align: center;
    color: #e67070;
}
/* .input-container {
    width: 80%;
    height: 100%;
    background-color: #f2f5fe;
    margin:15px 10px;
    padding: 20px;
    border-width: 1px;
    border-radius: 4px;
    border-style: dashed;
    border-color: #b7c6e8;
} */

/* .input-container > .title {
    font-weight: 700; 
    color: #334856;
} */

.select-input {
    width: 90%;
    margin-top: 2px;
    border-color: #9a9b9e;
    background-color: #fff;
    border-radius: 4px;
}
.select-input option:hover {
    background-color: red;
}
/* .selected-input {
    border: none;
    background-color: transparent!important;
    margin-top: 20px!important;
    outline: none;
    border-bottom: 1px solid #9a9b9e;
    width: 90%;
    color: #334856;
} */

.input-checkbox {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.input-checkbox input:not([type="checkbox"]) {
    width: 80%!important;
}
.input-checkbox .checkbox label {
    margin-bottom: 0;
}
.input-checkbox .checkbox .cr {
    background-color: #fff;
}

/* .edit-form-header {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #2B2B42;
    opacity: 1;
    padding-top: 20px;
    padding-left: 10px;
} */
/* .quill-editor-div {
    padding-left: 10px;
    padding-top: 20px;
} */
.quill-editor-div .quill {
    width: 100%;
    max-width: 860px !important;
    height: 300px;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.ql-toolbar.ql-snow {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
    opacity: 1;
    margin: 4px;
}
.ql-container.ql-snow {
    max-height: 220px;
    border: none;
}
.quill-editor-label {
    text-align: left;
    font-weight: 500;
    font-family: "Montserrat", Serif;
    font-size: 14px;
    letter-spacing: 0px;
    color: #454F63;
    opacity: 1;
}
.edit-form-btn {
    margin-top: 10px;
    text-align: right;
    margin-right: 20px;
}
.save-btn , .cancel-btn{
    width: 100px;
    height: 40px;
    border:1px solid #665DFF;
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    outline: none;
    box-shadow: none;
    margin: 5px;
}
.save-btn:hover {
    background: #665DFF 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.cancel-btn {
    background-color: #FFFFFF;
    color: #665DFF;  
}
.cancel-btn:hover {
    background-color: #FFFFFF;
    color: #665DFF;  
}
/* .add-another-input {
    color: #4FA3FF;
    font-size: 14px;
    font-weight: 400;
    margin-right: 17%;
    cursor: pointer;
} */
.plus-symbol {
    vertical-align: bottom !important;
    font-size: 20px !important;
}

.text-input {
    border: none;
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 10px;
    outline: none;
    border-bottom: 1px solid #9a9b9e;
    width: 80%;
    color: #334856;                             
}
/* .text-input-wrapper {
    flex: 1px;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 10px;                           
} */
/* .delete-icon {
    font-size: 20px;
    margin-right: 12%;
    cursor: pointer;
    float: right;
    margin-top: 0;
    text-align: center;
    color: #e67070;                           
} */
.select-input-para {
    font-weight: 700; 
    color: #334856;
}
/* .select-input-div {
    width: 80%;
    height: 100%;
    background-color: #f2f5fe;
    margin: 15px;
    padding: 10px;
    border-width: 1px;
    border-radius: 4px;
    border-style: dashed;
    border-color: #b7c6e8;                          
} */
.select-input-dropdown {
    width: 80%;
    margin-top: 2px;
    border-color: #9a9b9e;
    background-color: #fff;
    border-radius: 4px;
}
.editor-wrapper {
    padding: 15px;
}
/* .edit-form-title {
    color: #2B2B42;
    margin-top: 15px;
    font-size: 18px;
    padding-left: 10px;
} */
.deny-pass-toggle {
    margin-bottom: 10px;
}

.form-control-textAera {
    min-height: 300px;
    max-width: 860px;
    max-height: 300px;
    border: 1px solid #dddddd;
}


@media screen and (max-width: 480px) and (orientation: landscape) {
    /* .input-container {
        width: 95%;
    } */
    /* .trash-icon {
        margin-right: 0;
    } */
    .select-input, .selected-input {
        width: 100%;
    }
}
@media screen and (max-width: 480px) and (orientation: portrait) {
    /* .input-container {
        width: 90%;
    } */
    /* .trash-icon {
        margin-right: 0;
    } */
    .select-input, .selected-input {
        width: 100%;
    }
}
.add-new-post-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c6c6c6;
  border-radius: 14px;
  opacity: 1;
  padding: 10px 20px;
  margin-top: 25px;
}
.add-new-post-text {
  color: #585858;
  font-size: 14px;
  width: 60%;
  display: inline-block;
  font-weight: 600;
}
.add-new-post-button-div {
  display: inline-block;
  width: 40%;
  text-align: right;
}
.add-new-post-button {
  width: 150px !important;
  height: auto;
  border: 1px solid #665dff;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-weight: 500;
  border-radius: 4px !important;
  color: #665dff !important;
}
.add-new-post-button:hover,
.add-new-post-button:focus {
  border: 1px solid #665dff;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.phone .add-new-post-text {
  width: 100%;
}
.phone .add-new-post-button-div {
  width: 100%;
  margin-top: 10px;
}
.desktop .add-new-post-col {
  padding: 0px 50px;
}

.PhotoOrVideoContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 5px;
}

.PhotoOrVideo {
  width: 100%;
  max-width: 205px;
  height: 205px;
  margin: 12px;
  padding: 45px;
  background: #f1f5fd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.photo-icon-style {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  line-height: 2 !important;
  font-size: 30px !important;
  opacity: 1;
  border-radius: 64px;
  cursor: pointer;
}
.photo-icon-style svg {
  width: 30px;
  height: 30px;
}
.photo-icon-text {
  color: #585858;
  margin-top: 10px;
}

.camera-style {
  background-color: #665dff !important;
  border: none;
  text-align: center;
}

.camera-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  align-items: center;
}

.flip-icon-style {
  margin-right: 20px;
  margin-left: 60px;
}
.off.switch-toggle-style .react-switch-button {
  background: #999999;
}
.switch-toggle-style {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}
.switch-toggle-style label {
  padding: 0 5px;
  color: #999999;
  font-weight: 500;
}
.switch-toggle-style .react-switch-button {
  background: #ffffff;
}
@media screen and (max-width: 415px) {
  .PhotoOrVideoContainer {
    display: inherit;
  }
  .PhotoOrVideo {
    margin: 0 auto;
    margin-bottom: 12px;
  }
}

.takePhotoOrVideoPopup .modal-title {
  color: #2b2b42 !important;
  font-size: 22px;
  font-weight: 600;
}
.editimagepopup .row {
  margin: 0px;
}
.takePhotoOrVideoPopup video {
  height: auto !important;
}
.videoRecoderDiv {
  height: 80vh;
}
/* .addPopup .border-radius-none {
  color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #fff;
  width: 120px;
}

.addPopup .border-radius-none:hover {
  color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #fff;
  width: 120px;
} */

.addPopup .post-button,
.addPopup .post-button:hover {
  color: #fff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #6c64fb;
  width: 120px;
}

.addPopup .post-button-disable:hover,
.addPopup .post-button-disable {
  color: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  background: #cccccc;
  width: 120px;
  cursor: not-allowed;
}
.new-post-container {
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 2.5rem;
}

.new-post-image {
  display: inline-block;
}
.new-post-image img,
.new-post-image video {
  width: 375px;
  border-radius: 10px;
}
.new-post-image img {
  height: 341px;
}
.new-post-text-div {
  display: inline-block;
  vertical-align: top;
  padding-left: 30px;
}

.new-post-text-div textarea,
.new-post-text-div textarea:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  max-height: 120px !important;
  min-height: 120px !important;
  width: 320px;
}
.new-post-text-div .custome-multiselect {
  margin-top: 20px;
  width: 320px;
}
.dropdown-container {
  box-shadow: none !important;
  border-color: var(--rmsc-border) !important;
}

@media screen and (max-width: 860px) {
  .new-post-container {
    flex-direction: column;
    margin: 0;
  }
  .new-post-text-div textarea {
    margin-top: 20px;
    width: 100%;
  }
  .new-post-text-div {
    padding-left: 0px;
  }
  .new-post-image img,
  .new-post-image video {
    width: 100% !important;
  }
}
@media screen and (max-width: 320px) {
  .new-post-text-div textarea {
    width: 270px;
  }
  .new-post-text-div .custome-multiselect {
    width: 270px;
  }
  .display-subspace-div {
    width: 270px !important;
  }
}
.display-subspace-div {
  margin-top: 10px;
  width: 320px;
}
.display-subspace-style {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  font-family: "Roboto", Serif;
  letter-spacing: 0px;
  color: #5a5a5a;
  background: #f2f5ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  padding: 6px;
  margin-right: 5px;
  opacity: 1;
  word-break: break-word;
  margin-top: 5px;
  white-space: pre-line;
}

.view-post-div {
  padding: 0px !important;
}
.post-header {
  padding: 10px 20px;
  display: inline-flex;
  width: 100%;
}
.post-footer {
  padding: 10px 20px;
}
.post-user-name {
  text-align: left;
  font-weight: bold;
  font-family: "Montserrat", Serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
}
.post-space-name {
  text-align: left;
  font-family: "Montserrat", Serif;
  font-size: 11px;
  letter-spacing: 0px;
  opacity: 1;
}
.post-space-style {
  display: inline-block;
}
.post-time {
  padding-left: 5px;
}
.post-caption-style {
  text-align: left;
  font: Medium 14px/18px "Montserrat", Serif;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
  word-break: break-all;
  white-space: pre-line;
}
.view-comment-text {
  text-align: left;
  font: Medium 14px/18px "Montserrat", Serif;
  letter-spacing: 0px;
  color: #8f96aa;
  opacity: 1;
}
.user-comment-section {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
}
.post-text-field {
  border: none;
  width: 100%;
  border-radius: 0 !important;
  opacity: 0.5;
  border-bottom: 1px solid #8f96aa;
}
.post-button-style,
.post-button-style:hover {
  text-align: left !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Montserrat", Serif !important;
  letter-spacing: 0px !important;
  color: #665eff !important;
  border: none !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.post-button-style :disabled {
  color: #665eff;
  opacity: 0.3 !important;
}
.post-icons {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.icon-space {
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
}
.spinnerpopup {
  margin: 0 auto;
  max-width: 85px !important;
}
.spinnerpopup .spinner-loader {
  margin-top: 5px !important;
}
.post-space-style:first-child::before {
  content: none !important;
}

.post-space-style::before {
  content: ",";
  padding-right: 5px;
}
.post-space-name span {
  vertical-align: unset !important;
}

.view-comment-text::-webkit-input-placeholder {
  color: #8f96aa;
}

.view-comment-text:-ms-input-placeholder {
  color: #8f96aa;
}

.view-comment-text::placeholder {
  color: #8f96aa;
}
.post-count-style {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
}
.outlineColor {
  color: #707070;
  font-size: 22px !important;
}
.fillColor {
  color: #665eff;
  font-size: 22px !important;
}
.editimagepopup .modal-header {
  padding: 10px 12px;
}
.MccLt {
  display: none !important;
}
.recoding-vedio {
  margin-top: 5px;
}
.recoding-buttons-next {
  text-align: right;
}
.recoding-buttons-next button,
.recoding-buttons-next button:hover {
  width: 120px;
  border: 1px solid #665dff;
  background: #665dff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  box-shadow: none;
  margin: 5px;
}
.recodingdiv {
  background: #ce3b3b !important;
}
.startrecord,
.stoprecord {
  margin-top: 18px;
  color: white !important;
}
#timer {
  position: absolute;
  z-index: 999;
  margin-top: -40px;
  color: #fff;
  right: 30px;
  font-size: 16px;
  font-weight: 500;
}
.loading-cal-spinner {
  margin-top: 5px;
  color: #6e6e7c;
  font-size: 12px;
  text-align: center;
}
.infiniteScrollpost,
.infinite-scroll-component__outerdiv {
  overflow-x: hidden !important;
  width: -webkit-fill-available;
}

.add-new-post-continer {
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.post-header-left {
  display: inline-flex;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.post-header-right {
  padding-right: 0px !important;
  padding-left: 0px !important;
  left: 4%;
}
.post-header-right .threedots:after {
  content: "\2807";
  font-size: 22px;
  color: #617992;
  left: 7px;
  top: -3px;
  font-family: none;
}
.post-header-right .dropright:hover {
  width: 24px;
  height: 24px;
  background-color: #f5f5f5;
  border-radius: 50px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
.deletePostText {
  text-align: left;
  font-size: 17px;
  font-weight: normal;
  font-family: "Roboto", Serif !important;
  letter-spacing: 0px;
  color: #6e6e7c;
}
.activeSafelypassdisplay{
  color: #6E6E7C;
}
.add-photo-or-video-link {
  margin-top: 20px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0px;
  color: #665DFF;
  cursor: pointer;
  opacity: 1;
}
.camera-post-icon {
  margin-right: 10px;
  vertical-align: text-bottom;
}
.add-post-text {
  padding: 0 40px;
}
.add-post-text textarea,
.add-post-text textarea:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9baac7;
  border-radius: 4px;
  max-height: 120px !important;
  min-height: 120px !important;
  width: 100%;
}
.add-post-text .custome-multiselect {
  margin-top: 20px;
  width: 320px;
}
@media screen and (max-width: 860px) {
  .add-post-text {
    padding: 0px;
  }
}
@media screen and (max-width: 320px) {
  .add-post-text textarea {
    width: 270px;
  }
  .add-post-text .custome-multiselect {
    width: 270px;
  }
  .display-subspace-div {
    width: 270px !important;
  }
}
.desktop .postloadmore{
  padding-right: 50px;
}
.phone .postloadmore, .tablet .postloadmore{
  padding-right: 15px;
}
.postloadmore{
  width: 100%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
}
.loadmore{
  width: auto !important;
  text-transform: uppercase;
  font: normal 600 11px/14px "Montserrat", Serif;
}

.postContentModal {
  justify-content: center;
}
.postContentModal .modal-content {
  width: auto;
}

.postModal .modal-footer {
  margin-right: 2.5rem;
}

.postModal .modal-title, .editimagepopup .modal-title {
  font-size: 22px;
  font-weight: bold;
}

.titleAlign .modal-title { 
  padding-left: 40px;
}
@media screen and (max-width: 860px) { 
  .titleAlign .modal-title {
    padding-left: 0px;
  }
  .postModal .modal-footer {
    margin-right: 0;
  }
}
.lastpagethatsAll{
  color: #585858;
  font: normal 500 14px/18px "Montserrat", Serif;
  text-align: center;
}
.marketplace-container-row {
    flex-direction: row;
    justify-content: center; 
    padding: 10px 20px;
}

.desktopMarketplace-data {
    margin: 20px 15px;
    flex: auto;
}

.tabletMarketplace-data{
    margin: 5%;
    flex: none;
}

.phoneMarketplace-data {
    margin: 20px 15px;
    flex: none;
}

.market-product-div {
    width: 243px;
    height: 94px;
    background: transparent linear-gradient(90deg, #202D4C 0%, #304381 100%) 0% 0% no-repeat padding-box;
    border: 4px solid #A3B5B5;
    border-radius: 63px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    margin: 20px 0;
    padding: 10px;
}

.marketPlace-image {
    width: 243px;
    height: 149px;
    opacity: 1;
    border-radius: 5px;
}

.market-product-left {
    text-align: left;
    font-size: 10px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    padding-left: 10px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    color: #FFFFFF;
    word-break: break-word;
}
.market-product-right {
    width: 74px;
    height: 74px;
    border-radius: 100%;
    border: 4px solid #CAD5D6;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    flex: none;
    margin-top: -4px;
    display: flex;
    align-items: center;
    padding: 9px !important;
    justify-content: center;
}
.marketPlace-btn-text {
    font-size: 10px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #004850;
    text-transform: uppercase;
    text-align: center;
    opacity: 1;
    text-decoration: none !important;
    cursor: pointer;
}

.assign-to-container{
    background-color: #FBFBFB;
}

.assign-to-multiselect{
    width: 210px;
}
