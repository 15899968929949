.form-style {
    height: 100% !important;
}

.createSafelySpace-div {
    height: 450px;
    min-height: 60%;
    background-color: #F1F5FD;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
}
.welcome-header {
    background-color: #F1F5FD;
}
.welcomePage-body1 {
    height: 400px !important;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
}
.existingSpace-div {
    height: 240px;
    min-height: 40%;
    width: 100%;
    border-top: 1px solid rgb(207, 219, 241);
    background: #EDF3FF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

/* @media screen and (max-height: 650px) { 
    .createSafelySpace-div {
        height: 400px !important;
        min-height: 60% !important;
    }
   .existingSpace-div {
        height: 250px !important;
        min-height: 40% !important;
    }
    .welcomePage-body1 {
        height: 200px !important;
        padding-bottom: 100px !important;
    }
} */

.welcome-container {
    padding: 0px;
    background-color: #EDF3FF;
}
.btn-display {
    display: flex;
    justify-content: center;
}
.scan-btn-div {
    width: 100%;
}
.scan-btn-div .scan-btn-button {
    width: 100%;
    max-width: 336px;
    border: 1px solid #665DFF !important;
    background-color: #EDF3FF !important;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #665DFF !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
.scan-btn-div .scan-btn-button:focus, .scan-btn-div .scan-btn-button:active, .scan-btn-div .scan-btn-button:hover{
    border: 1px solid #665DFF !important;
    color: #665DFF;
    background: #EDF3FF !important;
    background-color:#EDF3FF !important;
    box-shadow: none !important;
}
.welcome-text-div {
    text-align: center;
}
.welcome-text-div .text-style {
    text-align: center;
    font-size: 22px;
    font-family: "Montserrat", Serif;
    font-weight: bold;
    letter-spacing: 0px;
    color: #454F63;
    padding-bottom: 30px;
}
.user-name-style {
    font-size: 28px;
}
.para-text-style {
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat", Serif;
    font-weight: normal;
    letter-spacing: 0px;
    color: #6E7E88;
    opacity: 1;
}
.new-workspace-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
}
.existing-text-style {
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #454F63;
    opacity: 1;
}
.tryAnotherAccount-Btn {
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #4FA3FF;
    opacity: 1;
    border: none;
    background-color: #EDF3FF;
}
.phone.welcomePage{
    overflow-x: hidden;
}
.header-row-div{
    width: 100%;
}
.create-space-btn {
    width: 100%;
    max-width: 336px;
}