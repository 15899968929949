.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.bs-callout div {
  text-align: justify;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}
