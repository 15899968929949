.table thead th:focus {
  outline: none;
}
.table thead th {
  border-top: none;
}
a:focus {
  outline: none;
}
.no-wrap {
  white-space: nowrap;
}

.space-checkbox {
  margin-right: 10px;
  margin-top: 5px;
  color: white;
  float: right;
}

.space-checkbox input:checked ~ .cr{
  background-color: #665DFF;
}

.transaction-checkbox {
  display: inline-block;
}

.transaction-checkbox input:checked ~ .cr {
  color: white;
  background-color: #665DFF;
}

.checkbox label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}

.page-item.active .page-link {
  z-index: 0;
}

/* Global Styles */
body {
  font-family: "Montserrat", sans-serif;
}
.main-container {
  background: #f1f5fd;
}
.css-2613qy-menu {
  /* display: inline!important; */
  visibility: initial!important;
}
.sub-container {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  min-height: 85vh;
  padding: 10px 0;
  margin: 0 20px;
}

.workspacelist.sub-container {
  padding: 0px !important;
}

.phone.sub-container,
.tablet.sub-container {
  box-shadow: none !important;
}
.primary-cta {
  font-size: 14px;
  font-weight: 600;
  background-color: #665dff;
  color: #ffffff;
  outline: none;
  border: 1px solid #665dff;
  border-radius: 4px;
  text-transform: capitalize;
  height: 40px;
}
.header-nav {
  background: #f1f5fd;
}
/* Global Styles */

.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap,
.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor,
.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor
  .tui-image-editor-canvas-container,
.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor
  .tui-image-editor-canvas-container
  canvas {
  height: auto !important;
  margin: 0 auto;
}

.tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-main
  .tui-image-editor-wrap
  .tui-image-editor-size-wrap
  .tui-image-editor {
  width: 100% !important;
  top: 0px !important;
}

.andonix-tui-image-editor-container
  .tui-image-editor-container
  .tui-image-editor-main-container
  .tui-image-editor-menu-rotate
  .tui-image-editor-submenu
  li.tui-image-editor-newline.tui-image-editor-range-wrap {
  display: none;
}
.tu.tui-image-editor-main-container {
  top: -79px;
}
.tui-image-editor-container .tui-image-editor-main-container {
  top: -78px !important;
}
/* image editor style */

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
[type="file"] + label {
  background: #665dff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  font-size: inherit;
  margin-bottom: 0;
  outline: none;
  padding: 0.5rem 10px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
[type="file"] + label:hover {
  background-color: #665dff;
}
[type="file"] + label.btn-1 {
  background-color: #665dff;
  box-shadow: 0 6px #665dff;
  transition: none;
}
[type="file"] + label.btn-1:hover {
  box-shadow: 0 4px #665dff;
  top: 2px;
}
.btn-info {
  background: #665dff;
}
.btn-info:hover {
  background-color: #665dff !important;
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}
.loader3 span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: rgba(135, 211, 124, 1);
  -webkit-animation: loader3 1.5s linear infinite;
  animation: loader3 1.5s linear infinite;
}
.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}
@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
html,
body {
  overflow-y: scroll;
}

.qrcodeScanner .modal-title {
  color: #2b2b42;
  font-weight: bold;
}
.qrScannerErrorPopup .modal-body{
  padding-left: 20px;
}
.subspace-name-error{
  color: #6E6E7C;
  font-weight: bold;
}
.photoOrVedioPopup .modal-header{
  padding: 0px;
  padding-right: 20px;
  padding-top: 5px;
}
.photoOrVedioPopup .modal-body{
  padding-top: 0px
}
.qrcodeScanner .modal-header,.qrScannerErrorPopup .modal-header,.takePhotoOrVideoPopup  .modal-header,.editimagepopup .modal-header,.addPopup .modal-header,.photoOrVedioPopup .modal-header{
  border-bottom: none;
}
.qrcodeScanner .modal-footer,.qrScannerErrorPopup .modal-footer,.addPopup .modal-footer{
  border-top: none;
}
.qrcodeScanner .modal-content {
  padding: 15px;
  padding-top: 5px;
}
.qrcodeScanner .cancel-button-qrcode, .qrScannerErrorPopup .cancel-button-qrcode{
  color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  opacity: 1;
  background: #fff;
  width: 120px;
}
.containerScan {
  position: absolute;
  top: 15%;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
}

.containerScan::before {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;
  left: 0px;
  top: 0px;
  border-left-width: 5px;
  border-top-width: 5px;
}

.containerScan::after {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;
  right: 0px;
  top: 0px;
  border-right-width: 5px;
  border-top-width: 5px;
}

.containerScan em::before {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;

  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
}

.containerScan em::after {
  border-color: #1754ff;
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  border-style: solid;
  border-width: 0px;

  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
}

.scan {
  width: 95%;
  position: absolute;
  -moz-animation: scan 2s infinite;
  -webkit-animation: scan 2s infinite;
  animation: scan 2s infinite;
  -webkit-animation-direction: alternate-reverse;
  background: linear-gradient(0deg, #1754ff, transparent);
  height: 40px;
}

@keyframes scan {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(155px);
    transform: translateY(155px);
  }
}

@-webkit-keyframes scan {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(155px);
    transform: translateY(155px);
  }
}

.DescriptionScan {
  position: absolute;
  text-align: center;
  color: #fff;
  bottom: 5%;
  font-weight: 600;
  padding: 0 4px 0 4px;
}

.flipCamera {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 5%;
  padding-bottom: 5%; */
  padding: 5% 15px 5% 15px;
}
.flipCamera span {
  font-size: 10px;
  text-align: center;
}

.modalScannerFooter {
  border-top: 0;
  padding: 1.5rem 2rem 2rem 2rem;
}
@media only screen and (max-width: 399px) {
  .modalScannerFooter {
    display: flex;
    justify-content: center;
    padding: 0.7rem 2rem 2rem 2rem;
  }
}

@media only screen and (min-width: 400px) {
  .flipCamera {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 135px;
  }
}

@media only screen and (max-width: 310px) {
  .containerScan {
    width: 100px;
    height: 100px;
  }
  .scan {
    height: 20px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
    }
  }
  .DescriptionScan {
    font-size: 10px;
    bottom: 2%;
  }
}

@media only screen and (min-width: 310px) {
  .containerScan {
    width: 110px;
    height: 110px;
  }
  .scan {
    height: 20px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(65px);
      transform: translateY(65px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(65px);
      transform: translateY(65px);
    }
  }
  .DescriptionScan {
    font-size: 10px;
    bottom: 2%;
  }
}

@media only screen and (min-width: 320px) {
  .containerScan {
    width: 100px;
    height: 100px;
  }
  .scan {
    height: 20px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
    }
  }
  .DescriptionScan {
    font-size: 12px;
    bottom: 1.5%;
  }
}

@media only screen and (min-width: 350px) {
  .containerScan {
    width: 130px;
    height: 130px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(105px);
      transform: translateY(105px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(105px);
      transform: translateY(105px);
    }
  }
}

@media only screen and (min-width: 380px) {
  .containerScan {
    width: 150px;
    height: 150px;
  }
  .scan {
    height: 30px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(115px);
      transform: translateY(115px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(115px);
      transform: translateY(115px);
    }
  }
}

@media only screen and (min-width: 400px) {
  .DescriptionScan {
    font-size: 14px;
  }
}

@media only screen and (min-width: 420px) {
  .containerScan {
    width: 170px;
    height: 170px;
  }
  .scan {
    height: 40px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(125px);
      transform: translateY(125px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(125px);
      transform: translateY(125px);
    }
  }
}

@media only screen and (min-width: 440px) {
  .DescriptionScan {
    bottom: 8%;
  }
}

@media only screen and (min-width: 480px) {
  .containerScan {
    width: 200px;
    height: 200px;
  }
  .scan {
    height: 30px;
  }
  @keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(165px);
      transform: translateY(165px);
    }
  }
  @-webkit-keyframes scan {
    0%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(165px);
      transform: translateY(165px);
    }
  }
}

.tool-Tip {
  border-radius: 20px !important;
  padding: 1px 10px !important;
  font-size: 9px !important;
  font-weight: 600 !important;
}

.css-50etie {
  width: 50px!important;
}
/* Multi Select CSS */

.user-stats {
  display: none !important;
}

/*Styles of Verification Point  */
/* .TimeLineVerificationPoint .list-group-item {
  border: 0;
  text-align: center;
}
.TimeLineVerificationPoint>.list-group>.list-group-item>div>.inputButton-container-buttons {
  justify-content: center;
} */

/* Verification point */
.logo-container {
  background-color: #e9e8ff;
  height: 240px;
  width: 240px;
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.outer-circle {
  background-color: #c3c0ff;
  height: 190px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.inner-circle {
  background-color: #665eff;
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.cta-container button {
  margin: 20px 0;
}
.btn-primary-filled {
  background-color: #665dff;
  border: 1px solid #665dff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 12px 20px;
  min-width: 335px;
  max-width: 335px;
}
.btn-primary-filled:hover {
  background-color: #665dff;
  border: 1px solid #665dff;
}

.btn-primary-outline {
  background-color: transparent;
  border: 1px solid #665dff;
  color: #665dff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 12px 20px;
  min-width: 335px;
  max-width: 335px;
}
.btn-primary-outline:hover {
  background-color: transparent;
  border: 1px solid #665dff;
  color: #665dff;
}

.btn-checkin-outline {
  background: transparent;
  color: #665dff;
  border: 1px solid #665dff;
  min-width: 120px;
  padding: 5px 15px;
}
.btn-checkin-outline:hover {
  background-color: transparent;
  color: #665dff;
}
.btn-checkin-outline:disabled {
  background-color: #DDDDDD;
  border: 1px solid #DDDDDD;
  color: #FFFFFF;
  cursor: not-allowed;
}

.btn-checkout-outline {
  background: transparent;
  color: #e67070;
  border: 1px solid #e67070 !important;
  min-width: 120px;
  padding: 5px 15px;
}
.btn-checkout-outline:hover,
.btn-checkout-outline:focus {
  background-color: transparent !important;
  color: #e67070;
  border: 1px solid #e67070 !important;
}
.checkin-select label {
  display: none;
}
.checkin-table td,
th {
  vertical-align: middle;
}
.checkin-table th {
  border-top: none;
}
.text-danger {
  color: #e67070 !important;
}
.text-success {
  color: #39b97e !important;
}
.table-row-count {
  font-size: 13px;
  color: #999999;
}
.next-btn {
  border: 1px solid #665dff;
  color: #665dff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 7px 30px;
}
.next-btn:hover {
  background-color: #665dff;
  border: 1px solid #665dff;
}
.previous-btn {
  padding: 7px 30px;
  border: 1px solid #665dff;
  color: #665dff;
  text-transform: uppercase;
  font-weight: 600;
}
.previous-btn:hover {
  background-color: #665dff;
  border: 1px solid #665dff;
}
.previous-btn:disabled,
.next-btn:disabled {
  background: #f2f2f2;
  color: #d3d3d3;
  font-weight: 600;
  border: 1px solid #d3d3d3;
  text-transform: uppercase;
}
.show-control {
  color: #5a5a5a;
  font-size: 13px;
}
.checkin-modal .modal-content {
  background-color: #fcfcff;
}
.checkin-modal .modal-body {
  padding: 40px;
}

.checkin-modal h3 {
  color: #2b2b42;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
}
.checkin-modal .close {
  margin-top: -30px;
  margin-right: -25px;
}
.checkin-modal .avtar {
  margin-bottom: 20px;
}
.checkin-modal input {
  height: 36px;
  color: #6e6e7c;
}
.checkin-select .select--container > .input-select__control {
  border-radius: 4px;
  color: #5a5a5a;
}

.checkin-select .input-select__placeholder {
  color: #6e6e7c;
}
.multi-select .dropdown-heading-value {
  color: #6e6e7c;
}
.multi-select .dropdown-heading-value span {
  max-width: 200px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.checkout-modal .modal-body {
  padding: 40px;
}
.checkout-modal h3 {
  color: #2b2b42;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
}
.checkout-modal .close {
  margin-top: -30px;
  margin-right: -25px;
}
.checkout-modal p {
  font-size: 17px;
  color: #6e6e7c;
}
.checkout-modal .btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.checkout-modal .btn-container .btn-outline-secondary {
  background-color: transparent !important;
  border-color: #665dff;
  color: #665dff;
  padding: 0px;
}

.checkout-modal .btn-container .btn-danger {
  background-color: #e67070 !important;
  border-color: #e67070 !important;
  font-weight: 500;
  margin-left: 10px;
  padding: 0px;
  /* color: #665DFF; */
}
/* Verification point */

/* user manamgement css */
.icon-btn-secondary {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 0 4px;
  border: 1px solid #665dff;
}
.icon-btn-secondary span {
  font-size: 16px;
  color: #665dff;
}
.icon-btn-secondary svg {
  margin-top: -5px;
  margin-left: -5px;
}
.icon-btn {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: #665dff;
  border: 1px solid #665dff;
  margin-left: 5px;
}

.icon-btn svg {
  margin-top: -5px;
  margin-left: -5px;
  fill: #665dff;
  stroke: #665dff;
  border: 1px solid #665dff;
}
.user-search {
  height: 36px !important;
  border: 1px solid #9baac7 !important;
  border-radius: 4px !important;
  color: #6e6e7c !important;
}
.user-workspaces {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.user-workspaces label {
  color: #454f63;
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 0;
  white-space: nowrap;
}
.user-workspaces .select--container {
  width: 100%;
}
.user-workspaces .input-select__control {
  height: 36px;
  border-radius: 4px;
}
.user-workspaces .input-select__indicator,
.user-workspaces .input-select__value-container {
  padding: 0 8px;
}
.user-workspaces.caret-down-gray .input-select__indicator i {
  color: #B4B4B4;
}

.user-workspaces.caret-down-gray .input-select__indicator,
 .user-workspaces.caret-down-gray .input-select__value-container> div{
   color: #B4B4B4;
 }

.user-controls {
  margin-top: 30px;
}
/* .user-workspaces  */
/* Multi Select CSS */

.select-panel .item-renderer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
/* Multi Select CSS */
/* start - Styles of QR  */
.Box-containerQR {
  position: relative;
  padding: 20px 20px 20px 20px;
  border: 1px solid #ebebeb;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
}

.containerQR::before {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;
  left: 25px;
  top: 25px;
  border-left-width: 10px;
  border-top-width: 10px;
}

.containerQR::after {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;
  right: 25px;
  top: 25px;
  border-right-width: 10px;
  border-top-width: 10px;
}

.containerQR em::before {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;

  left: 25px;
  bottom: 25px;
  border-left-width: 10px;
  border-bottom-width: 10px;
}

.containerQR em::after {
  border-color: #000;
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 0px;

  right: 25px;
  bottom: 25px;
  border-right-width: 10px;
  border-bottom-width: 10px;
}
/* end - Styles of QR  */

.sentinvetationpopup .modal-title {
  width: 100%;
  text-align: justify;
  word-break: break-all;
}
.qrCodePopUP .modal-title {
  width: 95%;
  text-align: justify;
  word-break: break-all;
}

.renderInvitationModal .workSpaceName-label-renderInvitationModal {
  width: 100%;
  text-align: justify;
  word-break: break-all;
}
.custome-multiselect .dropdown-content .select-panel:first-child {
  position: relative;
}
.custome-multiselect .dropdown-content .select-panel:first-child:before {
  font-family: "FontAwesome", Serif;
  color: #3c3c4399;
  content: "\f002";
  position: absolute;
  margin-left: 10px;
  margin-top: 8px;
}
.custome-multiselect
  .dropdown-content
  .select-panel:first-child
  div:first-child
  input {
  padding-left: 30px !important;
}
.users-search-input {
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
}
/* image cropper css*/

.checkin-input-container {
  width: 300px;
  position: relative;
}

@media screen and (min-width: 600px) {
  .ImageCropper-cropContainer-1 {
    height: 320px !important;
    width: 99% !important;
  }
  .ImageCropper-controls-3 {
    padding: 0 0 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .ImageCropper-cropContainer-1 {
    width: 100%;
    height: 235px !important;
    position: relative;
    background: #333;
  }
}

.image-crop-modal {
  padding: 20px;
}
.image-crop-modal h3 {
  margin-bottom: 20px;
  color: #2b2b42;
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.cropped-image-btn-container button {
  padding: 10px 35px;
  max-width: 100px;
}
.cropped-image-btn-container button:focus {
  outline: none;
}

.cropped-image-btn-container .MuiButton-containedPrimary {
  color: #fff;
  background-color: #665dff !important;
}
/*Loader CSS*/
.loader-container {
  background-color: #ebebeb;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-container .spinner-loader {
  margin-top: 5px;
}
.createQrCode-div,
.inviteMyContacts-div {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .users-table td,
  .checkin-table td,
  th {
    white-space: nowrap;
  }
  .users-table,
  .checkin-table {
    overflow-x: auto;
  }
  .users-search-input {
    margin-left: 10px;
  }
  .react-bootstrap-table {
    overflow: scroll;
  }
}
@media screen and (max-width: 767px) {
  /* .userManagement-table{
    height: 100vh;
  } */
  .checkin-input-container {
    width: 100%;
  }
  .users-table td,
  .checkin-table td,
  th {
    white-space: nowrap;
  }
  .users-search-input {
    margin-left: 10px;
  }
  .user-workspaces {
    align-items: flex-start;
    flex-direction: column;
  }
  .user-workspaces label {
    margin-bottom: 10px;
  }
  .table thead th {
    font-size: 13px;
  }
  .users-table .contactsTable-paginationFooter div,
  .checkin-table .contactsTable-paginationFooter div {
    text-align: center;
    justify-content: center;
    margin: 10px 0;
  }
  .users-table .contactsTable-paginationFooter-Showing,
  .checkin-table .contactsTable-paginationFooter-Showing {
    padding: 0;
  }
  .users-table .contactsTable-paginationFooter-perPage,
  .checkin-table .contactsTable-paginationFooter-perPage {
    justify-content: center;
  }
}
@media screen and (max-width: 360px) {
  .btn-primary-filled,
  .btn-primary-outline {
    font-size: 13px;
    padding: 12px 10px;
    min-width: 250px;
    max-width: 250px;
  }
  .users-search-input {
    margin-left: 7px;
    margin-top: 6px;
  }
}

@media screen and (max-width: 320px) {
  .users-search-input {
    margin-left: 7px;
    margin-top: 6px;
  }
}
.userManagement-table .contactsTable-tableContainer {
  overflow: visible !important;
}
.userManagement-table .contactsTable-tableContainer .dropdown-menu,
.safelyTableContiner .dropdown-menu {
  min-width: 0rem !important;
}
.userManagement-table .contactsTable-tableContainer .dropdown-item,
.safelyTableContiner .dropdown-item {
  padding: 0px !important;
}
.userManagement-table .contactsTable-tableContainer .dropdown-item a,
.safelyTableContiner .dropdown-item {
  padding: 0.25rem 1.5rem !important;
}
.scanQRCodeButton {
  display: flex;
  align-items: center;
  border: 1px solid #665dff;
  border-radius: 4px;
  margin-right: 10px;
  color: #665dff;
  background: #f1f5fd;
  padding: 8px;
  font-size: 12px;
}
.scanQRCodeButton:hover {
  outline: none;
}
.scanQRCodeButton:focus {
  outline: none;
}
.scanQRCodeButton svg {
  margin-right: 5px;
}
.scanQRCodeButton.phone svg {
  margin-right: 0px;
}
.error-cricle{
  background: #E67070 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  padding-top: 4px;
  display: inline-block;
}
.error-message-title-text{
  color: #2B2B42;
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}
.error-message-des-text{
  color: #6E6E7C;
  padding-left: 40px;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.sucess-cricle{
  background: #39B97E 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  padding-top: 4px;
  display: inline-block;
}
.godal-space-dropdown{
  width: 146px;
  margin-right: 10px !important;
  box-shadow:none;
}
.godal-space-dropdown label{
  display: none;
}
.godal-space-dropdown .input-select__control{
  border-color: hsl(0,0%,80%) !important;
  box-shadow:none !important;
}
.godal-space-dropdown .input-select__control:focus,.godal-space-dropdown .input-select__control:hover,.godal-space-dropdown .select--container:focus,.godal-space-dropdown .select--container:hover{
  outline:none !important;
  box-shadow:none !important;
  border-color: hsl(0,0%,80%) !important;
}
.scanQRCodeButton.phone{
  margin-right: 5px;
  padding: 5px;
}
.godal-space-dropdown.phone{
  width: 100px;
  margin: 0px !important;
  margin-right: 5px !important;
}
.main-container-header-tittle.phone{
  max-width: 79px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.phonenave{
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #2B2B42;
  font-weight: bold;
  font-size: 12px;
}
@media screen and (max-width: 860px) {
  nav{
    padding: 5px !important;
  }
  .sidebar-button{
    margin-top: 70px !important;
  }
  .tui-image-editor-container{
    min-height: 417px !important;
    overflow-x:scroll;
  }
}
@media screen and (max-width: 411px) {
  .tui-image-editor-container{
    min-height: 340px !important;
  }
}
@media screen and (max-width: 375px) {
  .tui-image-editor-container{
    min-height: 313px !important;
  }
}
@media screen and (max-width: 320px) {
  .tui-image-editor-container{
    min-height: 271px !important;
  }
  .phonenave{
    width: 80px;
  }
}
.outline-secondary-save{
  background: #665DFF 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 4px;
  border-color: #665DFF;
}
.outline-secondary-save:hover{
  background: #665DFF 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 4px;
  border-color: #665DFF;
}
.outline-secondary-cancel{
  background: #fff 0% 0% no-repeat padding-box;
  color: #665DFF;
  border-radius: 4px;
  border: 1px solid #665DFF;
}
.outline-secondary-cancel:hover{
  background: #fff 0% 0% no-repeat padding-box;
  color: #665DFF;
  border-radius: 4px;
  border: 1px solid #665DFF;
}
.tui-image-editor-submenu-style{
background: #151515 0% 0% no-repeat padding-box !important;
opacity: 0.75 !important;
}
.tui-image-editor-container{
  min-height: 590px;
}
.tui-image-editor-container .color-picker-control{
  width: 250px !important;
}
.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button{
  min-width: 7px;
  min-height: 7px;
}
.header-back-button{
  color: #655DFD;
  cursor: pointer;
}

.subspace-close-icon {
  vertical-align: middle;
  font-size: 20px;
}
.error-message-deletespace-text {
  font-size: 16px;
  padding-left: 40px;
  font-family: "Roboto", Serif !important;
  letter-spacing: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #6E6E7C;
  opacity: 1;
}

.pb-15{
  padding-bottom: 15px;
}
.subspace-style-text {
  width: 90%;
  font-size: 16px;
  font-family: "Roboto", Serif;
}
.subspace-name-bold {
  font-weight: bold;
}
.copperpoupHide{
  display: none !important;
}

.subspace-oval {
  display: inline-flex;
  background-color: #F2F5FF;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #E6E6E6;
  border-radius: 15px;
  opacity: 1;
  margin: 10px 5px 5px 0px;
  padding: 5px;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #5A5A5A;
  opacity: 1;
}

.createTransaction.sub-container,
.editTransaction.sub-container,
.executeTransaction.sub-container {
  overflow-x: auto;
  padding: 0 10px;
}

.transaction-container {
  min-width: 760px;
  max-width: max-content;
  margin: 0 auto;
}

.transaction-container.execute {
  min-width: max-content;
}

.transaction-header {
  display: flex;
  font: normal normal 600 15px Montserrat;
  line-height: 15px;
  padding: 10px 4px;
  background-color: #F2F2F2;
}

.transaction-filename {
  width: 215px;
  padding-left: 5px;
}

.transaction-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 auto;
}

.transaction-pagination.execute {
  justify-content: flex-end;
  align-items: center;
}

.transaction-row {
  display: flex;
  border-left: 5px solid #F2F2F2;
  border-right: 5px solid #F2F2F2;
  border-bottom: 5px solid #F2F2F2;
}

.pdf-viewer {
  position: relative;
  float: left;
  margin: 0 5px;
  border: 2px solid #BABABA;
}

.pdf-viewer.execute {
  margin: 0;
}

.input-select,
.create-transaction-menu {
  font: normal normal bold 14px Montserrat;
  padding: 5px;
  min-width: 210px;
  background-color: #FBFBFB;
  border: 2px solid #BABABA;
  float: left;
}

.transaction-button-container {
  margin: 15px 0;
  float: right;
}

.executeTransaction .transaction-button-container .save-btn{
  min-width: 110px;
  width: max-content !important;
}

.input-button {
  margin-top: 20px;
  cursor: pointer;
}

.input-icon {
  margin-right: 10px;
}

.pdf-text,
.pdf-signature {
  position: relative;
  text-align: center;
  line-height: 1;
  background-color: #FFFFC4;
  border: 1px solid black;
  letter-spacing: 0px;
  padding: 2px 0;
}

.pdf-text.input-required,
.pdf-signature.input-required {
  color: red
}

.pdf-text.resizable,
.pdf-signature.resizable {
  border: 2px solid #362fb3;
}

.resizable .resizers .resizer{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #362fb3;
  border: 2px solid #362fb3;
  position: absolute;
}
.resizable .resizers .resizer.top-left {
  left: -4px;
  top: -4px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizable .resizers .resizer.top-right {
  right: -4px;
  top: -4px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-left {
  left: -4px;
  bottom: -4px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-right {
  right: -4px;
  bottom: -4px;
  cursor: nwse-resize;
}

.signature-pad {
  width: 70vw;
  max-width: 470px;
  height: 30vh;
  border: 1px solid black;
  display: block;
  margin: 0 auto;
}

.add-signature .btn.btn-info:disabled {
  background-color: #E0E0E0 !important;
  color: white;
  border: 1px solid #E0E0E0;
}

.add-signature .btn.btn-info {
  background-color: #665DFF;
  color: white;
}

.pdf-input-text {
  padding: 0;
  margin: 0;
}

.transaction-button-container .save-btn.btn-primary:focus {
  background-color: #665DFF;
  color: white;
}

.transaction-button-container .save-btn.btn-primary:disabled {
 background-color: gray; 
 border: none;
}

.transaction-button-container .cancel-btn.btn-primary:focus {
  background-color: white;
  color: #665DFF;
}