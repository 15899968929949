.workspace-name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 54px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.workspace-name-container p {
  color: #3E3E3E;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat", Serif;
  margin-bottom: unset;
  margin-top: unset;
}
