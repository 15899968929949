.backToSingup-link-div {
    font-size: 14px;
    letter-spacing: 0px;
    color: #4FA3FF;
    height: 80px;
    padding-top: 35px;
    font-weight: 500;
}
.verify-wrapper {
    min-height: 500px;
}
.pincode-input-text {
    width: 44px !important;
    height: 44px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #9BAAC7 !important;
    border-radius: 4px;
    margin: 0px 4px !important;
}
.verify-pin-input-div {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}
.verify-btn-div {
    margin: auto;
    width: 206px;
    padding-top: 30px;
}
.verify-form {
    width: 320px;
}
.verify-header {
    height: 110px !important;
}
.resend-pin-div {
    padding-top: 20px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0px;
    color: #6E7E88;
    opacity: 1;
}
.pin-verified-fail, .pin-verified-success {
    text-align: center;
    font-family: "Roboto", Serif !important;
    letter-spacing: 0px;
    color: #39B97E;
    font-size: 12px;
    opacity: 1;
    padding-top: 5px;
}
.pin-verified-fail {
    color: red;
}
.pin-success-btn {
    background-color: #39B97E !important;
    border: 1px solid #39B97E !important;
    width: 100% !important;
}
.pin-verified-icon {
    font-size: 18px;
    margin-top: 2px;
    color: #FFFFFF;
    font-weight: bold;
}
.resend-pin-btn {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4FA3FF;
    opacity: 1;
    background-color: #FFFFFF !important;
}
.btn-submit-pin {
    background: #665DFF 0% 0% no-repeat padding-box;
    border: 1px solid #665DFF;
    width: 100% !important;
}
.btn-submit-pin:disabled {
    background: #DDDDDD 0% 0% no-repeat padding-box !important;
    border: 1px solid #DDDDDD !important;
    width: 100% !important;
}

.not-receive-verification-pin-text{
    text-align: center;
    color: #6e7e88;
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
}