/*  Show Mode - Button  */

.inputButton-container-show {
    display: flex;
    flex-direction: column;
}

.inputButton-container-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
}

.inputButton-button {
    border: 1px solid #333;
    border-radius: 4px;
    width: 167px;
    padding-left: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 20px 1em 0 0;
}

.inputButton-button.active {
    border: 1px solid #665DFF;
}

.inputButton-container-buttons .active {
    color: #665DFF;
}

.inputButton-button label {
    font-weight: 600;
    cursor: pointer;
}

/* @supports (display: grid) {
    .inputButton-container-buttons {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(167px, 1fr));
        grid-gap: 1em;
    }
    div>.form-check {
        padding: 0;
    }
} */

/*  Edit Mode - Button  */

.inputButton-container {
    padding-top: 11px;
}

.inputButton-options {
    display: flex;
    align-items: baseline;
}

.text-container {
    width: 50%;
}

.text-container .input-container {
    margin-top: 0;
}

.text-container .input-container .edition-input {
    margin-bottom: 0;
}

.text-container .required {
    margin-top: 0;
}

.text-container>span {
    color: red;
    font-size: 9px;
}

.option-container {
    display: flex;
    width: 50%;
}

.cancelIcon {
    width: 17px;
    margin: 5px 0 0 10px;
}

.inputButton-addButton {
    text-align: right;
    padding-top: 10px;
}

.inputButton-addButton>span {
    color: #4FA3FF;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.cancelIcon>svg {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .inputButton-options {
        display: flex;
        align-items: baseline;
        flex-direction: column;
    }
    .text-container {
        width: 100%;
    }
    .text-container>.selected-input {
        width: 100%;
    }
    .option-container {
        width: 100%;
    }
    .option-container>select {
        margin: 20px 0;
    }
}

/*  Dropdown */

.dropdown-list {
    max-width: 500px;
}

.dropdown-list>label:first-child {
    color: #454F63;
    font: normal normal normal 18px/40px Roboto;
}

.dropdown-list .input-select__control {
    border-radius: 4px;
}