/* .edit-form-header {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #2B2B42;
    opacity: 1;
    padding-top: 20px;
    padding-left: 10px;
} */
/* .quill-editor-div {
    padding-left: 10px;
    padding-top: 20px;
} */
.quill-editor-div .quill {
    width: 100%;
    max-width: 860px !important;
    height: 300px;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.ql-toolbar.ql-snow {
    width: fit-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
    opacity: 1;
    margin: 4px;
}
.ql-container.ql-snow {
    max-height: 220px;
    border: none;
}
.quill-editor-label {
    text-align: left;
    font-weight: 500;
    font-family: "Montserrat", Serif;
    font-size: 14px;
    letter-spacing: 0px;
    color: #454F63;
    opacity: 1;
}
.edit-form-btn {
    margin-top: 10px;
    text-align: right;
    margin-right: 20px;
}
.save-btn , .cancel-btn{
    width: 110px !important;
    height: 40px;
    border:1px solid #665DFF;
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    outline: none !important;
    box-shadow: none !important;
    margin: 5px;
}
.save-btn:hover {
    background: #665DFF 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.cancel-btn {
    background-color: #FFFFFF;
    color: #665DFF;  
}
.cancel-btn:hover {
    background-color: #FFFFFF;
    color: #665DFF;  
}
/* .add-another-input {
    color: #4FA3FF;
    font-size: 14px;
    font-weight: 400;
    margin-right: 17%;
    cursor: pointer;
} */
.plus-symbol {
    vertical-align: bottom !important;
    font-size: 20px !important;
}
.text-input {
    border: none;
    background-color: #F1F5FD !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    outline: none;
    border-bottom: 1px solid #9a9b9e;
    width: 90% !important;
    color: #334856;                             
}
/* .text-input-wrapper {
    flex: 1px;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 10px;                           
} */
/* .delete-icon , 
.tabletdelete-icon ,
.phonedelete-icon
{
    font-size: 20px;
    margin-right: 12%;
    cursor: pointer;
    float: right;
    margin-top: 0;
    text-align: center;
    color: #e67070;                           
} */
.select-input-para {
    font-weight: 700; 
    color: #334856;
}
/* .select-input-div {
    width: 80%;
    height: 100%;
    background-color: #f2f5fe;
    margin: 15px;
    padding: 10px;
    border-width: 1px;
    border-radius: 4px;
    border-style: dashed;
    border-color: #b7c6e8;                          
} */
/* ------------- */
/* .tabletdelete-icon {
    margin-right: 10% !important;
}
.phonedelete-icon {
    margin-right: 6% !important;
} */
.drop-down-style {
    width: 90%;
}
.form-control-textAera{
    min-height: 300px;
    max-width: 860px;
    max-height: 300px;
    border: 1px solid #dddddd;
}
.verifyEditdiv {
    cursor: pointer;
}
.verifyEditdiv > svg .a {
    fill: #665DFF;
  }