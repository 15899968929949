.marketplace-container-row {
    flex-direction: row;
    justify-content: center; 
    padding: 10px 20px;
}

.desktopMarketplace-data {
    margin: 20px 15px;
    flex: auto;
}

.tabletMarketplace-data{
    margin: 5%;
    flex: none;
}

.phoneMarketplace-data {
    margin: 20px 15px;
    flex: none;
}

.market-product-div {
    width: 243px;
    height: 94px;
    background: transparent linear-gradient(90deg, #202D4C 0%, #304381 100%) 0% 0% no-repeat padding-box;
    border: 4px solid #A3B5B5;
    border-radius: 63px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    margin: 20px 0;
    padding: 10px;
}

.marketPlace-image {
    width: 243px;
    height: 149px;
    opacity: 1;
    border-radius: 5px;
}

.market-product-left {
    text-align: left;
    font-size: 10px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    padding-left: 10px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    color: #FFFFFF;
    word-break: break-word;
}
.market-product-right {
    width: 74px;
    height: 74px;
    border-radius: 100%;
    border: 4px solid #CAD5D6;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    flex: none;
    margin-top: -4px;
    display: flex;
    align-items: center;
    padding: 9px !important;
    justify-content: center;
}
.marketPlace-btn-text {
    font-size: 10px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #004850;
    text-transform: uppercase;
    text-align: center;
    opacity: 1;
    text-decoration: none !important;
    cursor: pointer;
}
