.safelypass-text-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.safelypass-activated-text {
  color: #39b97e;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", Serif;
  padding-left: 5px;
}
.safelypass{
  padding: 10px 20px;
}

.timer-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timer-check-out-contier{
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  width: fit-content;
  padding: 10px;
}
.workspaceSafelyPasscontier{
  display: flex;
  align-items: center;
}
.timer-check-out-contier .noSpaceImage-worksapce{
padding-top: 1px;
}
.safely-workspace-name{
  color: #2B2B42;
  font: normal normal 600 15px/19px "Montserrat", Serif;
  padding-left: 5px;
  word-break: break-word;
}
.timer-container {
  width: 268px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  align-items: center;
}
.safelypass-activated-date{
font: normal 16px/19px "Montserrat", Serif;
color: #606076;
}

.user-info-text-name{
  color: #2B2B42;
  font: normal 600 1.5rem/1.8125rem "Montserrat", Serif;
  word-break: break-word;
}

.timer-text {
  font-size: 17px;
  color: #2b2b42;
  font-family: "Roboto", Serif;
  font-weight: bold;
  margin: 0 10px 0 10px;
}

.timer-container > .btn {
  padding: 0 !important;
}

.container > .user-profile {
  padding: 0 !important;
  margin: 35px 10px;
}

.btn-checkout {
  background: #e67070;
  width: 120px;
  height: 40px;
  border-radius: 4px;
}

.icon-pass-text-activated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotate(-20deg);
}

.icon-pass-text-activated::before {
  color: #39b97e;
}

.text-content > div {
  width: auto !important;
}

.safelyPass-popup .modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #00000033;
  border-radius: 10px;
  opacity: 1;
}
.safelyPass-popup .modal-header {
  border-bottom: none;
}

.safelyPass-popup .modal-content .modal-header .modal-title {
  font-size: 22px;
  font-weight: 700;
  color: #2b2b42;
}

.safelyPass-popup .modal-content .modal-footer {
  border-top: none;
}

.safelyPass-popup .safelyPass-popup-button {
  border: 1px solid #665dff;
  border-radius: 4px;
  background: #ffff;
  color: #665dff;
  width: 100px;
  height: 40px;
}
.safelyPass-popup .safelyPass-popup-button-no {
  border: 1px solid #E67070;
  border-radius: 4px;
  background: #E67070;
  color: #fff;
  width: 100px;
  height: 40px;
}
.safelyPass-popup .safelyPass-popup-button-yes {
  border: 1px solid #665DFF;
  border-radius: 4px;
  background: #665DFF;
  color: #fff;
  width: 100px;
  height: 40px;
}

.safelyPass-popup .safelyPass-popup-text1 {
  color: #e67070;
}

.safelyPass-popup .safelyPass-popup-text2 {
  margin-top: 10px;
  color: #6e6e7c;
}

.safypasstext {
  word-break: break-all;
}

/* .text-center{
    text-align: center;
} */
.safelypass-coniter .user-details img{
  width: 120px;
  height: 120px;
}
.phone.safelypass .user-profile{
  display: inherit !important;
  margin-left: 0px;
}

.btn-check-into-subspace{
  height: 40px;
  background: #39B97E 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border-color: currentColor;
}

.btn-check-out-subspace{
  height: 36px;
  background: #E67070 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border-color: currentColor;
}

.qr-code-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 480px){
  .timer-container{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    margin: 0px;
  }

  .timer-text{
    font-size: 16px;
  }

  .btn-check-into-subspace{
    width: auto;
    height: auto;
  }

  .qr-code-section{
    margin: 5px 0 10px 0px;
  }
}

@media only screen and (max-width: 712px){
  .timer-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px 10px 0px;
  }

  .user-profile{
    max-height: none;
  }
}


.phone .timer-container{
  text-align: center;
  display: block;
  margin: 0px;
}
.phone .user-details{
  display: block !important;
  text-align: center;
}
.phone .timer-container .btn{
  width: 100%;
}

.tablet .safelypass-coniter .user-details{
  display: block !important;
  text-align: center;
  justify-content: flex-start;
}
.tablet .safelypass-coniter .safelypass-text-container {
  justify-content: space-evenly;
  max-height: 130px;
}
.tablet .safelypass-coniter .timer-section{
  justify-content: space-evenly;
  max-height: 130px;
}
@media only screen and (orientation:landscape) and (max-width: 823px){
.tablet .safelypass-coniter .user-details, .tablet .safelypass-coniter .timer-section,.tablet .safelypass-coniter .safelypass-text-container{
  flex: 100% !important;
  max-width: 100% !important;
}  
.tablet .safelypass-coniter .timer-container{
  text-align: center;
  display: block;
}
.tablet .safelypass-coniter .user-details{
  display: block !important;
  text-align: center;
}
.tablet .safelypass-coniter .timer-container .btn{
  width: 100%;
}
.tablet .safelypass-coniter .user-profile{
  max-height: 100%;
}
.tablet .timer-check-out-contier,.tablet .safelypass-activated-div{
   margin-right: 0px;
}
}
.phone .safelypass-coniter .user-profile{
  max-height: 100%;
}

.tablet .safelypass-coniter .user-profile{
  margin-top: 30px;
}
