/* 
.inputs-wrapper {
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 10;
} */
.trash-icon {
    font-size: 20;
    margin-right: 12%;
    cursor: pointer;
    float: right;
    margin-top: 0;
    text-align: center;
    color: #e67070;
}
/* .input-container {
    width: 80%;
    height: 100%;
    background-color: #f2f5fe;
    margin:15px 10px;
    padding: 20px;
    border-width: 1px;
    border-radius: 4px;
    border-style: dashed;
    border-color: #b7c6e8;
} */

/* .input-container > .title {
    font-weight: 700; 
    color: #334856;
} */

.select-input {
    width: 90%;
    margin-top: 2px;
    border-color: #9a9b9e;
    background-color: #fff;
    border-radius: 4px;
}
.select-input option:hover {
    background-color: red;
}
/* .selected-input {
    border: none;
    background-color: transparent!important;
    margin-top: 20px!important;
    outline: none;
    border-bottom: 1px solid #9a9b9e;
    width: 90%;
    color: #334856;
} */

.input-checkbox {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.input-checkbox input:not([type="checkbox"]) {
    width: 80%!important;
}
.input-checkbox .checkbox label {
    margin-bottom: 0;
}
.input-checkbox .checkbox .cr {
    background-color: #fff;
}

/* .edit-form-header {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #2B2B42;
    opacity: 1;
    padding-top: 20px;
    padding-left: 10px;
} */
/* .quill-editor-div {
    padding-left: 10px;
    padding-top: 20px;
} */
.quill-editor-div .quill {
    width: 100%;
    max-width: 860px !important;
    height: 300px;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.ql-toolbar.ql-snow {
    width: fit-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
    opacity: 1;
    margin: 4px;
}
.ql-container.ql-snow {
    max-height: 220px;
    border: none;
}
.quill-editor-label {
    text-align: left;
    font-weight: 500;
    font-family: "Montserrat", Serif;
    font-size: 14px;
    letter-spacing: 0px;
    color: #454F63;
    opacity: 1;
}
.edit-form-btn {
    margin-top: 10px;
    text-align: right;
    margin-right: 20px;
}
.save-btn , .cancel-btn{
    width: 100px;
    height: 40px;
    border:1px solid #665DFF;
    background: #665DFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", Serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    outline: none;
    box-shadow: none;
    margin: 5px;
}
.save-btn:hover {
    background: #665DFF 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.cancel-btn {
    background-color: #FFFFFF;
    color: #665DFF;  
}
.cancel-btn:hover {
    background-color: #FFFFFF;
    color: #665DFF;  
}
/* .add-another-input {
    color: #4FA3FF;
    font-size: 14px;
    font-weight: 400;
    margin-right: 17%;
    cursor: pointer;
} */
.plus-symbol {
    vertical-align: bottom !important;
    font-size: 20px !important;
}

.text-input {
    border: none;
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 10px;
    outline: none;
    border-bottom: 1px solid #9a9b9e;
    width: 80%;
    color: #334856;                             
}
/* .text-input-wrapper {
    flex: 1px;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 10px;                           
} */
/* .delete-icon {
    font-size: 20px;
    margin-right: 12%;
    cursor: pointer;
    float: right;
    margin-top: 0;
    text-align: center;
    color: #e67070;                           
} */
.select-input-para {
    font-weight: 700; 
    color: #334856;
}
/* .select-input-div {
    width: 80%;
    height: 100%;
    background-color: #f2f5fe;
    margin: 15px;
    padding: 10px;
    border-width: 1px;
    border-radius: 4px;
    border-style: dashed;
    border-color: #b7c6e8;                          
} */
.select-input-dropdown {
    width: 80%;
    margin-top: 2px;
    border-color: #9a9b9e;
    background-color: #fff;
    border-radius: 4px;
}
.editor-wrapper {
    padding: 15px;
}
/* .edit-form-title {
    color: #2B2B42;
    margin-top: 15px;
    font-size: 18px;
    padding-left: 10px;
} */
.deny-pass-toggle {
    margin-bottom: 10px;
}

.form-control-textAera {
    min-height: 300px;
    max-width: 860px;
    max-height: 300px;
    border: 1px solid #dddddd;
}


@media screen and (max-width: 480px) and (orientation: landscape) {
    /* .input-container {
        width: 95%;
    } */
    /* .trash-icon {
        margin-right: 0;
    } */
    .select-input, .selected-input {
        width: 100%;
    }
}
@media screen and (max-width: 480px) and (orientation: portrait) {
    /* .input-container {
        width: 90%;
    } */
    /* .trash-icon {
        margin-right: 0;
    } */
    .select-input, .selected-input {
        width: 100%;
    }
}