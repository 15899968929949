.input-box {
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 8px 0 0 0;
  border: 1px solid #9baac7;
  border-radius: 4px;
  height: 44px;
  padding-left: 35px;
}
.input-box:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.input-box:focus + .search-result {
  display: block;
}
.search-result:hover {
  display: block;
}
.search-result {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 0;
  list-style: none;
  padding-inline-start: 0;
  display: none;
  max-height: 9rem;
  overflow: scroll;
}
/* checkbox css starts here */
/* The container */
.container-dropdown {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #6e6e7c;
  padding: 5px 8px;
  margin-bottom: 0px;
}
.container-dropdown:hover {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
}
/* Hide the browser's default checkbox */
.container-dropdown input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: none;
}
.chip button:focus {
  outline: none !important;
}
/* On mouse-over, add a grey background color */
.container-dropdown:hover input ~ .checkmark {
  background-color: #ccc;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

/* When the checkbox is checked, add a blue background */
.container-dropdown input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-dropdown input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-dropdown .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.multiSelect {
  text-align: left;
  display: flex;
  flex-direction: column;
}
/* chip css */
.chip {
  flex-grow: 1;
}
.chip-body {
  min-width: 101px;
  width: auto;
  background: #f2f5ff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 15px !important;
  color: #5a5a5a;
  padding: 1px 10px;
  display: inline-block;
  margin-left: 3px;
  margin-bottom: 3px;
  margin-top: 10px;
  font: Regular 13px/18px "Roboto" , Serif;
}
.chip-text {
  margin: 0;
  display: inline;
}
.chip-close {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  float: right;
  margin-left: 3px;
}
.multiSelect .fa-search {
  position: absolute;
  margin-top: 23px;
  margin-left: 15px;
  color: #3c3c4399;
}
.search-fa-icon {
  color: #3C3C4399 !important;
}
