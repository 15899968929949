html,
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 100%;
}

.sws-container {
  height: 100%;
}

/* Split the screen in half */
.split {
  width: 100%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the top side */
.white-top-split {
  height: 100%;
  top: 0;
  /* background-color: #fff; */
}

/* Control the bottom side */
.green-bottom-split {
  height: 40%;
  bottom: 0;
  background-color: #13988a;
}

.login {
  width: 730px;
  min-height: 500px;
  overflow: hidden;
  display: inline-flex;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #00000033;
  border-radius: 10px;
  opacity: 1;
}

.login-header {
  height: 130px;
}

.wrapper {
  min-height: calc(100% - 27.8px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  background-color: #F1F5FD;
}

.login-white-container {
  background-color: #fff;
  height: 60%;
}

.login-green-container {
  background-color: #13988a;
  height: calc(40% - 32.6px);
  position: relative;
}

.footer {
  position: relative;
  width: 100%;
  background-color: #fafafa !important;
}

.footer-copyright-text {
  font-size: 14px;
}

.login-carousel-image {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 541px;
}

/*=====Styles for imputs===*/
.border-bottom-input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #9BAAC7;
  border-radius: 4px !important;
  opacity: 1;
  font-family: "Roboto", Serif;
  overflow: hidden;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.border-bottom-input-focus:focus {
  border: 1px solid #4FA3FF !important;
}

/*============MEDIA QUERIES FOR LOGIN==============*/
/* Android devices */
@media only screen and (max-width: 480px) {
  html,
  body {
    background: none;
  }
  .login {
    box-shadow: none;
    border-radius: 10px;
    height: 100%;
    position: relative;
    padding: 15px 15px 15px 15px;
  }

  .login-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: 100%;
  }

  .green-bottom-split {
    display: none;
  }

  .white-top-split {
    display: none;
  }

  .wrapper {
    position: relative;
    min-height: calc(100% - 27.8px);
    background: none;
  }

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 300;
    background: none;
  }

  .footer-copyright-text {
    font-size: 12px;
  }

  .logo-andonix {
    padding-top: 10px;
  }

  .login-header {
    padding-bottom: 1rem;
  }

  .carusel-column {
    display: none;
  }

  .login-main-container {
    min-height: auto;
    height: auto !important;
  }

  .button-circle {
    width: 40px;
    height: 40px;
  }
}

.login-container {
  width: 390px;
}

@media only screen and (min-width: 480px) {
  .login-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .green-bottom-split {
    display: none;
  }

  .white-top-split {
    display: none;
  }

  .footer-copyright-text {
    font-size: 12px;
  }

  .logo-andonix {
    padding-top: 10px;
  }

  .login-header {
    padding-bottom: 2rem;
  }

  .carusel-column {
    display: none;
  }

  .login-main-container {
    min-height: auto;
    height: auto !important;
  }
}

@media only screen and (min-width: 480px) and (orientation: landscape) {
  .login-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .footer {
    position: relative;
  }
}

@media only screen and (min-width: 768px) {
  .login {
    box-sizing: border-box;
    box-shadow: 0px 4px 33px rgba(79, 41, 132, 0.13),
      0px 4px 20px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    /* height: 550px; */
  }

  .green-bottom-split {
    display: block;
  }

  /* .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  } */

  /* .footer-copyright-text {
    font-size: 14px;
  } */

  .carusel-column {
    display: block;
  }
}

/*Login social media*/
.seperator {
  float: left;
  width: 100%;
  border-top: 1px solid #ccc;
  text-align: center;
  margin: 20px 0 0;
}
.seperator b {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  position: relative;
  top: -22px;
  z-index: 1;
}

.social-icon-div {
  padding-top: 25px;
}
/* .social-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-icon .fa {
  font-size: 28px;
  width: 40px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.social-icon .fa:hover {
  opacity: 0.7;
}

.social-icon .fa-facebook {
  background: #3b5998;
  color: white;
}

.social-icon .fa-twitter {
  background: #55acee;
  color: white;
}

.social-icon .fa-google {
  background: #dd4b39;
  color: white;
}

.social-icon .fa-windows {
  background: #81c2e0;
  color: white;
} */

.safely-sigin-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-login {
  width: 280px;
  min-height: 220px;
  max-width: 100%;
  height: 100%;
}

.klnsGD {
  max-width: 100%;
}

.signin-select {
  padding-top: 10px;
  padding-right: 25px;
}

.btn-submit {
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", Serif !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.btn-submit:focus {
  outline: none !important;
  box-shadow: none !important;
}

.safely-logo {
  padding-top: 20px;
  padding-left: 20px;
}

.forgot-password {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", Serif;
  letter-spacing: 0px;
  color: #4FA3FF;
  opacity: 1;
}

.login-right-style {
  background-color: #726BEF;
}

.login-right-style .signin-link {
  background-image: url("../icons/signupImage.svg");
  background-position: right;
  height: 100%;
  padding-top: 48%;
  max-height: 393px;
  margin-top: 40px;
}