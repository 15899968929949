
.error-message{
    text-align: center;
    margin-top: 15px;
}
.safelyTableContiner .pagination{
    float: right;
}
.safelyTableContiner .react-bootstrap-table-pagination-total{
    padding-left: 10px;
}
.spinner-loader{
    text-align: center;
    margin-top: 20px;
}
@media (max-width: 756px) {
.safelyTableContiner{
    width: max-content;
}
.reportlist.sub-container{
    width: fit-content;
}
}
@media (max-width: 360px) {
.main-container-header-tittle{
    font-size: 11px !important;
}
}

.reports-header {
    margin: 30px 0px 10px 30px;
    font: 18px/22px "Montserrat", Serif;
    font-weight: 600;
    display: block;
}

.tabs-container {
    padding: 20px 25px !important;
    flex-wrap: nowrap;
}

.tab-reports {
    font-family: "Montserrat", Serif;
    color:  #6e7e88;
    font-size: 13px;
    font-weight: 600;
}

.tab-reports.nav-item.nav-link.active {
    background-color: #F1F5FD;
    color: #665DFF;
}

/* verificatiion report css */
.report-container {
    padding: 40px;
    min-height: 500px;
    max-height: calc(95vh - 71px);
    overflow-y: auto;
}
.report-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report-title h3 {
    color: #2B2B42;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.export-button {
    padding: 7px 20px;
    color: #665DFF;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid #665DFF;
}
.export-button:hover, .export-button:focus, .export-button:active {
    background-color: #665DFF !important;
    color: #fff !important;
    border: 1px solid #665DFF!important;
    box-shadow: none!important;
}
.export-button:disabled:hover, .export-button:disabled:focus, .export-button:disabled:active {
    cursor: not-allowed;
    color: #212529!important;
    background-color: #f8f9fa!important;
    border-color: #f8f9fa!important;
}
.period-selector {
    margin: 20px 0!important;
}
.period-selector .input-select__indicator{
    padding: 0!important;
}
.period-selector .input-select__control {
    min-height: 36px;
    border: 1px solid #9BAAC7!important;
    border-radius: 4px!important;
}
.period-selector .css-1wa3eu0-placeholder {
    color: #6E6E7C;
}
.date-picker-container .react-datepicker-wrapper{
    width: 100%;
}
.date-picker-container i {
    color: #707070;
    position: absolute;
    z-index: 1;
    right: 0;
    margin-right: 22px;
    margin-top: 10px;
}
.date-picker {
    width: 100%;
    min-height: 36px;
    color: #6E6E7C;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.input-search-container input {
    width: 100%;
    min-height: 36px;
    padding-left: 30px;
    color: #6E6E7C;
    border: 1px solid #9BAAC7;
    border-radius: 4px;
}
.input-search-container i{
    color: #3C3C4399;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
}
.report-table .react-bootstrap-table {
    overflow: auto;
}
.report-table .react-bootstrap-table th {
    white-space: nowrap;
}
.report-table::before, .report-table::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    content: '';
    pointer-events: none;
}
.report-table::before {
    left: 0;
}
.cell-fixed-left {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    z-index: 5;
    background-color: #fff;
}

.cell-fixed-left::after {
    -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.th-title[data-title] {
    position: relative;
    z-index: 1;
}
.th-title[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -25px;
    display: inline-block;
    padding: 1px 6px;
    background: #fff;
    color: #7b7d7f;
    font-size: 10px;
    font-family: sans-serif;
    border: 1px solid #7b7d7f;
    border-radius: 16px!important;
    white-space: nowrap;
}
.th-title[data-title]:hover::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 8px;
    display: inline-block;
    color: #7b7d7f;
    border: 8px solid transparent;	
    border-bottom: 8px solid #7b7d7f;
  }

  .report-container .css-2b097c-container{
      z-index: 10;
  }
  .report-container .react-datepicker-popper {
      z-index: 6;
  }
  
  .toggle-more {
      cursor: pointer;
      color: #4FA3FF;
      font-size: 12px;
  }
.mobile-filters i {
    margin-left: 5px;
    color: #665dff;
}
.mobile-filters i.disabled {
    color: #6e7e88;
    cursor: not-allowed;
}
.filter-container {
    margin-left: 15px;
}
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .period-selector .css-1wa3eu0-placeholder{
        white-space: nowrap;
    }
    .input-search-container i {
        color: #3C3C4399;
        position: absolute;
        z-index: 1;
        left: 0;
        margin-left: 10px;
        margin-top: 11px;
    }
    .date-picker-container i {
        margin-right: 25px;
    }
}

  @media screen and (max-width: 767px) {
    .report-title {
        flex-direction: row;
    }
    .report-title h3 {
        font-size: 18px;
    }
    .input-search-container i {
        margin-left: 10px;
    }
    .date-picker-container i {
        margin-right: 25px;
    }
    .users-table, .checkin-table {
        overflow-x: inherit;
    }
    .cell-fixed-left {
        position: static!important;
    }
  }
@media screen and (max-width: 375px) and (min-width: 360px) {
    .report-title h3 {
        font-size: 14px;
    }
}
  @media screen and (max-width: 320px) {
    .report-container .pl-0 {
        padding-left: 0px!important;
    }
    .input-search-container i {
        margin-left: 10px;
    }
    .date-picker-container i {
        margin-right: 10px;
    }
    .filter-container > div[class*="col-"] {
          padding: 0;
    }
    .report-title {
        flex-direction: row;
        padding: 0;
    }
    .report-title h3 {
        font-size: 14px;
    }
    .date-picker {
        font-size: 12px;
    }
    .period-selector .css-1wa3eu0-placeholder{
        font-size: 12px;
    }
    .input-search-container input::placeholder {
        font-size: 12px;
    }
    .export-button {
        font-size: 12px;
    }
    .contactsTable-paginationFooter {
        font-size: 12px;
    }
  }

  /* MeasuringCircle    */
.measuringCircle {
    display: flex;
    min-width: 200px;
}
.measuringCircle .circleContainer {
    position: relative;
}
.measuringCircle .availability {
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.measuringCircle .circle {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    border: solid 3px;
}
.measuringCircle .text {
    align-self: center;
    margin-left: 9px;
    font-weight: 500;
}

/*  measuringBar    */
.measuringBar {
    min-width: 100px !important;
    width: 100%;
}
.measuringBar > div > div {
    opacity: .5;
}

.activityTimes {
    height: 40px;
    background-color: #F5F7FC;
    border-bottom: 2px solid #f0f0f0;
    border-top: 2px solid #f0f0f0;
    padding: 10px 20px;
    text-align: left;
    font: 14px "Montserrat", Serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    width: 100%;
}

.activityRow {
    white-space: nowrap;
    border-bottom: 2px solid #f0f0f0;
    width: 100%;
}

.activityHeaderText {
    display: inline-block;
    width: 250px;
    color: #5A5A5A;
    font: 13px "Montserrat";
    font-weight: bolder;
    padding-left: 20px;
    margin-bottom: 10px;
}

.activityDate { 
    display: inline-block;
    width: 250px;
    color: #9C9C9C;
    font-family: "Montserrat", Serif;
    font-size: 16px;
    padding-left: 20px;
}

.activityText {
    display: inline-block;
    color: #5A5A5A;
    font: 17px "Montserrat", Serif;
    padding: 23px 21.5px;
    vertical-align: middle;
}

.activityIcon {
    width: 21px;
    height: 18px;
    margin-right: 15px;
    vertical-align: top;
}
.user-pic-reports{
    width: 44px;
    height: 44px;
    border-radius: 4px;
    cursor: pointer;
}